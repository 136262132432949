import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import guruGLogo from "../../Images/logo-sm.png";
import { GiFlowerPot } from "react-icons/gi";
import { Button, Image, Timeline } from "antd";
import moment from "moment";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const { deliveryDetails, isLoadingOnLogin, kycRevokeLog } = store.mainState;
  return { deliveryDetails, isLoadingOnLogin, kycRevokeLog };
};

function DeliveryDetails({
  id,
  deliveryDetails,
  isLoadingOnLogin,
  kycRevokeLog,
}) {
  const dispatch = useDispatch();
  const [activeImage, setActiveImage] = useState("");
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [verifyKYCmodal, setVerifyKYCmodal] = useState(false);
  const [revokeKycVerificationModal, setRevokeKycVerificationModal] =
    useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [isKycRevokeLogModalVisible, setIsKycRevokeLogModalVisible] =
    useState(false);

  useEffect(() => {
    dispatch({
      type: "FETCH_DELIVERY_DETAILS_REQUEST",
      payload: {
        id,
      },
    });
    dispatch({
      type: "FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST",
      payload: {
        id,
      },
    });
  }, [id]);
  // useEffect(() => {
  //   dispatch({
  //     type: "FETCH_DELIVERY_DETAILS_REQUEST",
  //     payload: {
  //       id,
  //     },
  //   });
  // }, []);

  const driverReloadFn = (response) => {
    dispatch({
      type: "FETCH_DELIVERY_DETAILS_REQUEST",
      payload: {
        id,
      },
    });
    dispatch({
      type: "FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST",
      payload: {
        id,
      },
    });
  };
  return (
    <div>
      <div
        className={`${
          isImageModalVisible ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading"></div>
          <>
            <div className="delete--admin--container__body">
              <h1>Image Preview</h1>
              <img
                src={activeImage}
                style={{ height: "425px", width: "500px" }}
              />
            </div>
            <div className="delete--admin--container__footer">
              <p
                style={{ border: "none" }}
                onClick={() => setIsImageModalVisible(!isImageModalVisible)}
              ></p>
              <button
                onClick={() => setIsImageModalVisible(!isImageModalVisible)}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>

      {/* Kyc revoke log */}
      <div
        className={`${
          isKycRevokeLogModalVisible ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            KYC Revoke Log
          </div>
          <>
            <div className="delete--admin--container__body">
              <Timeline onChange={() => {}} style={{ marginTop: "1rem" }}>
                {kycRevokeLog?.data?.map((item) => {
                  return (
                    <Timeline.Item style={{ display: "flex" }} key={item.id}>
                      <h5>
                        {moment(item.sendTime).local().format("YYYY-MM-DD")}{" "}
                      </h5>
                      <div>{item.message}</div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
            <div className="delete--admin--container__footer">
              <p
                style={{ border: "none" }}
                onClick={() =>
                  setIsKycRevokeLogModalVisible(!isKycRevokeLogModalVisible)
                }
              ></p>
              <button
                onClick={() =>
                  setIsKycRevokeLogModalVisible(!isKycRevokeLogModalVisible)
                }
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>

      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Delivery Person Details</span>
      </div>
      <div className="delivery--profile">
        <div className="delivery--profile__background">
          <div className="delivery--profile__background--color"></div>
        </div>
        <div className="delivery--profile__details">
          <div className="profile--header">
            <div className="profile--header__image">
              <img src={deliveryDetails.userImage} alt="Profile Image" />
            </div>
            <div className="profile--header__fullName">
              <p>{deliveryDetails.fullName}</p>
              <p>@ {deliveryDetails.userName}</p>
              <p>{deliveryDetails.gender}</p>
            </div>
          </div>
          <div className="profile--body">
            <div className="profile--body--children">
              <p>Email : {deliveryDetails.email}</p>
              <div className="profile--body--children__text">
                <p>Phone No : {deliveryDetails.phone}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>DOB : {deliveryDetails.dob}</p>
              <div className="profile--body--children__text">
                <p>Location : {deliveryDetails.location}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Identification Type : {deliveryDetails.identificationType}</p>
              <div className="profile--body--children__text">
                <p>Identification No. : {deliveryDetails.identificationNo}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Bike Type : {deliveryDetails.bikeType}</p>
              <div className="profile--body--children__text">
                <p>
                  Is Day Shift Available :
                  {deliveryDetails.dayTimeAvailability ? "Yes" : "No"}
                </p>
              </div>
            </div>
            {deliveryDetails.bikeType?.toUpperCase() === "BIKE" && (
              <div className="profile--body--children">
                <p>Bike Model : {deliveryDetails.bikeModel}</p>
                <div className="profile--body--children__text">
                  <p>Bike Number : {deliveryDetails.bikeNo}</p>
                </div>
              </div>
            )}
            <div className="profile--body--children">
              <p>
                Is Night Shift Available :{" "}
                {deliveryDetails.nightTimeAvailability ? "Yes" : "No"}
              </p>
              <div className="profile--body--children__text">
                <p>
                  Work Shift :{" "}
                  {deliveryDetails.workShift?.map((item) => {
                    return (
                      <span
                        style={{
                          background: "none",
                          color: "black",
                        }}
                      >
                        {item}
                      </span>
                    );
                  })}
                </p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                Resistration Status :{" "}
                <span>{deliveryDetails.registrationStatus}</span>
              </p>
              <div className="profile--body--children__text">
                <p>
                  Active Status :{" "}
                  <span>
                    {deliveryDetails.isActive ? "Active" : "Not Active"}
                  </span>
                </p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                Liscense Number :{" "}
                <span>
                  {deliveryDetails.additionalProfileInfo?.licenseNumber}
                </span>
              </p>
              <div className="profile--body--children__text">
                <p>
                  Bluebook Number :{" "}
                  <span>
                    {deliveryDetails.additionalProfileInfo?.blueBookNumber}
                  </span>
                </p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                {" "}
                Identification Document Front :{" "}
                <img
                  onClick={() => {
                    setActiveImage(
                      deliveryDetails.additionalProfileInfo
                        ?.identificationDocumentFront
                    );
                    setIsImageModalVisible(true);
                  }}
                  style={{ height: "90px", width: "90px" }}
                  src={
                    deliveryDetails.additionalProfileInfo
                      ?.identificationDocumentFront
                  }
                />
              </p>
              <div className="profile--body--children__text">
                <p>
                  Identification Document Back :{" "}
                  <img
                    style={{ height: "90px", width: "90px" }}
                    onClick={() => {
                      setActiveImage(
                        deliveryDetails.additionalProfileInfo
                          ?.identificationDocumentBack
                      );
                      setIsImageModalVisible(true);
                    }}
                    src={
                      deliveryDetails.additionalProfileInfo
                        ?.identificationDocumentBack
                    }
                  />
                </p>
              </div>
            </div>
            {deliveryDetails.bikeType?.toUpperCase() === "BIKE" && (
              <div className="profile--body--children">
                <p>
                  {" "}
                  Liscense Image:{" "}
                  <img
                    onClick={() => {
                      setActiveImage(
                        deliveryDetails.additionalProfileInfo?.licenseImage
                      );
                      setIsImageModalVisible(true);
                    }}
                    style={{ height: "90px", width: "90px" }}
                    src={deliveryDetails.additionalProfileInfo?.licenseImage}
                  />
                </p>
                <div className="profile--body--children__text">
                  <p>
                    Bluebook Last Renew Image :{" "}
                    <img
                      onClick={() => {
                        setActiveImage(
                          deliveryDetails.additionalProfileInfo
                            ?.blueBookLastRenewImg
                        );
                        setIsImageModalVisible(true);
                      }}
                      style={{ height: "90px", width: "90px" }}
                      src={
                        deliveryDetails.additionalProfileInfo
                          ?.blueBookLastRenewImg
                      }
                    />
                  </p>
                </div>
              </div>
            )}
            {deliveryDetails.bikeType?.toUpperCase() === "BIKE" && (
              <div className="profile--body--children">
                <p>
                  {" "}
                  Bluebook Last Owner Image:{" "}
                  <img
                    onClick={() => {
                      setActiveImage(
                        deliveryDetails.additionalProfileInfo
                          ?.blueBookLastOwnerImg
                      );
                      setIsImageModalVisible(true);
                    }}
                    style={{ height: "90px", width: "90px" }}
                    src={
                      deliveryDetails.additionalProfileInfo
                        ?.blueBookLastOwnerImg
                    }
                  />
                </p>
                <div className="profile--body--children__text">
                  <p>
                    Bluebook Vehicle No Image :{" "}
                    <img
                      onClick={() => {
                        setActiveImage(
                          deliveryDetails.additionalProfileInfo
                            ?.blueBookVehicleNumberImg
                        );
                        setIsImageModalVisible(true);
                      }}
                      style={{ height: "90px", width: "90px" }}
                      src={
                        deliveryDetails.additionalProfileInfo
                          ?.blueBookVehicleNumberImg
                      }
                    />
                  </p>
                </div>
              </div>
            )}
            {deliveryDetails.bikeType?.toUpperCase() === "BIKE" && (
              <div className="profile--body--children">
                <p>
                  {" "}
                  Bluebook Vehicle Details Image :{" "}
                  <img
                    onClick={() => {
                      setActiveImage(
                        deliveryDetails.additionalProfileInfo
                          ?.blueBookVehicleDetailsImg
                      );
                      setIsImageModalVisible(true);
                    }}
                    style={{ height: "90px", width: "90px" }}
                    src={
                      deliveryDetails.additionalProfileInfo
                        ?.blueBookVehicleDetailsImg
                    }
                  />
                </p>
                <div className="profile--body--children__text"></div>
              </div>
            )}
            <div className="profile--body--children">
              <p>
                KYC Status : <span>{deliveryDetails.kycStatus}</span>
              </p>
              <div className="profile--body--children__text">
                <p>Driver Note :{deliveryDetails.verificationNote}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "20px",
              }}
            >
              {deliveryDetails.kycStatus !== "KYC_VERIFIED" && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      setVerifyKYCmodal(true);
                    }}
                  >
                    Verify KYC
                  </Button>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                {deliveryDetails.kycStatus !== "KYC_UNVERIFIED" && (
                  <Button
                    danger
                    onClick={() => {
                      setRevokeKycVerificationModal(true);
                    }}
                  >
                    Revoke KYC
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={() => {
                    setIsKycRevokeLogModalVisible(true);
                  }}
                >
                  Revoke History Log
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* Verify kyc Modal */}
        <div
          className={`${
            verifyKYCmodal ? "delete--admin" : "delete--admin__hidden"
          }`}
        >
          <div className="delete--admin--container">
            <div className="delete--admin--container__heading">
              Are you sure to verify this driver?
            </div>
            <>
              <div className="delete--admin--container__body">
                <div>
                  {isLoadingOnLogin && (
                    <div className="deleting--something">
                      {" "}
                      <p>Verifying...</p>
                    </div>
                  )}
                </div>

                <div className="reason--enableDisable">
                  <label>Mention Reason: </label>
                  <textarea
                    type="text"
                    required
                    placeholder="Enter Reason"
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="delete--admin--container__footer">
                <button onClick={() => setVerifyKYCmodal(!verifyKYCmodal)}>
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    if (
                      confirmationText === "" ||
                      confirmationText === null ||
                      confirmationText === undefined
                    ) {
                      toast.error("Verify KYC reason cannot be blank");
                    } else {
                      dispatch({
                        type: "VERIFY_KYC_REQUEST",
                        payload: {
                          id,
                          confirmationText: confirmationText,
                          isReloadFn: true,
                          reloadFn: driverReloadFn,
                        },
                      });
                      setTimeout(() => {
                        setVerifyKYCmodal(false);
                      }, [2000]);
                    }
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          </div>
        </div>
        {/* revoke Verification Modal */}
        <div
          className={`${
            revokeKycVerificationModal
              ? "delete--admin"
              : "delete--admin__hidden"
          }`}
        >
          <div className="delete--admin--container">
            <div className="delete--admin--container__heading">
              Are you sure to undo verification for this driver?
            </div>
            <>
              <div className="delete--admin--container__body">
                <div>
                  {isLoadingOnLogin && (
                    <div className="deleting--something">
                      {" "}
                      <p>Loading...</p>
                    </div>
                  )}
                </div>

                <div className="reason--enableDisable">
                  <label>Mention Reason: </label>
                  <textarea
                    type="text"
                    required
                    placeholder="Enter Reason"
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                  ></textarea>
                </div>
                {/* <div className="reason--enableDisable">
                      <label>
                        Is SMS Send ? :{" "}
                        <Checkbox
                          value={isSendSMSForLogin}
                          onChange={(e) =>
                            setisSendSMSForLogin(e.target.checked)
                          }
                        />
                      </label>
                    </div> */}
              </div>
              <div className="delete--admin--container__footer">
                <button
                  onClick={() =>
                    setRevokeKycVerificationModal(!revokeKycVerificationModal)
                  }
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={(e) => {
                    if (
                      confirmationText === "" ||
                      confirmationText === null ||
                      confirmationText === undefined
                    ) {
                      toast.error("Revoke KYC reason cannot be blank");
                    } else {
                      dispatch({
                        type: "REVOKE_KYC_VERIFICATION_REQUEST",
                        payload: {
                          id,
                          confirmationText: confirmationText,
                          isReloadFn: true,
                          reloadFn: driverReloadFn,
                          // isSendSMSForLogin: isSendSMSForLogin,
                        },
                      });
                      setTimeout(() => {
                        setRevokeKycVerificationModal(false);
                      }, [2000]);
                    }

                    // dispatch({
                    //   type: "PUSH_INTO_KYC_REVOKE_LOG_REQUEST",
                    //   payload: {
                    //     id,
                    //     message: confirmationText,
                    //     isSendSMSForLogin: isSendSMSForLogin,
                    //   },
                    // });
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          </div>
        </div>
        <div className="delivery--profile__footer"></div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(DeliveryDetails);
