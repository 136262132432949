import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight, BiCut } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsX } from "react-icons/bs";
import { getCookie } from "../../FrontendHelper";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import UpdateExternalType from "./UpdateExternalType";
import UpdateInternalType from "./UpdateInternalType";
import { DatePicker } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import { FaCross } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import axios from "axios";

const mapStateToProps = (store) => {
  const {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
  } = store.mainState;
  return {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
  };
};

function Advertisement({
  advertisementList,
  isToogleSidebar,
  isLoadingOnLogin,
  isEnabledUsers,
  isVerifyChanged,
  isOperationSuccessful,
}) {
  const [editAdvertisement, setEditAdvertisement] = useState(false);
  const [edtingItem, setEditingItem] = useState(null);
  // for Enable/Disable adds
  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [startFrom, setStartFrom] = useState("");
  const [endDate, setEndDate] = useState("");

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    advertisementList !== undefined &&
    advertisementList.slice(indexOfFirstPost, indexOfLastPost);
  const [activeAd, setActiveAd] = useState("");
  const [markAsPopup, setMarkAsPopup] = useState(false);
  const [removeFromPopup, setRemoveFromPopup] = useState(false);

  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [usableImage, setUsableImage] = useState("");
  const [nonUsableImage, setNonUsableImage] = useState("");

  for (
    let i = 1;
    i <= Math.ceil(advertisementList.length / postsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "FETCH_ADVERTISEMENT_REQUEST" });
  }, [isVerifyChanged]);
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        setShowSmModal(false);
        dispatch({
          type: "FETCH_ADVERTISEMENT_REQUEST",
        });
      }, 1000);
    }
  }, [isEnabledUsers, isVerifyChanged]);
  useEffect(() => {
    setValidFrom("");
    setValidTo("");
  }, [isVerifyChanged]);
  const onChange = (value, dateString) => {
    setStartFrom(value);
  };
  const onChange1 = (value, dateString) => {
    setEndDate(value);
  };

  console.log("is Operation successful", isOperationSuccessful);
  useEffect(() => {
    setStartFrom("");
    setEndDate("");
    setMarkAsPopup(false);
    setRemoveFromPopup(false);
    setNonUsableImage("");
    setUsableImage("");
  }, [isOperationSuccessful]);

  const onAdvertisementImageChangeHandler = (image) => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    axios({
      method: "POST",
      url: `https://app.gurugfood.com:8444/foocery/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setNonUsableImage(response.data.imageUrl);
      })
      .catch((error) => {});
  };
  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Advertisement</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Advertisements</h4>
              <button
                onClick={() => dispatch({ type: "OPEN_ADVERTISEMENT_MODAL" })}
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
            {/* <div className="user_groups--button_n_search--search">
              <input type="text" placeholder="Search" />
            </div> */}
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="securityQtn--id">ID</th>
                  <th className="securityQtn--image">Image</th>
                  <th className="securityQtn--qtn">Advertisement Name</th>
                  <th className="securityQtn--qtn">Advertisement For</th>
                  <th className="securityQtn--qtn">Description</th>
                  <th className="securityQtn--updatedBy">Company Name</th>
                  <th className="securityQtn--updatedBy">Company Url</th>
                  <th className="securityQtn--state">Type</th>
                  <th className="securityQtn--state">Status</th>
                  <th className="securityQtn--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.length !== 0 &&
                  currentPosts.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <img src={item.advertisementURL} />
                      </td>
                      <td>{item.advertisementName}</td>
                      <td>{item.advertisementFor}</td>
                      <td>{item.advertisementDetails}</td>
                      <td>{item.companyName}</td>
                      <td>
                        <a href={item.companyURL}>{item.companyURL}</a>
                      </td>
                      <td>{item.advertisementLinkType}</td>
                      <td>
                        <span
                          style={{
                            backgroundColor: "purple",
                            padding: "2px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          {item.isActive ? "Active" : "Not Active"}
                        </span>
                      </td>
                      <td>
                        <Tippy
                          content={
                            <span>
                              {item.isActive ? "De-activate" : "Activate"}
                            </span>
                          }
                          placement="top"
                        >
                          <span>
                            <BsCheckCircle
                              className="action--edit__admin"
                              style={{ fontSize: "16px", marginLeft: "10px" }}
                              onClick={() => {
                                setShowSmModal(true);
                                setEnableDisableItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        <Tippy content="Edit" placement="bottom">
                          <span>
                            <AiFillEdit
                              className="edit--icon"
                              onClick={() => {
                                setEditAdvertisement(!editAdvertisement);
                                setEditingItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        {item.isPopupAllowed ? (
                          <Tippy content="Remove From Popup" placement="bottom">
                            <span>
                              <ImCross
                                style={{ fontSize: "11px" }}
                                className="edit--icon"
                                onClick={() => {
                                  setActiveAd(item.id);
                                  setRemoveFromPopup(true);
                                }}
                              />
                            </span>
                          </Tippy>
                        ) : (
                          <Tippy content="Mark As Popup" placement="bottom">
                            <span>
                              <TiTick
                                className="edit--icon"
                                onClick={() => {
                                  setActiveAd(item.id);
                                  setMarkAsPopup(true);
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              className={`${
                removeFromPopup ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to remove from popup ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setRemoveFromPopup(!removeFromPopup)}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "REMOVE_FROM_POPUP_REQUEST",
                          payload: {
                            id: activeAd,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* Mark AS POPUP */}
            <div
              className={`${
                markAsPopup ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Mark As Popup
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="Modal--form--title">
                      <p
                        style={{
                          display: "flex",
                          marginLeft: "7rem",
                          gap: "2rem",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="title">Image : </label>
                        <input
                          type="file"
                          style={{
                            border: "1px solid #D9D9D9",
                            width: "59%",
                            marginLeft: "3.5rem",
                          }}
                          name="image"
                          value={usableImage}
                          accept="image/*"
                          onChange={(e) => {
                            onAdvertisementImageChangeHandler(
                              e.target.files[0]
                            );
                            setUsableImage(e.target.value);
                          }}
                        />
                      </p>
                    </div>
                    <div className="Modal--form--title">
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "2rem",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="title">Verify From : </label>
                        <DatePicker
                          showTime
                          value={startFrom}
                          onChange={onChange}
                          style={{ height: "40px" }}
                        />
                      </p>
                    </div>
                    <div className="Modal--form--title">
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "2rem",
                          alignItems: "center",
                        }}
                      >
                        <label htmlFor="title">Verify To : </label>
                        <DatePicker
                          showTime
                          value={endDate}
                          onChange={onChange1}
                          style={{
                            marginLeft: "1.8rem",
                            height: "40px",
                            paddingTop: "-1rem",
                          }}
                        />
                      </p>
                    </div>

                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setMarkAsPopup(!markAsPopup)}>
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "MARK_AS_POPUP_REQUEST",
                          payload: {
                            id: activeAd,
                            popupImage: nonUsableImage,
                            validFrom: moment
                              .utc(startFrom)
                              .format("YYYY-MM-DD HH:mm:ss"),
                            validTo: moment
                              .utc(endDate)
                              .format("YYYY-MM-DD HH:mm:ss"),
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* for Enable/Disable Security Question  */}

            <div
              className={`${
                showSmModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    {enableDisableItem.isActive ? (
                      <h1>Are you sure to Disable this ??</h1>
                    ) : (
                      <h1>Are you sure to Enable this ??</h1>
                    )}
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setShowSmModal(!showSmModal)}>
                      Cancel
                    </button>
                    {enableDisableItem.isActive ? (
                      <button
                        onClick={() => {
                          let enableDisableId = enableDisableItem.id;
                          dispatch({
                            type: "DISABLE_PROMOTION_REQUEST",
                            payload: {
                              enableDisableId,
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          let enableDisableId = enableDisableItem.id;
                          dispatch({
                            type: "ENABLE_PROMOTION_REQUEST",
                            payload: {
                              enableDisableId,
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    )}
                  </div>
                </>
              </div>
            </div>
            {/* update advertisement */}
            <div
              className={`${
                editAdvertisement ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {edtingItem?.advertisementLinkType === "EXTERNAL" ? (
                  <UpdateExternalType item={edtingItem} />
                ) : (
                  <UpdateInternalType item={edtingItem} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setEditAdvertisement(!editAdvertisement);
                        dispatch({ type: "FETCH_ADVERTISEMENT_REQUEST" });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > advertisementList.length
                  ? advertisementList.length
                  : indexOfLastPost}{" "}
                of {advertisementList.length} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Advertisement);
