import React from 'react'
import { useEffect } from 'react'
import usePrevious from './useCompareStateCustomHook'
import song from "../../Images/noti.wav";
import { useState } from 'react';
import { setApiKey } from 'react-geocode';

function IndividualCard({value,title}) {
    // const [audio,setAudio] = useState(new Audio(song))
    // const [isPlaying,setIsPlaying] = useState(false)


    // const previousValue = usePrevious(value)

    // useEffect(() => {

    //     setIsPlaying(true)
    // },[])
    // // useEffect(()=> {
    // //     alert("smedsfsdf")
    // // },[previousValue])
    // useEffect(()=> {
    //     if(isPlaying) {
    //         handlePlay()
    //         // setIsPlaying(false)
    //     }
        
    // },[isPlaying])
    // const handlePlay = ()=> {
    //     if(isPlaying) {
    //         audio.play()
    //     } else {
    //         audio.pause()
    //     }

    //     setIsPlaying(!isPlaying)

    // }
    // console.log("The isPlaying status",isPlaying)
  return (
    <div className="item--container">
    <p>{value}</p>
    <p>{title}</p>
    {/* <button onClick={()=> {
        setIsPlaying(true)
    }}>play</button> */}
</div>
  )
}

export default React.memo(IndividualCard)
