import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit, AiOutlineFieldTime } from "react-icons/ai";
import { BiChevronLeft, BiChevronRight, BiCut } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsX } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import moment from "moment";
import EditPreOrderMerchant from "./EditPreOrderMerchant";
import AddPreOrderMerchant from "./AddPreOrderMerchant";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import { useHistory } from "react-router";
import { AiFillEye } from "react-icons/ai";
import PreOrderMerchantDetail from "./PreOrderMerchantDetail";

const mapStateToProps = (store) => {
  const {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
    resellerList,
    isResellarEditModal,
    preOrderMerchantList,
    isAddPreOrderMerchantList,
    isEditPreOrderMerchant,
  } = store.mainState;
  return {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
    resellerList,
    isResellarEditModal,
    preOrderMerchantList,
    isAddPreOrderMerchantList,
    isEditPreOrderMerchant,
  };
};

function PreOrderMerchant({
  isToogleSidebar,
  isLoadingOnLogin,
  isVerifyChanged,
  isOperationSuccessful,
  preOrderMerchantList,
  isAddPreOrderMerchantList,
  isEditPreOrderMerchant,
}) {
  const [editAdvertisement, setEditAdvertisement] = useState(false);
  const [edtingItem, setEditingItem] = useState(null);
  // for Enable/Disable adds
  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [startFrom, setStartFrom] = useState("");
  const [endDate, setEndDate] = useState("");
  const history = useHistory();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    preOrderMerchantList !== undefined &&
    preOrderMerchantList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  const [activateConfirmationText, setactivateConfirmationText] = useState("");
  const [deactivateConfirmationText, setdeactivateConfirmationText] =
    useState("");
  const [activateModal, setActivateModal] = useState("");
  const [deactivateModal, setDeactivateModal] = useState("");
  const [preOrderMerchantId, setPreOrderMerchantId] = useState("");
  const [showPreOrderMerchantModal, setShowPreOrderMerchantModal] =
    useState(false);
  const [activeDescription, setActiveDescription] = useState("");

  for (let i = 1; i <= preOrderMerchantList.totalPage; i++) {
    pageNumbers.push(i);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_PREORDER_MERCHANT_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, [isVerifyChanged, postsPerPage, currentPage, isOperationSuccessful]);
  useEffect(() => {
    if (isOperationSuccessful) {
      setActivateModal(false);
      setDeactivateModal(false);
      setactivateConfirmationText("");
      setdeactivateConfirmationText("");
    }
  }, [isOperationSuccessful]);
  return (
    <Layout>
      <div>
        {/* <div className="group_list">
          <h4>Advertisement</h4>
        </div> */}
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Preorder Merchant</h4>
              <button
                onClick={() =>
                  dispatch({ type: "OPEN_PREORDER_MERCHANT_MODAL" })
                }
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
            {/* <div className="user_groups--button_n_search--search">
              <input type="text" placeholder="Search" />
            </div> */}
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="securityQtn--image">Preorder Name</th>
                  <th className="securityQtn--qtn">Image</th>
                  <th className="securityQtn--state">Valid Till</th>
                  <th className="securityQtn--updatedBy">Valid From</th>
                  <th className="securityQtn--updatedBy">Delivery Start</th>
                  <th className="securityQtn--actions">Delivery End</th>
                  <th className="securityQtn--state">Initital Paying Price</th>
                  <th className="securityQtn--state">Total Amount</th>
                  <th className="securityQtn--updatedBy">Payment Method</th>

                  <th className="securityQtn--state">Minimum Order Qty.</th>
                  <th className="securityQtn--state">Active</th>
                  <th className="securityQtn--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts?.length !== 0 &&
                  currentPosts?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.preOrderName}</td>
                      <td>
                        {" "}
                        <img
                          src={item.preOrderImage}
                          style={{ height: "70px", width: "70px" }}
                        />{" "}
                      </td>
                      <td>
                        {" "}
                        {moment(item.validTill).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>
                        {" "}
                        {moment(item.validFrom).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>
                        {moment(item.deliveryStartFrom).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td>
                        {moment(item.deliveryEndFrom).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td>{item.planPrice}</td>
                      <td>{item.totalCost}</td>
                      <td>
                        {item.allowedPaymentMethods?.map((item) => (
                          <p>{item.allowedMethod}</p>
                        ))}
                      </td>
                      <td>{item.minimumOrderQuantity}</td>
                      <td>
                        {" "}
                        <span
                          style={{
                            backgroundColor: item.isActive ? "green" : "red",
                            padding: "2px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          {item.isActive ? "Yes" : "No"}
                        </span>
                      </td>

                      <td>
                        <Tippy content="Edit" placement="bottom">
                          <span>
                            <AiFillEdit
                              className="edit--icon"
                              onClick={() => {
                                dispatch({
                                  type: "OPEN_PREORDER_MERCHANT_EDIT_MODAL",
                                });
                                setEditingItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>

                        <Tippy content={<span>{"Order List"}</span>}>
                          <span>
                            <BsReverseLayoutTextSidebarReverse
                              style={{ fontSize: "12px" }}
                              className="action--eye__admin"
                              onClick={() =>
                                history.push(
                                  `/requested-pre-order-merchant-by-id/${item.id}`
                                )
                              }
                            />
                          </span>
                        </Tippy>
                        <Tippy content="Add Time Slot" placement="bottom">
                          <span>
                            <AiOutlineFieldTime
                              className="action--edit__admin"
                              onClick={() => {
                                history.push({
                                  pathname: `preorder-merchant-time-slot/${item.id}`,
                                  state: {
                                    startDate: item.deliveryStartFrom,
                                    endDate: item.deliveryEndFrom,
                                  },
                                });
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        <Tippy content={"View"} placement="top">
                          <span>
                            <AiFillEye
                              className="action--eye__admin"
                              onClick={() => {
                                setPreOrderMerchantId(item);
                                setShowPreOrderMerchantModal(true);
                                setActiveDescription(item.description);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        <Tippy
                          content={
                            <span>
                              {item.isActive ? "De-activate" : "Activate"}
                            </span>
                          }
                          placement="top"
                        >
                          <span>
                            <BsCheckCircle
                              className="action--eye__admin"
                              onClick={() => {
                                if (item.isActive) {
                                  setDeactivateModal(true);
                                } else {
                                  setActivateModal(true);
                                }
                                setEnableDisableItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* Add Pre Order Merchant  */}
            <div
              className={`${
                isAddPreOrderMerchantList
                  ? "mymodal modal_activated"
                  : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <AddPreOrderMerchant />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        dispatch({
                          type: "OPEN_PREORDER_MERCHANT_MODAL",
                        });
                        dispatch({
                          type: "FETCH_PREORDER_MERCHANT_REQUEST",
                          payload: {
                            postsPerPage,
                            currentPage,
                          },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Update delivery user details */}
            <div
              className={`${
                isEditPreOrderMerchant ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {edtingItem !== null && (
                  <EditPreOrderMerchant item={edtingItem} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        dispatch({
                          type: "OPEN_PREORDER_MERCHANT_EDIT_MODAL",
                        });
                        dispatch({
                          type: "FETCH_PREORDER_MERCHANT_REQUEST",
                          payload: {
                            postsPerPage,
                            currentPage,
                          },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${
                showPreOrderMerchantModal
                  ? "mymodal modal_activated"
                  : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {preOrderMerchantId !== null && (
                  <PreOrderMerchantDetail
                    preOrderMerchantData={preOrderMerchantId}
                    description={activeDescription}
                  />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowPreOrderMerchantModal(
                          !setShowPreOrderMerchantModal
                        );
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                activateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to activate this Preorder ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{
                          border: "1px solid #d7dbd8",
                          borderRadius: "5px",
                        }}
                        placeholder="Confirmation Text"
                        value={activateConfirmationText}
                        onChange={(e) =>
                          setactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setActivateModal(!activateModal)}>
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "ACTIVATE_PREORDER_MERCHANT_REQUEST",
                          payload: {
                            id: enableDisableItem.id,
                            values: {
                              confirmationText: activateConfirmationText,
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            <div
              className={`${
                deactivateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to deactivate this Preorder ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{
                          border: "1px solid #d7dbd8",
                          borderRadius: "5px",
                        }}
                        placeholder="Confirmation Text"
                        value={deactivateConfirmationText}
                        onChange={(e) =>
                          setdeactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setDeactivateModal(!deactivateModal)}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "DEACTIVATE_PREORDER_MERCHANT_REQUEST",
                          payload: {
                            id: enableDisableItem.id,
                            values: {
                              confirmationText: deactivateConfirmationText,
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>

            {/* update advertisement */}

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > preOrderMerchantList.length
                  ? preOrderMerchantList.length
                  : indexOfLastPost}{" "}
                of {preOrderMerchantList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(PreOrderMerchant);
