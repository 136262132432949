import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX, BsCheckCircle, BsEyeFill } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BatchDetail from "../BatchPakage/BatchDetail";
import AssignDriver from "../BatchPakage/DriverAssign/AssignDriver";
import { ImAccessibility } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { GrUndo } from "react-icons/gr";
import { Checkbox } from "antd";
import KYCdetails from "./KYCdetails";
import { toast } from "react-toastify";

function KYClist() {
  const {
    isEnabledUsers,
    isLoadingOnLogin,
    isToogleSidebar,
    isVerifyChanged,
    kycList,
  } = useSelector((state) => state.mainState);

  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showAssignDriverModal, setShowAssignDriverModal] = useState(false);
  const [openEye, setOpenEye] = useState(false);
  const [detailsShoingItem, setDetailsShhowingItem] = useState(null);

  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [assigningBatchId, setAssigningBatchId] = useState(null);

  //from delivery
  const [detailShoingDraftItem, setDetailShowingDraftItem] = useState(null);
  const [showDraftDriverModal, setShowDraftDriverModal] = useState(false);
  const [showDeleteSmModal, setShowDeleteSmModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [approvingItem, setApprovingItem] = useState(null);
  const [deletingId, setDeletingId] = useState(null);

  //verify kyc
  const [verifyingKYC, setVerifyingKYC] = useState("");
  const [verifyKYCmodal, setVerifyKYCmodal] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  //view kyc
  const [userDetailsId, setUserDetailsId] = useState(null);

  //revoke kyc verification
  const [revokeKycVerificationModal, setRevokeKycVerificationModal] =
    useState(false);
  const [isSendSMSForLogin, setisSendSMSForLogin] = useState(false);
  const [kycStatus, setKycStatus] = useState("KYC_UNVERIFIED");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);

  let pageNumbers = [];

  console.log("the kycList in the cmponent", kycList);

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (let i = 1; i <= kycList?.totalPage; i++) {
    pageNumbers.push(i);
  }

  const [rate, setRate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (kycStatus === "KYC_SUBMITTED") {
      dispatch({
        type: "FETCH_KYC_LIST_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
        },
      });
    } else if (kycStatus === "KYC_VERIFIED") {
      dispatch({
        type: "FETCH_VERIFIED_KYC_LIST_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
        },
      });
    } else if (kycStatus === "KYC_UNVERIFIED") {
      dispatch({
        type: "FETCH_UNVERIFIED_KYC_LIST_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
        },
      });
    }
  }, [currentPage, postsPerPage]);

  const unverifiedReloadFn = (response) => {
    console.log("unverifiedReloadFn", response);
    if (kycStatus === "KYC_SUBMITTED") {
      dispatch({
        type: "FETCH_KYC_LIST_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
        },
      });
    } else if (kycStatus === "KYC_VERIFIED") {
      dispatch({
        type: "FETCH_VERIFIED_KYC_LIST_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
        },
      });
    } else if (kycStatus === "KYC_UNVERIFIED") {
      dispatch({
        type: "FETCH_UNVERIFIED_KYC_LIST_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
        },
      });
    }
  };
  // const verifiedReloadFn = (response) => {
  //   console.log("verifiedReloadFn", response);
  // };
  // const submittedReloadFn = (response) => {
  //   console.log("submittedReloadFn", response);
  // };

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage KYC</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              {/* <h4>Know Your Customer</h4>
              <button onClick={() => setShowOptionModal(true)}>
                Add <i className="bi bi-plus c-plus" />
              </button> */}
              <label
                htmlFor=""
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                KYC Status
              </label>
              <select
                name="status"
                value={kycStatus}
                onChange={(e) => {
                  setKycStatus(e.target.value);
                  if (e.target.value === "KYC_SUBMITTED") {
                    dispatch({
                      type: "FETCH_KYC_LIST_REQUEST",
                      payload: {
                        currentPage,
                        postsPerPage,
                      },
                    });
                  } else if (e.target.value === "KYC_VERIFIED") {
                    dispatch({
                      type: "FETCH_VERIFIED_KYC_LIST_REQUEST",
                      payload: {
                        currentPage,
                        postsPerPage,
                      },
                    });
                  } else if (e.target.value === "KYC_UNVERIFIED") {
                    dispatch({
                      type: "FETCH_UNVERIFIED_KYC_LIST_REQUEST",
                      payload: {
                        currentPage,
                        postsPerPage,
                      },
                    });
                  }
                }}
              >
                <option value="KYC_SUBMITTED">SUBMITTED</option>
                <option value="KYC_VERIFIED">VERIFIED</option>
                <option value="KYC_UNVERIFIED">UNVERIFIED</option>
              </select>
            </div>
            {/* <div className="user_groups--button_n_search--search">
              <input type="text" placeholder="Search" />
            </div> */}
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="delivery--id">ID</th>
                  <th className="delivery--image">Image</th>
                  <th className="delivery--name">Name</th>
                  <th className="delivery--mobile">Phone Number</th>
                  <th className="delivery--idNo">Identification No.</th>
                  <th className="delivery--email">Email</th>
                  <th className="delivery--gender">Gender</th>
                  <th className="delivery--status">KYC Status</th>
                  <th className="delivery--status">Active Status</th>
                  <th className="delivery--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {kycList.data?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <img
                        className="myLargeImage"
                        src={item.userImage}
                        alt="image"
                      />
                    </td>
                    <td>{item.fullName}</td>
                    <td>{item.phone}</td>
                    <td>{item.identificationNo}</td>
                    <td>{item.email}</td>
                    <td>{item.gender}</td>
                    <td>
                      {item.kycStatus === "KYC_SUBMITTED"
                        ? "SUBMITTED"
                        : item.kycStatus}
                    </td>

                    <td>
                      <span
                        style={{
                          backgroundColor: "green",
                          borderRadius: "4px",
                          color: "white",
                        }}
                      >
                        {item.isActive ? "Active" : "Not Active"}
                      </span>
                    </td>
                    <td>
                      {item.kycStatus != "KYC_VERIFIED" && (
                        <Tippy content={"Verify KYC"}>
                          <span>
                            <TiTick
                              className="action--eye__admin"
                              onClick={() => {
                                setVerifyKYCmodal(true);
                                setVerifyingKYC(item.id);
                              }}
                            />
                          </span>
                        </Tippy>
                      )}
                      {item.kycStatus != "KYC_UNVERIFIED" && (
                        <Tippy content={"Revoke Verification"}>
                          <span>
                            <GrUndo
                              className="action--eye__admin"
                              onClick={() => {
                                setRevokeKycVerificationModal(true);
                                setVerifyingKYC(item.id);
                              }}
                            />
                          </span>
                        </Tippy>
                      )}

                      <Tippy content="View" placement="top">
                        <span>
                          <BsEyeFill
                            className="action--edit__admin"
                            onClick={() => {
                              setUserDetailsId(item.id);
                              setOpenEye(true);
                            }}
                          />{" "}
                        </span>
                      </Tippy>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* for active de-activation of announcement */}
            <form>
              <div
                className={`${
                  showOptionModal ? "delete--admin" : "delete--admin__hidden"
                }`}
              >
                <div className="delete--admin--container">
                  <div className="batch-option--container">
                    <div className="batch-option--container__options">
                      {/* <Link to="/add-normalorder-batch-package"> <p>Normal Order</p></Link> */}
                      <Link to="/add-preorder-batch-package">
                        {" "}
                        <p>Pre Order</p>
                      </Link>
                      <Link to="/add-campaignorder-batch-package">
                        {" "}
                        <p>Campaign Order</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* edit announcement modal*/}
            <div
              className={`${
                showEditModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {/* {editingId !== null && <EditAnnouncement id={editingId} myBody={announcementBody} />} */}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowEditModal(false);
                        dispatch({
                          type: "FETCH_USER_ANNOUNCEMENT_REQUEST",
                          payload: { currentPage, postsPerPage },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            {/* assign driver modal */}
            <div
              className={`${
                showAssignDriverModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <AssignDriver batchId={assigningBatchId} />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span onClick={() => setShowAssignDriverModal(false)}>
                      <BsX />
                    </span>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            {/* Show normal driver details */}
            <div
              className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {userDetailsId !== null && <KYCdetails id={userDetailsId} />}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span onClick={() => setOpenEye(!openEye)}>
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Verify kyc Modal */}
            <div
              className={`${
                verifyKYCmodal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading">
                  Are you sure to verify this driver?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div>
                      {isLoadingOnLogin && (
                        <div className="deleting--something">
                          {" "}
                          <p>Verifying...</p>
                        </div>
                      )}
                    </div>

                    <div className="reason--enableDisable">
                      <label>Mention Reason: </label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter Reason"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setVerifyKYCmodal(!verifyKYCmodal)}>
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => {
                        if (
                          confirmationText === "" ||
                          confirmationText === null ||
                          confirmationText === undefined
                        ) {
                          toast.error("Verify KYC reason cannot be blank");
                        } else {
                          dispatch({
                            type: "VERIFY_KYC_REQUEST",
                            payload: {
                              id: verifyingKYC,
                              confirmationText: confirmationText,
                              isReloadFn: true,
                              reloadFn: unverifiedReloadFn,
                            },
                          });
                        }

                        setTimeout(() => {
                          setVerifyKYCmodal(false);
                        }, [2000]);
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* revoke Verification Modal */}
            <div
              className={`${
                revokeKycVerificationModal
                  ? "delete--admin"
                  : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading">
                  Are you sure to undo verification for this driver?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div>
                      {isLoadingOnLogin && (
                        <div className="deleting--something">
                          {" "}
                          <p>Loading...</p>
                        </div>
                      )}
                    </div>

                    <div className="reason--enableDisable">
                      <label>Mention Reason: </label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter Reason"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                      ></textarea>
                    </div>
                    {/* <div className="reason--enableDisable">
                      <label>
                        Is SMS Send ? :{" "}
                        <Checkbox
                          value={isSendSMSForLogin}
                          onChange={(e) =>
                            setisSendSMSForLogin(e.target.checked)
                          }
                        />
                      </label>
                    </div> */}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() =>
                        setRevokeKycVerificationModal(
                          !revokeKycVerificationModal
                        )
                      }
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => {
                        if (
                          confirmationText === "" ||
                          confirmationText === null ||
                          confirmationText === undefined
                        ) {
                          toast.error("Revoke KYC reason cannot be blank");
                        } else {
                          dispatch({
                            type: "REVOKE_KYC_VERIFICATION_REQUEST",
                            payload: {
                              id: verifyingKYC,
                              confirmationText: confirmationText,
                              isReloadFn: true,
                              reloadFn: unverifiedReloadFn,
                              // isSendSMSForLogin: isSendSMSForLogin,
                            },
                          });
                        }

                        // dispatch({
                        //   type: "PUSH_INTO_KYC_REVOKE_LOG_REQUEST",
                        //   payload: {
                        //     id: verifyingKYC,
                        //     message: confirmationText,
                        //     // isSendSMSForLogin: isSendSMSForLogin,
                        //   },
                        // });
                        setTimeout(() => {
                          setRevokeKycVerificationModal(false);
                        }, [2000]);
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > kycList.totalData
                  ? kycList.totalData
                  : indexOfLastPost}{" "}
                of {kycList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default KYClist;
