import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight, BiCut } from "react-icons/bi";
import { BsCheckCircle, BsEye } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsX } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import moment from "moment";
import { Calendar, DatePicker, Tabs, Tooltip } from "antd";
import { Button } from "antd";
import { TiTick } from "react-icons/ti";
import ApproveOrderForm from "./ApproveOrderForm";
import { BsEyeFill } from "react-icons/bs";
import EditArchives from "./EditArchives";
import ViewReconcilement from "./ViewReconcilement";
import { Alert } from "antd";
import OrderDetails from "../Dashboard/OrderDetails";
import { Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { GiSkullCrossedBones } from "react-icons/gi";
import { Select, List, Typography } from "antd";

const { Option } = Select;

const mapStateToProps = (store) => {
  const {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
    resellerList,
    isResellarEditModal,
    reconcilementList,
    isApproveSuccess,
    archiveList,
    operatingLocationList,
  } = store.mainState;
  return {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
    resellerList,
    isResellarEditModal,
    reconcilementList,
    isApproveSuccess,
    archiveList,
    operatingLocationList,
  };
};

function Reconcilement({
  isToogleSidebar,
  isLoadingOnLogin,
  isVerifyChanged,
  isOperationSuccessful,
  resellerList,
  isResellarEditModal,
  reconcilementList,
  isApproveSuccess,
  archiveList,
  isEnabledUsers,
  operatingLocationList,
}) {
  const [edtingItem, setEditingItem] = useState(null);
  // for Enable/Disable adds
  const [enableDisableItem, setEnableDisableItem] = useState({});

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    archiveList !== undefined &&
    archiveList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  const [activateConfirmationText, setactivateConfirmationText] = useState("");
  const [deactivateConfirmationText, setdeactivateConfirmationText] =
    useState("");
  const [activateModal, setActivateModal] = useState("");
  const [deactivateModal, setDeactivateModal] = useState("");
  const [showResellerDetailModal, setShowResellerDetailModal] = useState(false);
  const [resellerId, setResellerId] = useState("");
  const [myReconcilementList, setMyReconcilementList] = useState([]);
  const [value, setValue] = useState(moment(Date.now()));
  const [isApproveOrderModal, setIsApproveOrderModal] = useState(false);
  const [isEditReconcilement, setIsEditReconcilement] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [activeArchives, setActiveArchieves] = useState(null);
  const [isViewOrder, setIsViewOrder] = useState(false);
  const [openEye, setOpenEye] = useState(false);
  const [userDetailsId, setUserDetailsId] = useState(null);
  const [opLocation, setOpLocation] = useState("");
  const [opLocationArchieve, setOpLocationArchieve] = useState("");

  const [filterDateArchive, setFilterDateArchive] = useState("");
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [everythingChecked, setEverytingChecked] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [myPaymentId, setMyPaymentId] = useState(null);
  const [isDriverAllocation, setIsDriverAllocation] = useState(false);

  const onSelect = (newValue) => {
    setValue(newValue);
  };

  const onPanelChange = (newValue) => {
    setValue(newValue);
  };

  for (let i = 1; i <= archiveList.totalPage; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    let postsPerPage = 50;
    let currentPage = 1;
    dispatch({
      type: "FETCH_OPERATING_LOCATION_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_RECONCILEMENT_REQUEST",
      payload: {
        postsPerPage,
        currentPage,
        reconcilementDate: value.format("YYYY-MM-DD"),
        operatingLocationId: opLocation,
      },
    });
  }, [
    isVerifyChanged,
    postsPerPage,
    currentPage,
    isOperationSuccessful,
    value,
    isEnabledUsers,
    isApproveSuccess,
  ]);

  useEffect(() => {
    if (selectedTab == 1) {
      dispatch({
        type: "FETCH_RECONCILEMENT_REQUEST",
        payload: {
          reconcilementDate: value.format("YYYY-MM-DD"),
          operatingLocationId: opLocation,
        },
      });
    } else {
      dispatch({
        type: "FETCH_ARCHIVES_REQUEST",
        payload: {
          postsPerPage,
          currentPage,
          reconcilementDate: filterDateArchive
            ? filterDateArchive?.format("YYYY-MM-DD")
            : "",
          operatingLocationId: opLocationArchieve,
        },
      });
    }
  }, [
    selectedTab,
    currentPage,
    postsPerPage,
    isApproveSuccess,
    filterDateArchive,
    isEnabledUsers,
    opLocation,
    opLocationArchieve,
  ]);

  useEffect(() => {
    if (isApproveSuccess) {
      setIsApproveOrderModal(false);
      setIsEditReconcilement(false);
      setMyReconcilementList([]);
    }
  }, [isApproveSuccess]);

  const handleIndividualCheck = (item, status) => {
    if (status) {
      setMyReconcilementList([...myReconcilementList, item]);
    } else {
      let xx = myReconcilementList.filter(
        (reconcilementItem) =>
          reconcilementItem.customerOrderId !== item.customerOrderId
      );
      setMyReconcilementList(xx);
    }
  };
  const handleEverythingCheck = (status) => {
    if (status) {
      setMyReconcilementList(reconcilementList?.orderDetails);
    } else {
      setMyReconcilementList([]);
    }
  };
  const getLocatDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  const onChange = (value, dateString) => {
    setFilterDateArchive(value);
  };

  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];

  return (
    <Layout>
      <div>
        <div className="user_groups">
          <div
            className="user_groups--button_n_search"
            style={{ marginBottom: "-3rem" }}
          >
            <div className="user_groups--button_n_search--button">
              <h4>Reconcilement</h4>
              {/* <button onClick={() => dispatch({ type: "OPEN_RESELLAR_MODAL" })}>
                Add <i className="bi bi-plus c-plus" />
              </button> */}
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          {selectedTab == 1 && (
            <>
              <Calendar
                style={{ marginTop: "-3rem" }}
                fullscreen={false}
                value={value}
                onSelect={onSelect}
                onPanelChange={onPanelChange}
              />

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginRight: "3rem",
                  }}
                >
                  {" "}
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={"Show All Dates"}
                    checked={isSwitchChecked}
                    onChange={(e) => setIsSwitchChecked(e)}
                  />
                  {myReconcilementList.length > 0 && (
                    <Button
                      type="primary"
                      shape="round"
                      style={{ background: "#3E455E", border: "none" }}
                      icon={<TiTick />}
                      size={"large"}
                      onClick={() => {
                        setIsApproveOrderModal(true);
                      }}
                    >
                      Approve Order
                    </Button>
                  )}{" "}
                </div>
              </div>

              <Alert
                style={{ margin: "20px" }}
                message={
                  <p>
                    You have reconcile dates on{" "}
                    {isSwitchChecked
                      ? reconcilementList?.unreconciledDateList?.map((item) => (
                          <span
                            onClick={() => {
                              setValue(moment(item));
                              setMyReconcilementList([]);
                              setEverytingChecked(false);
                            }}
                            style={{
                              marginRight: "2px",
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            {item}
                          </span>
                        ))
                      : reconcilementList?.unreconciledDateList
                          ?.filter((item) =>
                            item.includes(moment(value).format("YYYY"))
                          )
                          ?.map((item) => (
                            <span
                              onClick={() => {
                                setValue(moment(item));
                                setMyReconcilementList([]);
                                setEverytingChecked(false);
                              }}
                              style={{
                                marginRight: "2px",
                                cursor: "pointer",
                                color: "blue",
                              }}
                            >
                              {item}
                            </span>
                          ))}
                  </p>
                }
                type="info"
              />
            </>
          )}

          <div
            style={{
              display: "flex",

              margin: "20px",
            }}
          >
            <Tabs
              defaultActiveKey="1"
              type="card"
              onChange={(e) => setSelectedTab(e)}
            >
              <Tabs.TabPane tab="Reconcillation" key="1">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      Total Amount : ${" "}
                      {reconcilementList?.unreconciledAmountForDate}{" "}
                      <Tooltip title="See Drivers Amount">
                        <Button
                          onClick={() => {
                            setIsDriverAllocation(true);
                          }}
                          style={{ borderRadius: "50%" }}
                          icon={<BsEye />}
                        />
                      </Tooltip>
                    </p>
                  </div>
                  <Select
                    style={{
                      width: "220px",
                      marginBottom: "10px",
                      marginLeft: "1rem",
                      borderRadius: "10px",
                    }}
                    name=""
                    placeholder="Select Location"
                    id=""
                    onChange={(e) => setOpLocation(e)}
                  >
                    {operatingLocationList?.data?.map((item) => {
                      if (item.isActive) {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.locationName}
                          </Option>
                        );
                      }
                    })}
                  </Select>
                </div>
                <table>
                  <thead>
                    <tr style={{ backgroundColor: "pink" }}>
                      <th className="campaignItem--image">
                        <input
                          checked={everythingChecked}
                          type="checkbox"
                          onChange={(e) => {
                            handleEverythingCheck(e.target.checked);
                            setEverytingChecked(e.target.checked);
                          }}
                        />
                      </th>
                      <th className="order--itemName">Order ID</th>
                      <th className="order--itemName">Order Type</th>
                      <th className="order--customerName">Customer Name</th>
                      <th className="order--sheduledTime">Sheduled Time</th>
                      <th className="order--status">Date Of Order</th>
                      <th className="order--status">Assigned To</th>
                      <th className="order--grandTotal">Grand Total</th>
                      <th className="order--status">Payment Status</th>
                      <th className="order--status">Order Status</th>
                      <th className="order--actions" style={{ width: "15vw" }}>
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {reconcilementList?.orderDetails?.map((item) => (
                      <tr key={item.paymentId}>
                        <td>
                          <input
                            type="checkbox"
                            id={item.customerOrderId}
                            checked={myReconcilementList.includes(item)}
                            onChange={(e) =>
                              handleIndividualCheck(item, e.target.checked)
                            }
                          />
                        </td>
                        <td>{item.customerOrderId}</td>
                        <td>
                          {item.isPhoneOrder ? "Phone Order" : "Normal Order"}
                        </td>
                        <td>{item.deliveryInfoDto.deliveryFullName}</td>
                        <td>
                          {item.scheduledTime !== "null"
                            ? getLocatDateTime(item.scheduledTime)
                            : "Not Scheduled"}
                        </td>
                        <td>
                          {item.checkOutDateTime !== "null"
                            ? getLocatDateTime(item.checkOutDateTime)
                            : "Not Available"}
                        </td>
                        <td>
                          {item.deliveryAssignedTo !== null
                            ? `${item.deliveryAssignedTo}`
                            : "Not Assigned"}
                        </td>
                        <td>$ {item.actualPaymentData.grandTotal}</td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "purple",
                              color: "white",
                              padding: "3px 5px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.paymentStatus}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor: "purple",
                              color: "white",
                              padding: "3px 5px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.orderStatus}
                          </span>
                        </td>
                        <td>
                          <Tippy content="View" placement="bottom">
                            <span>
                              <BsEyeFill
                                className="action--edit__admin"
                                onClick={() => {
                                  setUserDetailsId(item.customerOrderId);
                                  setOpenEye(true);
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                          {item.paymentStatus == "VERIFICATION_PENDING" && (
                            <Button
                              style={{ borderRadius: "5px" }}
                              onClick={() => {
                                setMyPaymentId(item.paymentId);
                                setShowPaymentModal(true);
                              }}
                            >
                              Accept Payment
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Archives" key="2">
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    margin: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <label
                      htmlFor=""
                      style={{
                        marginLeft: "10px",
                        fontSize: "18px",
                        fontFamily: "bold",
                        color: "#3e445e",
                      }}
                    >
                      Location:
                    </label>
                    <Select
                      style={{
                        width: "220px",
                        marginLeft: "1rem",
                        borderRadius: "10px",
                      }}
                      name=""
                      placeholder="Select Location"
                      id=""
                      onChange={(e) => setOpLocationArchieve(e)}
                    >
                      <Option value="">All</Option>
                      {operatingLocationList?.data?.map((item) => {
                        if (item.isActive) {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.locationName}
                            </Option>
                          );
                        }
                      })}
                    </Select>
                  </div>

                  {/* <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#4E5673",
                    }}
                  >
                    Archives
                  </span> */}
                  <DatePicker
                    onChange={onChange}
                    style={{ width: "300px", borderRadius: "5px" }}
                    placeholder="Filter By Date"
                  />
                </div>
                <div className="table-container">
                  <table>
                    <thead>
                      <tr style={{ backgroundColor: "pink" }}>
                        <th className="order--itemName">Hand Over By</th>
                        <th className="order--customerName">Hand Over To</th>
                        <th className="order--sheduledTime">
                          Approved Comment
                        </th>
                        <th className="order--status">Is Bank Deposited</th>
                        <th className="order--status">Total Order Amount</th>
                        <th className="order--grandTotal">Total Order Count</th>
                        <th className="order--status">Deposit Amount</th>
                        <th className="order--status">Deposit Slip</th>
                        <th className="order--status">Reconcilement Date</th>

                        <th
                          className="order--actions"
                          style={{ width: "15vw" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {archiveList?.data?.map((item) => (
                        <tr key={Math.random()}>
                          <td>{item.handOverBy}</td>
                          <td>{item.handOverTo}</td>
                          <td>{item.approvalComment}</td>
                          <td>{item.isBankDepositedYet ? "Yes" : "No"}</td>
                          <td>$ {item.totalOrderAmount}</td>
                          <td> {item.totalOrderCount}</td>

                          <td>{item.depositAmount}</td>

                          <td>{item.bankDepositSlip}</td>
                          <td>{item.reconcilementDate}</td>

                          <td>
                            <Tippy content="View Orders" placement="bottom">
                              <span>
                                <BsEyeFill
                                  className="action--edit__admin"
                                  onClick={() => {
                                    setIsViewOrder(true);
                                    setActiveArchieves(item);
                                  }}
                                />{" "}
                              </span>
                            </Tippy>
                            <Tippy content="Edit" placement="bottom">
                              <span>
                                <AiFillEdit
                                  className="action--edit__admin"
                                  onClick={() => {
                                    setIsEditReconcilement(true);
                                    setActiveArchieves(item);
                                  }}
                                />{" "}
                              </span>
                            </Tippy>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="pagination">
                    <p>
                      Showing {indexOfFirstPost} to{" "}
                      {indexOfLastPost > archiveList.totalData
                        ? archiveList.totalData
                        : indexOfLastPost}{" "}
                      of {archiveList.totalData} entries
                    </p>
                    <nav aria-label="...">
                      <ul className="pagination">
                        {currentPage === 1 ? (
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <BiChevronLeft />
                            </a>
                          </li>
                        ) : (
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <BiChevronLeft
                                onClick={() => setCurrentPage(currentPage - 1)}
                              />
                            </a>
                          </li>
                        )}
                        {pageNumbers.map((x) => (
                          <li key={x} className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => setCurrentPage(x)}
                            >
                              {x}
                            </a>
                          </li>
                        ))}
                        {currentPage == pageNumbers.length ? (
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <BiChevronRight />
                            </a>
                          </li>
                        ) : (
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <BiChevronRight
                                onClick={() => setCurrentPage(currentPage + 1)}
                              />
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div
            className={`${
              isDriverAllocation ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div
                className="delete--admin--container__heading"
                style={{ color: "black" }}
              >
                Amount of Individual Driver
              </div>
              <>
                <div className="delete--admin--container__body">
                  <List
                    bordered={false}
                    dataSource={reconcilementList?.deliveryUserReconcileAmount}
                    renderItem={(item) => (
                      <List.Item>
                        <div
                          style={{
                            width: "400px",
                            padding: "10px",
                            boxShadow:
                              "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                          }}
                        >
                          <img
                            style={{
                              height: "80px",
                              width: "80px",
                              borderRadius: "50%",
                            }}
                            src={item.deliveryUserInfoResponse.imageUrl}
                          />
                          <span>{item.deliveryUserInfoResponse.fullName}</span>
                          <span> : $ {item.earnedAmount.toFixed(2)}</span>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={() => setIsDriverAllocation(!isDriverAllocation)}
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => setIsDriverAllocation(!isDriverAllocation)}
                  >
                    Ok
                  </button>
                </div>
              </>
            </div>
          </div>
          <div className="table-container">
            {/* Approve Order Modal */}
            <div
              className={`${
                isApproveOrderModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <ApproveOrderForm
                  myReconcilementList={myReconcilementList}
                  opLocation={opLocation}
                />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setEditingItem(" ");
                        setIsApproveOrderModal(false);
                        dispatch({
                          type: "FETCH_RECONCILEMENT_REQUEST",
                          payload: {
                            postsPerPage,
                            currentPage,
                          },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${
                showPaymentModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading">
                  <h1>Sure to Accept Payment of this Order ??</h1>{" "}
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setShowPaymentModal(!showPaymentModal)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch({
                          type: "ACCEPT_PAYMENT_OF_ORDER_REQUEST",
                          payload: {
                            paymentId: myPaymentId,
                            confirmationText: "yes",
                          },
                        });
                        setShowPaymentModal(false);
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>

            <div
              className={`${
                activateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to activate this Reseller ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{
                          border: "1px solid #d7dbd8",
                          borderRadius: "5px",
                        }}
                        placeholder="Confirmation Text"
                        value={activateConfirmationText}
                        onChange={(e) =>
                          setactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setActivateModal(!activateModal)}>
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "ACTIVATE_RESELLER_REQUEST",
                          payload: {
                            id: enableDisableItem.resellerId,
                            values: {
                              confirmationText: activateConfirmationText,
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            <div
              className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {userDetailsId !== null && (
                  <OrderDetails id={userDetailsId} opLocation={opLocation} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setOpenEye(!openEye);
                        setUserDetailsId(null);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                deactivateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to deactivate this Reseller ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{
                          border: "1px solid #d7dbd8",
                          borderRadius: "5px",
                        }}
                        placeholder="Confirmation Text"
                        value={deactivateConfirmationText}
                        onChange={(e) =>
                          setdeactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setDeactivateModal(!deactivateModal)}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "DEACTIVATE_RESELLER_REQUEST",
                          payload: {
                            id: enableDisableItem.resellerId,
                            values: {
                              confirmationText: deactivateConfirmationText,
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>

            <div
              className={`${
                isEditReconcilement ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {activeArchives !== null && (
                  <EditArchives item={activeArchives} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setIsEditReconcilement(!isEditReconcilement);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                isViewOrder ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {activeArchives !== null && (
                  <ViewReconcilement item={activeArchives} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setIsViewOrder(!isViewOrder);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            {/* update advertisement */}
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Reconcilement);
