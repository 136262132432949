import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import guruGLogo from "../../Images/foocery-logo-sm.png";
import AssignOrderToD from "../Orders/AssignOrderToD";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { BsX } from "react-icons/bs";
import LoadingComponent from "./../LoadingComponent";
import moment from "moment";
import { Divider, Input, Modal, Popconfirm } from "antd";
import { toast } from "react-toastify";
import AssignOrderToSuppliers from "../Orders/AssignOrderToSuppliers";

const mapStateToProps = (store) => {
  const {
    orderDetails,
    isEnabledUsers,
    isToogleSidebar,
    isLoadingOnLogin,
    isVerifyChanged,
  } = store.mainState;
  return {
    orderDetails,
    isEnabledUsers,
    isToogleSidebar,
    isLoadingOnLogin,
    isVerifyChanged,
  };
};

function InquiryDetails({
  id,
  orderDetails,
  isEnabledUsers,
  isToogleSidebar,
  isLoadingOnLogin,
  opLocation,
  isVerifyChanged,
}) {
  const dispatch = useDispatch();
  const [reasontoCancelOrder, setReasonToCancelOrder] = useState("");
  const [showResDetailModal, setShowResDetailModal] = useState(false);
  const [orderResDetailData, setOrderResDetailData] = useState(null);

  const [showCusDetailModal, setShowCusDetailModal] = useState(false);
  const [customerDetailsData, setCustomerDetailData] = useState(null);
  console.log("the customer detaildata", customerDetailsData);

  // enable /diable order
  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [reasonForEnableDisable, setReasonForEnableDisable] = useState("");
  // Update Cart item
  const [showUpdateCartModal, setShowUpdateCartModal] = useState(false);

  // Assign order
  const [showAssignOrderModal, setShowAssignOrderModal] = useState(false);
  const [orderId, setMyOrderId] = useState(null);
  // Accept Payment
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [myPaymentId, setMyPaymentId] = useState(null);
  // reject Payment
  const [showPaymentRejectModal, setShowPaymentRejectModal] = useState(false);
  const [myPaymentRejectId, setMyPaymentRejectId] = useState(null);
  // Approve Order
  const [showApproveOrderModal, setShowApproveOrderModal] = useState(false);
  const [approveOrderId, setApproveOrderId] = useState(null);
  // order ready
  const [showOrderReadyModal, setShowOrderReadyModal] = useState(false);
  const [orderReadyId, setOrderReadyId] = useState(null);
  // receive delivery order from comapny admin
  const [showReceiveOrderByDeliver, setShowReceiveOrderByDelivery] =
    useState(false);
  const [orderIdTobeReceived, setOrderIdTobeReceived] = useState(null);
  // delivery reached to the destination
  const [showReachedDestinationModal, setShowReachedDestinationModal] =
    useState(false);
  const [orderIdReachedDestinaiton, setOrderIdReachedDestination] =
    useState(null);
  // delivery completed status
  const [showDeliveryCompletedModal, setShowDeliveryCompletedModal] =
    useState(false);
  const [deliveryCompletedOrderId, setDeliveryCompletedOrderId] =
    useState(null);
  // cancel order
  const [showCancelOrderModal, setShowCancelModal] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);
  const [slipNo, setSlipNo] = useState(orderDetails.slipNo);
  const [updateSlipModal, setUpdateSlipModal] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState("");

  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  useEffect(() => {
    dispatch({
      type: "ORDER_DETAILS_REQUEST",
      payload: id,
    });
    setSlipNo(orderDetails.slipNo);
  }, [id, orderDetails.slipNo, isVerifyChanged]);
  useEffect(() => {
    if (isEnabledUsers === "b") {
      let orderStatus = "ALL";
      let currentPage = 1;
      let postsPerPage = 10;
      setTimeout(() => {
        setShowSmModal(false);
        dispatch({
          type: "ORDER_DETAILS_REQUEST",
          payload: id,
        });
        dispatch({
          type: "FETCH_ORDER_LIST_BY_STATUS_REQUEST",
          payload: { orderStatus, currentPage, postsPerPage, opLocation },
        });
        setShowPaymentModal(false);
        setShowAssignOrderModal(false);
        setMyOrderId(null);
        setShowPaymentRejectModal(false);
        setShowApproveOrderModal(false);
        setShowReceiveOrderByDelivery(false);
        setShowReachedDestinationModal(false);
        setShowDeliveryCompletedModal(false);
        setShowOrderReadyModal(false);
        setShowCancelModal(false);
        setReasonForEnableDisable("");
        setReasonToCancelOrder("");
        setShowUpdateCartModal(false);
      }, 1000);
    }
  }, [isEnabledUsers, orderDetails.slipNo]);
  return (
    <div className="orderDetailsShowing--container">
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Inquiry Details</span>
      </div>
      <p className="privacy-loading--onFetching">
        {isLoadingOnLogin && <LoadingComponent />}
      </p>
      {/* <div className="modal--print_export">
        <div className="modal--print_export--button">
          <button>Print</button>
          <button>Export As PDF</button>
        </div>
      </div> */}
      <hr />

      <div className="modal-orderDetails--heading">
        <div className="modal-orderDetails--heading__logo">
          <img src={guruGLogo} alt="Gurug Logo" />
        </div>
        <div className="modal-orderDetails--heading__description">
          <p>Foocery</p>
          <p>NSW, Australia</p>
          <p>021-590654, 9852030654</p>
          <p>contact@foocery.com</p>
        </div>
      </div>
      <hr />

      <div className="modal-orderDetails--body">
        {orderDetails.deliveryInfoDto !== undefined && (
          <>
            <div className="modal-orderDetails--body__orderFrom">
              <p>Inquiry From :</p>
              <p
                onClick={() => {
                  setCustomerDetailData(orderDetails.customerInfo);
                  setShowCusDetailModal(true);
                }}
                className="customerX--name"
              >
                {orderDetails.customerInfo.fullName}
              </p>
              <p>{orderDetails.customerInfo.location}</p>
              <p>
                {orderDetails.customerInfo.phoneNumber},
                {orderDetails.deliveryInfoDto.deliveryAlternateContactNo}
              </p>
            </div>
            <div className="modal-orderDetails--body__orderStatus">
              <p>
                Inquiry Status: <span>{orderDetails.orderStatus}</span>
              </p>
              <p>
                Payment Method: <span>{orderDetails.paymentMethod}</span>
              </p>
              <p>
                Payment Status: <span>{orderDetails.paymentStatus}</span>
              </p>
            </div>
            <div className="modal-orderDetails--body__orderid">
              <p>{orderDetails.customerOrderId}</p>
              <p>
                Date of Inquiry:{" "}
                {getLocalDateTime(orderDetails.checkOutDateTime)}
              </p>
              <p>
                Date of Delivery:{" "}
                {getLocalDateTime(orderDetails.scheduledTime) !== "Invalid date"
                  ? getLocalDateTime(orderDetails.scheduledTime)
                  : "ASAP"}
              </p>
              <p>Slip No: {orderDetails.slipNo}</p>
              <p
                onClick={() => {
                  setUpdateSlipModal(true);
                  setCurrentOrderId(orderDetails.customerOrderId);
                }}
                style={{ background: "#449343", color: "#FFC0CA" }}
                className="customerX--name"
              >
                Update Slip
              </p>
            </div>
          </>
        )}
      </div>
      <hr />
      <div className="modal-orderDetails--footer">
        <div className="modal-orderDetails--footer__orderTo">
          <div className="modal-orderDetails--footer_button"></div>
          <table>
            <thead>
              <tr>
                <th className="orderDetail--sn">S.N.</th>
                <th className="orderDetail--itemName">Item Name</th>
                <th className="orderDetail--restaurant">Restaurant Name</th>
                <th className="orderDetail--note">Note</th>
                <th className="orderDetail--quantity">Quantity</th>
                <th className="orderDetail--price">Price($)</th>
                <th className="orderDetail--total">Total($)</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails.cartData !== undefined &&
                orderDetails.cartData.cartItemDto.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.itemName}</td>
                    <td>
                      <span
                        className="restaurant--name__td"
                        onClick={() => {
                          setShowResDetailModal(true);
                          setOrderResDetailData(item);
                        }}
                      >
                        {item.restaurantName}
                      </span>
                    </td>
                    <td>{item.note}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price}</td>
                    <td>{item.cartItemTotal}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* ac05088a-7d5e-4b7b-b5bf-73687fbf1510 */}
      <div className="orderDetails--Footer">
        <div className="button--controll">
          {orderDetails.orderStatus === "ORDER_PLACED" &&
            orderDetails.paymentStatus !== "VERIFICATION_PENDING" && (
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowSmModal(true);
                  setEnableDisableItem(orderDetails);
                }}
              >
                <p>
                  <span>Accept Inquiry</span>
                </p>
              </div>
            )}
          {orderDetails.orderStatus === "ORDER_PLACED" &&
            orderDetails.paymentStatus === "VERIFICATION_PENDING" &&
            orderDetails.paymentMethod === "CASH_ON_DELIVERY" && (
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowSmModal(true);
                  setEnableDisableItem(orderDetails);
                }}
              >
                <p>
                  <span>Accept Inquiry</span>
                </p>
              </div>
            )}

          {orderDetails.orderStatus === "ORDER_CONFIRMED" && (
            <div
              className="button--controll--facebook"
              onClick={() => {
                setShowApproveOrderModal(true);
                setApproveOrderId(orderDetails.customerOrderId);
              }}
            >
              <p>
                <span>Approve Order</span>
              </p>
            </div>
          )}

          {orderDetails.orderStatus === "PROCESSING" && (
            <>
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowOrderReadyModal(true);
                  setOrderReadyId(orderDetails.customerOrderId);
                }}
              >
                <p>
                  <span>Order Ready</span>
                </p>
              </div>
            </>
          )}

          {orderDetails.orderStatus === "ORDER_READY" && (
            <>
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowReceiveOrderByDelivery(true);
                  setOrderIdTobeReceived(orderDetails.customerOrderId);
                }}
              >
                <p>
                  <span>Receive Order</span>
                </p>
              </div>
            </>
          )}

          {orderDetails.orderStatus === "DELIVERY_ON_THE_WAY" && (
            <div
              className="button--controll--facebook"
              onClick={() => {
                setShowReachedDestinationModal(true);
                setOrderIdReachedDestination(orderDetails.customerOrderId);
              }}
            >
              <p>
                <span>Reached Order</span>
              </p>
            </div>
          )}

          {(orderDetails.paymentStatus === "VERIFICATION_PENDING") &
          (orderDetails.paymentMethod !== "CASH_ON_DELIVERY") ? (
            <>
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setMyPaymentId(orderDetails.paymentId);
                  setShowPaymentModal(true);
                }}
              >
                <p>
                  <span>Accept Payment</span>
                </p>
              </div>
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowPaymentRejectModal(true);
                  setMyPaymentRejectId(orderDetails.paymentId);
                }}
              >
                <p>
                  <span>Reject Payment</span>
                </p>
              </div>
            </>
          ) : null}
          {orderDetails.paymentStatus === "VERIFICATION_PENDING" &&
          orderDetails.paymentMethod === "CASH_ON_DELIVERY" &&
          orderDetails.orderStatus === "DELIVERY_COMPLETED" ? (
            <>
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setMyPaymentId(orderDetails.paymentId);
                  setShowPaymentModal(true);
                }}
              >
                <p>
                  <span>Accept Payment</span>
                </p>
              </div>
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowPaymentRejectModal(true);
                  setMyPaymentRejectId(orderDetails.paymentId);
                }}
              >
                <p>
                  <span>Reject Payment</span>
                </p>
              </div>
            </>
          ) : null}
          {orderDetails.orderStatus === "DELIVERY_REACHED_DESTINATION" &&
            orderDetails.paymentStatus === "VERIFICATION_PENDING" && (
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowDeliveryCompletedModal(true);
                  setDeliveryCompletedOrderId(orderDetails.customerOrderId);
                }}
              >
                <p>
                  <span>Complete Delivery</span>
                </p>
              </div>
            )}

          {orderDetails.deliveryAssignedTo === null &&
            orderDetails.orderStatus !== "CANCELLED" && (
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowAssignOrderModal(true);
                  setMyOrderId(orderDetails.customerOrderId);
                }}
              >
                <p>
                  <span>Assign Inquiry</span>
                </p>
              </div>
            )}
          {orderDetails.deliveryAssignedTo !== null &&
            (orderDetails.orderStatus === "ORDER_PLACED" ||
              orderDetails.orderStatus === "ORDER_CONFIRMED" ||
              orderDetails.orderStatus === "PROCESSING" ||
              orderDetails.orderStatus === "ORDER_READY") && (
              <div
                className="button--controll--facebook"
                onClick={() => {
                  setShowAssignOrderModal(true);
                  setMyOrderId(orderDetails.customerOrderId);
                }}
              >
                <p>
                  <span>Re-assign Order</span>
                </p>
              </div>
            )}

          {(orderDetails.orderStatus === "ORDER_PLACED" ||
            orderDetails.orderStatus === "ORDER_CONFIRMED" ||
            orderDetails.orderStatus === "PROCESSING") && (
            <div
              className="button--controll--facebook"
              onClick={() => {
                setShowCancelModal(true);
                setCancelOrderId(orderDetails.customerOrderId);
              }}
            >
              <p>
                <span>Cancel Inquiry</span>
              </p>
            </div>
          )}
        </div>
        <div className="totalSection">
          {orderDetails.actualPaymentData !== undefined && (
            <div className="totalSection-container">
              {orderDetails.actualPaymentData.isCouponCodeApplied && (
                <p style={{ color: "green" }}>
                  Coupon Code Discount{" "}
                  <span>
                    $ {orderDetails.actualPaymentData.couponDiscountAmount}
                  </span>
                </p>
              )}
              {orderDetails.actualPaymentData.isCouponCodeApplied && (
                <p style={{ color: "green" }}>
                  CouponCode:
                  <span
                    style={{
                      backgroundColor: "purple",
                      color: "white",
                      padding: "3px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    {orderDetails.actualPaymentData.couponCode}
                  </span>
                </p>
              )}
              {orderDetails.actualPaymentData.isCoinUsed && (
                <p style={{ color: "green" }}>
                  Coin Used Discount{" "}
                  <span>
                    $ {orderDetails.actualPaymentData.coinEquivalentAmount}
                  </span>
                </p>
              )}
              <p style={{ color: "purple" }}>
                Sub-Total{" "}
                <span>$ {orderDetails.actualPaymentData.cartSubTotal}</span>
              </p>
              <div className="hrline"></div>
              <p>
                Service Charge{" "}
                <span>$ {orderDetails.actualPaymentData.serviceCharge}</span>
              </p>
              {orderDetails.actualPaymentData.deliveryCharge !== null && (
                <p>
                  Delivery Charge{" "}
                  <span>
                    $ {orderDetails.actualPaymentData.totalDeliveryCharge}
                  </span>
                </p>
              )}
              <p>
                Tax Amount{" "}
                <span>$ {orderDetails.actualPaymentData.taxAmount}</span>
              </p>
              <div className="hrline"></div>
              <p>
                Grand Total{" "}
                <span>$ {orderDetails.actualPaymentData.grandTotal}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      {/* update Slip */}
      <div
        className={`${
          updateSlipModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Please Enter Slip No Below:</h1>
          </div>
          <>
            <div className="delete--admin--container__body">
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                  marginTop: "-6rem",
                }}
              >
                <label>Slip No: </label>
                <Input
                  type={"number"}
                  value={slipNo}
                  onChange={(e) => setSlipNo(e.target.value)}
                  style={{ padding: "10px", borderRadius: "5px" }}
                />
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setUpdateSlipModal(!updateSlipModal)}>
                Cancel
              </button>

              <button
                type="submit"
                onClick={(e) => {
                  if (!slipNo) {
                    return toast.error("Please enter slip No !", {
                      toastId: 23232,
                    });
                  }

                  dispatch({
                    type: "UPDATE_EMPTY_BILL_REQUEST",
                    payload: {
                      id: currentOrderId,
                      data: { slipNo: slipNo, confirmationText: "Ok" },
                    },
                  });
                  setTimeout(() => {
                    setUpdateSlipModal(false);
                  }, [1500]);
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Accept order */}
      <div
        className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            {enableDisableItem.orderStatus === "ORDER_PLACED" ? (
              <h1>Are you sure to Accept this Inquiry ??</h1>
            ) : (
              <h1>You have already accepted this order.</h1>
            )}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowSmModal(!showSmModal)}>
                Cancel
              </button>
              {enableDisableItem.orderStatus === "ORDER_PLACED" ? (
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    let enableDisableId = enableDisableItem.customerOrderId;
                    dispatch({
                      type: "ACCEPT_ORDER_REQUEST",
                      payload: {
                        id: enableDisableId,
                        data: {
                          orderDecision: "ACCEPT",
                          confirmText: "yes",
                        },
                      },
                    });
                  }}
                >
                  Ok
                </button>
              ) : (
                <button>Ok</button>
              )}
            </div>
          </>
        </div>
      </div>
      {/* Show restaurant details */}
      <div
        className={`${
          showResDetailModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div
            className="headingX--container"
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "16px" }}>Merchant's Details</p>
            <p style={{ fontSize: "16px" }}>
              Commission Rate{" "}
              <span className="commissionPercentage">
                {orderResDetailData?.restaurantCommissionRate}%
              </span>
            </p>
          </div>
          <>
            <div
              className="delete--admin--container__body"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: "5vw",
                marginTop: "-60px",
              }}
            >
              <p>
                <span>Restaurant Name :</span>{" "}
                {orderResDetailData?.restaurantName}
              </p>
              <p>
                <span>Contact Name :</span>
                {orderResDetailData?.restaurantContactPersonName}{" "}
              </p>
              <p>
                <span>Contact Number :</span>
                {orderResDetailData?.restaurantContactNumber}
              </p>
              <p>
                <span>Location :</span> {orderResDetailData?.restaurantLocation}
              </p>
              <p>
                <span> IsPopular:</span>{" "}
                <span
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "3px 5px",
                    fontSize: "12px",
                  }}
                >
                  {orderResDetailData?.isRestaurantPopular ? "Yes" : "No"}
                </span>
              </p>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowResDetailModal(false)}>
                Cancel
              </button>
              <button onClick={() => setShowResDetailModal(false)}>Ok</button>
            </div>
          </>
        </div>
      </div>
      {/* Show customer details */}
      <div
        className={`${
          showCusDetailModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="headingX--container" style={{ padding: "20px" }}>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              Customer Details
            </p>
            <Divider />
          </div>
          <>
            <div
              className="delete--admin--container__body"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "-80px",
                marginLeft: "5vw",
              }}
            >
              <p>
                <span>Customer Name :</span> {customerDetailsData?.fullName}
              </p>
              <p>
                <span>Contact Number :</span> {customerDetailsData?.phoneNumber}
              </p>
              <p>
                <span>Location :</span> {customerDetailsData?.location}
              </p>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowCusDetailModal(false)}>
                Cancel
              </button>
              <button onClick={() => setShowCusDetailModal(false)}>Ok</button>
            </div>
          </>
        </div>
      </div>
      {/* Accept Payment */}
      <div
        className={`${
          showPaymentModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Sure to Accept Payment of this Order ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowPaymentModal(!showPaymentModal)}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "ACCEPT_PAYMENT_OF_ORDER_REQUEST",
                    payload: {
                      paymentId: myPaymentId,
                      confirmationText: "yes",
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Assign order to delivery person  */}
      {orderId !== null && (
        <div
          className={`${
            showAssignOrderModal ? "mymodal modal_activated" : "mymodal"
          }`}
        >
          <div
            className={`${
              !isToogleSidebar
                ? "manage_modal--container "
                : "manage_modal--container sidebarOpend_F_modal"
            }`}
          >
            {orderId !== null && <AssignOrderToSuppliers id={orderId} />}
          </div>
          <div
            className={`${
              !isToogleSidebar
                ? "task--rectangle"
                : "task--rectangle withsidebarOpend"
            }`}
          >
            <div className="task--rectangle__circle">
              <div className="circle">
                {" "}
                <span
                  onClick={() => {
                    setShowAssignOrderModal(!showAssignOrderModal);
                  }}
                >
                  <BsX />
                </span>
                <p>Close</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Update Cart item modal */}
      {/* <div
          className={`${
            showUpdateCartModal ? "mymodal modal_activated" : "mymodal"
          }`}
        >
          <div
            className={`${
              !isToogleSidebar
                ? "manage_modal--container "
                : "manage_modal--container sidebarOpend_F_modal"
            }`}
          >
             <UpdateOrderCartItem updatigOrderItem={updatigOrderItem}  />
          </div>
          <div
            className={`${
              !isToogleSidebar
                ? "task--rectangle"
                : "task--rectangle withsidebarOpend"
            }`}
          >
            <div className="task--rectangle__circle">
              <div className="circle">
                {" "}
                <span
                  onClick={() => {
                    setShowUpdateCartModal(!showUpdateCartModal);
                  }}
                >
                  <BsX />
                </span>
                <p>Close</p>
              </div>
            </div>
          </div>
        </div> */}

      {/* Reject payment */}
      <div
        className={`${
          showPaymentRejectModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Sure to Reject Payment of this Order ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() =>
                  setShowPaymentRejectModal(!showPaymentRejectModal)
                }
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "REJECT_PAYMENT_OF_ORDER_REQUEST",
                    payload: {
                      paymentId: myPaymentRejectId,
                      confirmationText: "yes",
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Approve Order */}
      <div
        className={`${
          showApproveOrderModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Sure to Approve this Order ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                  marginTop: "-6rem",
                }}
              >
                <label>Slip No: </label>
                <Input
                  type={"number"}
                  value={slipNo}
                  onChange={(e) => setSlipNo(e.target.value)}
                  style={{ padding: "10px", borderRadius: "5px" }}
                />
              </div>

              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() => setShowApproveOrderModal(!showApproveOrderModal)}
              >
                Cancel
              </button>
              {slipNo ? (
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({
                      type: "APPROVE_ORDER_REQUEST",
                      payload: {
                        id: approveOrderId,
                        values: {
                          orderDecision: "APPROVE",
                          confirmText: "Yes",
                          slipNo: slipNo,
                        },
                      },
                    });
                  }}
                >
                  Ok
                </button>
              ) : (
                <Popconfirm
                  title="Are you sure to proceed without Slip No?"
                  onConfirm={() => {
                    dispatch({
                      type: "APPROVE_ORDER_REQUEST",
                      payload: {
                        id: approveOrderId,
                        values: {
                          orderDecision: "APPROVE",
                          confirmText: "Yes",
                        },
                      },
                    });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <button>Ok</button>
                </Popconfirm>
              )}
            </div>
          </>
        </div>
      </div>
      {/* Receive Delivery Order from admin */}
      <div
        className={`${
          showReceiveOrderByDeliver ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Sure to Receive Delivery Order ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() =>
                  setShowReceiveOrderByDelivery(!showReceiveOrderByDeliver)
                }
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "RECEIVE_ORDER_REQUEST",
                    payload: {
                      id: orderIdTobeReceived,
                      values: {
                        confirmText: "yes",
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Delivery order reached to the destination */}
      <div
        className={`${
          showReachedDestinationModal
            ? "delete--admin"
            : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Delivery Order Reached Destination ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() =>
                  setShowReachedDestinationModal(!showReachedDestinationModal)
                }
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "REACHED_DELIVERY_DESTINATION_REQUEST",
                    payload: {
                      id: orderIdReachedDestinaiton,
                      values: {
                        confirmText: "Yes",
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Delivery completed Status */}
      <div
        className={`${
          showDeliveryCompletedModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Delivery Order Reached Destination ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() =>
                  setShowDeliveryCompletedModal(!showDeliveryCompletedModal)
                }
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "DELIVERY_COMPLETED_REQUEST",
                    payload: {
                      id: deliveryCompletedOrderId,
                      values: {
                        confirmText: "Yes",
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* order Ready */}
      <div
        className={`${
          showOrderReadyModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h2>Sure To Change Status To ORDER_READY ??</h2>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button
                onClick={() => setShowOrderReadyModal(!showOrderReadyModal)}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "SET_ORDER_READY_REQUEST",
                    payload: {
                      id: orderReadyId,
                      values: {
                        confirmText: "Yes",
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Cancel Order */}
      <div
        className={`${
          showCancelOrderModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h1>Are you sure to Cancel this Inquiry ??</h1>{" "}
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
              <div className="reason--enableDisable">
                <label>Reason To Cancel : </label>
                <textarea
                  type="text"
                  placeholder="Reason To Cancel Order"
                  value={reasontoCancelOrder}
                  onChange={(e) => setReasonToCancelOrder(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowCancelModal(!showCancelOrderModal)}>
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "CANCEL_ORDER_REQUEST",
                    payload: {
                      id: cancelOrderId,
                      values: {
                        cancellationReason: reasontoCancelOrder,
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      <h1 style={{ margin: "20px 20px 5px 20px" }}>Delivery Info :</h1>
      {orderDetails.deliveryInfoDto !== undefined && (
        <div className="delivery--info">
          <div className="delivery--info__firstContainer">
            <p>
              Assinged To: <span>{orderDetails.deliveryAssignedTo}</span>
            </p>
            <p>
              Delivery PickedUp Time:{" "}
              <span>
                {orderDetails.deliveryPickedUpTime !== null
                  ? getLocalDateTime(orderDetails.deliveryPickedUpTime)
                  : "Not Available"}
              </span>
            </p>
            <p>
              Delivery Reached Time:{" "}
              <span>
                {orderDetails.deliveryReachedTime !== null
                  ? getLocalDateTime(orderDetails.deliveryReachedTime)
                  : "Not Available"}
              </span>
            </p>
            <p>
              Delivered Time:{" "}
              <span>
                {orderDetails.deliveredTime !== null
                  ? getLocalDateTime(orderDetails.deliveredTime)
                  : "Not Available"}
              </span>
            </p>
            <p>
              Delivery Status: <span>{orderDetails.deliveryStatus}</span>
            </p>
            <p>
              Driver Note:{" "}
              <span style={{ color: "green" }}>{orderDetails.driverNote}</span>
            </p>
          </div>
          <div className="delivery--info__secondContainer">
            <div className="delivery--info__secondContainer--text">
              <p>
                Delivery Address:{" "}
                <span>{orderDetails.deliveryInfoDto.deliveryAddress}</span>
              </p>
              <p>
                Contact Person:{" "}
                <span>{orderDetails.deliveryInfoDto.deliveryFullName}</span>
              </p>
              <p>
                Contact Number:{" "}
                <span>
                  {orderDetails.deliveryInfoDto.deliveryContactNo},
                  {orderDetails.deliveryInfoDto.deliveryAlternateContactNo}
                </span>
              </p>
              <p>
                Scheduled Time:{" "}
                <span>
                  {orderDetails?.cartData?.deliveryInfoDto?.scheduledTime !==
                  null
                    ? getLocalDateTime(
                        orderDetails?.cartData?.deliveryInfoDto?.scheduledTime
                      )
                    : "Not Scheduled"}
                </span>
              </p>
              <p>
                Cancel Text: <span>{orderDetails.cancellationReason}</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps, null)(InquiryDetails);
