import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import { getAdminId } from '../../FrontendHelper'
import {CgProfile} from 'react-icons/cg'


const mapStateToProps = store => {
    const { adminProfile, isLoadingOnLogin } = store.mainState;
    return { adminProfile, isLoadingOnLogin }
}

function AdminProfile({ adminProfile, isLoadingOnLogin }) {
    let id = getAdminId("adminId");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "FETCH_ADMIN_BY_ID_REQUEST",
        })
    }, [])

    return (
        <Layout>
            <div style={{ minHeight: "71vh" }}>
                <div className="group_list">
                    <h4>Admin Profile</h4>
                </div>
                <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                <div className="user_groups">
                    <div class="common__container">
                        <div class="common__container--profile">
                            <div class="common__container--profile__details">
                                <div className="admin--profile_con">
                                    <p><CgProfile className="myProfile--icon" />{adminProfile.fullName}</p>
                                </div>
                                <hr />
                                
                                <p>Email : {adminProfile.email}</p>
                                <p>Mobile No : {adminProfile.phoneNo}</p>
                                <p>Active Status : <span>{adminProfile.isActive ? "Active" : "Not Active"}</span></p>
                                <p>Gender : {adminProfile.gender}</p>
                                <p>Dob : {adminProfile.dob}</p>
                                <p>Made By : {adminProfile.addedByUserName}</p>
                                <p>Created On : {adminProfile.createdOn}</p>
                                <p>Modified By : {adminProfile.updatedByUserName}</p>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
            <Footer />

        </Layout>
    )
}

export default connect(mapStateToProps, null)(AdminProfile)

