import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { Checkbox, Col, Row, Radio, DatePicker, Button } from "antd";
import moment from "moment";
import LoadingComponent from "../LoadingComponent";
import { toast } from "react-toastify";
import GetValidLocation from "../PreOrder/GetValidLocation";
import { GrLocation } from "react-icons/gr";
import { FaCheck } from "react-icons/fa";

// import { LatLangGenerator } from '../Coupon/LatLangGenerator';

const mapStateToProps = (store) => {
  const {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  } = store.mainState;
  return {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  };
};

function AddPreOrderMerchant({
  buttonText,
  isEnabledUsers,
  searchUsersList,
  isLoadingOnLogin,
  isOperationSuccessful,
}) {
  const dispatch = useDispatch();
  const innerClassRef = useRef();
  const { searchRestaurantList } = useSelector((state) => state.mainState);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const [preorderName, setPreOrderName] = useState("");
  const [preOrderImage, setPreOrderImage] = useState("");
  const [validTill, setValidTill] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [deliveryStartFrom, setDeliveryStartFrom] = useState("");
  const [deliveryEndFrom, setDeliveryEndFrom] = useState("");
  const [description, setDescription] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [isSendNotification, setIsSendNotification] = useState("");
  const [customMessageNotification, setCustomMessageNotification] =
    useState("");

  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [planTitle, setPlanTitle] = useState(null);
  const [planContactName, setPlanContactName] = useState(null);
  const [planContactEmail, setPlanContactEmail] = useState(null);
  const [planContactPhone, setPlanContactPhone] = useState(null);
  const [deliveryValidLocation, setDeliveryValidLocation] = useState(null);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [validRadius, setValidRadius] = useState(0);
  const [maxValidRadius, setMaxValidRadius] = useState(1);
  const [
    deliveryPricePerKmAfterValidRadius,
    setDeliveryPricePerKmAfterValidRadius,
  ] = useState("");
  const [minimumRequestCount, setMinimumRequestCount] = useState(null);
  const [maxRequestCount, setMaxRequestCount] = useState(null);
  const [isGlobalDelivery, setIsGlobalDelivery] = useState(false);
  const [isTakeAwayAllowed, setIsTakeAwayAllowed] = useState(false);
  const [stallDetails, setStallDetails] = useState([
    {
      stallLocation: "",
      stallLongitude: "",
      stallLatitude: "",
      stallName: "",
    },
  ]);
  const [myRestaurantName, setMyRestaurantName] = useState("");
  const [restaurantId, setRestaurantId] = useState(null);
  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState(false);

  const addStall = () => {
    setStallDetails([
      ...stallDetails,
      {
        stallLocation: "",
        stallLongitude: "",
        stallLatitude: "",
        stallName: "",
      },
    ]);
  };

  useEffect(() => {
    let operatingLocation = null;
    if (myRestaurantName.length > 2) {
      dispatch({
        type: "FETCH_RESTAURANT_LIST_REQUEST",
        payload: {
          keyWord: myRestaurantName,
          postsPerPage,
          currentPage,
          operatingLocation,
        },
      });
    }
  }, [myRestaurantName]);

  const handleStallLocationChange = (e, index) => {
    console.log(e, index, "from handle stall location");

    const list = [...stallDetails];
    list[index].stallLocation = e;
    setStallDetails(list);
  };
  const handleStallLatitudeLongitudeChange = (e, index) => {
    console.log(e, index, "from handle stall longitude and latitude");
    const list = [...stallDetails];
    list[index].stallLatitude = e.lat;
    list[index].stallLongitude = e.lng;
    setStallDetails(list);
  };

  const handleStallNameChange = (e, index) => {
    console.log(e, index, "from handle stall name");
    const list = [...stallDetails];
    list[index].stallName = e;
    setStallDetails(list);
  };

  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };

  const handleAddress = (address) => {
    setDeliveryValidLocation(address);
  };

  const handleAddResellar = (e) => {
    // if (minimumOrderQuantity < 1) {
    if (minimumRequestCount < 1) {
      return toast.error("Negative order quantity is not allowed !!");
    }
    e.preventDefault();
    if (
      !preorderName ||
      !preOrderImage ||
      !validTill ||
      !validFrom ||
      !deliveryStartFrom ||
      !deliveryEndFrom ||
      !description ||
      !planPrice ||
      // !minimumOrderQuantity
      !minimumRequestCount
    ) {
      return toast.error("Please fill all the fields !!");
    }
    dispatch({
      type: "ADD_PREORDER_MERCHANT_REQUEST",
      // payload: {
      //   preOrderName: preorderName,
      //   preOrderImage: imageUrl,
      //   validTill: moment(validTill).format("YYYY-MM-DD HH:mm:ss"),
      //   validFrom: moment(validFrom).format("YYYY-MM-DD HH:mm:ss"),
      //   deliveryStartFrom: moment(deliveryStartFrom).format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   ),
      //   deliveryEndFrom: moment(deliveryEndFrom).format("YYYY-MM-DD HH:mm:ss"),
      //   description: description,
      //   planPrice: planPrice,
      //   totalAmount: parseInt(totalAmount),
      //   minimumOrderQuantity: minimumOrderQuantity,
      //   allowedPaymentMethod:
      //     paymentMethod.length > 0 &&
      //     paymentMethod?.map((item) => {
      //       return {
      //         allowedMethod: item,
      //       };
      //     }),
      //   sendNotification: isSendNotification,
      //   customNotificationMessage: isSendNotification
      //     ? customMessageNotification
      //     : "",
      // },

      payload: {
        preOrderName: preorderName,
        description: description,
        preOrderImage: imageUrl,
        planPrice: planPrice,
        validFrom: moment(validFrom).format("YYYY-MM-DD HH:mm:ss"),
        validTill: moment(validTill).format("YYYY-MM-DD HH:mm:ss"),
        deliveryStartFrom: moment(deliveryStartFrom).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        deliveryEndFrom: moment(deliveryEndFrom).format("YYYY-MM-DD HH:mm:ss"),

        planContactPersonEmail: planContactEmail,
        planContactNumber: planContactPhone,
        planContactPersonFullName: planContactName,
        planTitle: planTitle,
        isGlobalDelivery: isGlobalDelivery,
        deliveryValidLocation: deliveryValidLocation,
        deliveryValidLongitude: longitude,
        deliveryValidLatitude: latitude,
        deliveryPricePerKmAfterValidRadius: deliveryPricePerKmAfterValidRadius,
        validRadius: isTakeAwayAllowed && !isDeliveryAllowed ? 0 : validRadius,
        maxValidRadius:
          isTakeAwayAllowed && !isDeliveryAllowed ? 1 : maxValidRadius,
        // minimumOrderQuantity: minimumOrderQuantity,
        minimumRequestCount: minimumRequestCount,
        maxRequestCount: maxRequestCount,
        sendNotification: isSendNotification,
        orderModes:
          isDeliveryAllowed === true && isTakeAwayAllowed === true
            ? "both"
            : isDeliveryAllowed === true && isTakeAwayAllowed === false
            ? "delivery"
            : isDeliveryAllowed === false && isTakeAwayAllowed === true
            ? "pickup"
            : null,
        distributorId: restaurantId,
        // totalAmount: parseInt(totalAmount),
        customNotificationMessage: isSendNotification
          ? customMessageNotification
          : "",
        allowedPaymentMethod:
          paymentMethod.length > 0 &&
          paymentMethod?.map((item) => {
            return {
              allowedMethod: item,
            };
          }),
        isTakeAwayAllowed: isTakeAwayAllowed,
        takeAwayStallsList: stallDetails,
      },
    });
  };
  useEffect(() => {
    if (isOperationSuccessful) {
      setPreOrderImage("");
      setPreOrderName("");
      setValidTill("");
      setValidFrom("");
      setDeliveryStartFrom("");
      setDeliveryEndFrom("");
      setDescription("");
      setPlanPrice("");
      setTotalAmount("");
      setMinimumOrderQuantity("");
      setPaymentMethod([]);
      setIsSendNotification("");
      setCustomMessageNotification("");
    }
  }, [isOperationSuccessful]);
  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", preOrderImage);

    axios({
      method: "POST",
      url: `https://app.gurugfood.com:8444/foocery/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
      })
      .catch((error) => {});
  }, [preOrderImage]);
  const onChange = (checkedValues) => {
    setPaymentMethod(checkedValues);
  };
  const onValidFromChange = (value, dateString) => {
    setValidFrom(value);
  };
  const onValidTillChange = (value, dateString) => {
    setValidTill(value);
  };

  const onDeliveryStartFromChange = (value, dateString) => {
    setDeliveryStartFrom(value);
  };
  const onDeliveryEndFromChange = (value, dateString) => {
    setDeliveryEndFrom(value);
  };
  const onChange1 = (e) => {
    setIsSendNotification(e.target.value);
  };
  return (
    <div className="drawer--header--new">
      <div className="Modal--heading drawer--title--new">
        Add Preorder Merchant
      </div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Pre-Order Name: </label>
              <input
                type="text"
                value={preorderName}
                placeholder="Pre-Order Name"
                onChange={(e) => {
                  setPreOrderName(e.target.value);
                }}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingTop: "2rem",
              }}
            >
              <label htmlFor="title">Pre-Order Description : </label>
              <textarea
                rows={10}
                type="text"
                placeholder="Description"
                value={description}
                style={{
                  padding: "10px",
                  minHeight: "8rem",
                  margin: "0 2rem",
                }}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Pre-Order Image: </label>
              <input
                type="file"
                placeholder="Preorder Image"
                onChange={(e) => setPreOrderImage(e.target.files[0])}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Title*: </label>
              <input
                type="text"
                placeholder="Plan Title"
                value={planTitle}
                onChange={(e) => setPlanTitle(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Plan Price : </label>
              <input
                type="number"
                placeholder="Plan Price"
                value={planPrice}
                onChange={(e) => setPlanPrice(e.target.value)}
              />
            </p>
          </div>

          {/* <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Total Amount : </label>

              <input
                type="number"
                placeholder="Total Amount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
              />
            </p>
          </div> */}
          {/* <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Minimum Order Quantity : </label>
              <input
                type="number"
                value={minimumOrderQuantity}
                placeholder="Minimum Order Quantity"
                onChange={(e) => setMinimumOrderQuantity(e.target.value)}
              />
            </p>
          </div> */}

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Contact Person Name*: </label>
              <input
                type="text"
                placeholder="Full Name"
                value={planContactName}
                onChange={(e) => setPlanContactName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Contact Person Phone*: </label>
              <input
                type="number"
                placeholder="Plan Contact Person Phone"
                value={planContactPhone}
                onChange={(e) => setPlanContactPhone(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Contact Person Email*: </label>
              <input
                type="text"
                placeholder="Email"
                value={planContactEmail}
                onChange={(e) => setPlanContactEmail(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Valid From: </label>
              <DatePicker
                value={validFrom}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onValidFromChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Valid Till: </label>
              <DatePicker
                value={validTill}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onValidTillChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Delivery Start Date: </label>
              <DatePicker
                value={deliveryStartFrom}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onDeliveryStartFromChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Delivery End Date: </label>
              <DatePicker
                value={deliveryEndFrom}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onDeliveryEndFromChange}
              />
            </p>
          </div>

          <div className="Modal--form--checkbox">
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                borderBottom: "3px solid #f8960d",
                background: "#0d84ce40",
                justifyContent: "center",
                padding: "1rem 1rem 0.5rem 1.5rem",
              }}
            >
              {" "}
              <label style={{ fontWeight: 500 }} htmlFor="added_by">
                Is Delivery{" "}
              </label>
              <input
                style={{ padding: 0, cursor: "pointer" }}
                type="checkbox"
                name="activStatus"
                checked={isDeliveryAllowed}
                value={isDeliveryAllowed}
                onChange={(e) => setIsDeliveryAllowed(e.target.checked)}
              />
            </p>
          </div>

          {isDeliveryAllowed && (
            <>
              <div className="Modal--form--title">
                <p style={{ display: "flex", alignItems: "flex-start" }}>
                  <label style={{ marginTop: "2rem" }} htmlFor="title">
                    Delivery Valid Location:{" "}
                  </label>
                  <div>
                    <GetValidLocation
                      handlelatLong={handlelatLong}
                      handleAddress={handleAddress}
                    />
                  </div>
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">Valid Radius (K.M)*: </label>
                  <input
                    type="number"
                    placeholder="Valid Radius (K.M)"
                    value={validRadius}
                    onChange={(e) => setValidRadius(e.target.value)}
                  />
                </p>
              </div>

              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">Max Valid Radius (K.M)*: </label>
                  <input
                    type="number"
                    placeholder="Max Valid Radius (K.M)"
                    value={maxValidRadius}
                    onChange={(e) => setMaxValidRadius(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">
                    Delivery Charge After Valid Radius Per KM*:{" "}
                  </label>
                  <input
                    type="number"
                    placeholder="Delivery Charge After Valid Radius Per KM"
                    value={deliveryPricePerKmAfterValidRadius}
                    onChange={(e) =>
                      setDeliveryPricePerKmAfterValidRadius(e.target.value)
                    }
                  />
                </p>
              </div>
            </>
          )}

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Minimum Request Count*: </label>
              <input
                type="number"
                min={0}
                max={maxRequestCount}
                placeholder="Minimum Request Count"
                value={minimumRequestCount}
                onChange={(e) => setMinimumRequestCount(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Maximum Request Count*: </label>
              <input
                type="number"
                min={minimumRequestCount}
                max={1000}
                placeholder="Maximum Request Count"
                value={maxRequestCount}
                onChange={(e) => setMaxRequestCount(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Send Notification ?:</label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={isSendNotification}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          {isSendNotification && (
            <>
              <div className="Modal--form--title">
                <p
                  style={{
                    display: "flex",
                    alignItems: "flex-start !important",
                  }}
                >
                  <label style={{ marginTop: "2rem" }}>
                    Notification Message :{" "}
                  </label>
                  <div>
                    <textarea
                      type="text"
                      placeholder="Custom Message"
                      style={{ padding: "1rem", height: "8rem" }}
                      value={customMessageNotification}
                      onChange={(e) =>
                        setCustomMessageNotification(e.target.value)
                      }
                    />
                    <p style={{ color: "red", paddingLeft: "2rem" }}>
                      Default Message will be sent if left empty !
                    </p>
                  </div>
                </p>
              </div>
            </>
          )}
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Payment Methods : </label>
              <Checkbox.Group
                value={paymentMethod}
                onChange={onChange}
                style={{ marginLeft: "2rem", width: "25%", marginTop: "1rem" }}
              >
                <Row>
                  {/* <Col span={8}>
                    <Checkbox value="KHALTI">Khalti</Checkbox>
                  </Col> */}
                  <Col span={8}>
                    <Checkbox value="STRIPE">Stripe</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      value="CASH_ON_DELIVERY"
                      style={{ width: "200px" }}
                    >
                      Cash On Delivery
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label style={{ paddingBottom: "1rem" }} htmlFor="added_by">
                Is Global Delivery:{" "}
              </label>
              <input
                type="checkbox"
                name="activStatus"
                checked={isGlobalDelivery}
                value={isGlobalDelivery}
                style={{
                  height: 15,
                  width: 15,
                  cursor: "pointer",
                }}
                onChange={(e) => setIsGlobalDelivery(e.target.checked)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Restaurant Name: </label>
              <input
                type="text"
                placeholder="Search Restaurant Name"
                value={myRestaurantName}
                onChange={(e) => setMyRestaurantName(e.target.value)}
              />
            </p>
          </div>

          <div className="showRestaurantOption--addpreorder">
            <div className="new--resturant--list">
              {searchRestaurantList?.data
                ?.filter((item) => item?.isSupplier === true)
                ?.map((item) => (
                  <div
                    className={`${restaurantId === item?.id && "active"}`}
                    onClick={() => setRestaurantId(item?.id)}
                  >
                    <p>{item?.restaurantName}</p>
                    <p>
                      <GrLocation />
                      {item?.location}
                    </p>
                    <p>
                      <FaCheck />
                    </p>
                  </div>
                ))}
            </div>
          </div>

          <div className="Modal--form--checkbox">
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                borderBottom: "3px solid #f8960d",
                background: "#0d84ce40",
                justifyContent: "center",
                padding: "1rem 1rem 0.5rem 1.5rem",
              }}
            >
              <label style={{ fontWeight: 500 }} htmlFor="added_by">
                Is PickUp{" "}
              </label>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                name="activStatus"
                checked={isTakeAwayAllowed}
                value={isTakeAwayAllowed}
                onChange={(e) => setIsTakeAwayAllowed(e.target.checked)}
              />
            </p>
          </div>

          {isTakeAwayAllowed && (
            <div>
              <div
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <h3 style={{ marginLeft: "5vw" }}>Add Stall Details : </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    marginLeft: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#3e445e",
                      color: "white",
                    }}
                    onClick={addStall}
                  >
                    Add
                  </Button>
                  {stallDetails.length > 1 && (
                    <div className="remove--timeSlot__btn">
                      <div
                        onClick={() => {
                          setStallDetails([
                            {
                              stallLocation: "",
                              stallLongitude: "",
                              stallLatitude: "",
                              stallName: "",
                            },
                          ]);
                        }}
                        style={{
                          color: "white",
                          whiteSpace: "nowrap",
                          width: "90px",
                        }}
                      >
                        Remove All
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {stallDetails?.map((stall, index) => (
                <Fragment key={index}>
                  <div className="Modal--form--title">
                    <p>
                      <label htmlFor="added_by">Stall Number*: </label>
                      <input
                        type="number"
                        placeholder="Stall Number"
                        value={stallDetails[index].stallName}
                        onChange={(e) => {
                          handleStallNameChange(e.target.value, index);
                        }}
                      />
                    </p>
                  </div>
                  <div className="Modal--form--title">
                    <p style={{ display: "flex", alignItems: "flex-end" }}>
                      <label htmlFor="title">Stall Location: </label>
                      <GetValidLocation
                        handlelatLong={(val) =>
                          handleStallLatitudeLongitudeChange(val, index)
                        }
                        handleAddress={(val) =>
                          handleStallLocationChange(val, index)
                        }
                        ref={(ref) => (innerClassRef.current = ref)}
                      />
                    </p>
                  </div>
                </Fragment>
              ))}
            </div>
          )}
          <div className="modal--container__group--buttons">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <button
                style={{ marginBottom: "50px", marginRight: "24rem" }}
                type="submit"
                className="btn btn-primary"
                onClick={handleAddResellar}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddPreOrderMerchant);
