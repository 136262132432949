import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../FrontendHelper";
import axios from "axios";
import { toast } from "react-toastify";
import { Radio, Tooltip } from "antd";

function AddCampaign() {
  const dispatch = useDispatch();
  const clearFileInputRef = useRef();
  const { buttonText, isEnabledUsers } = useSelector(
    (state) => state.mainState
  );
  const [campaignImage, setCampaighImage] = useState(null);
  const [campaignFor, setCampaignFor] = useState("CUSTOMER");
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [isAddItem, setIsAddItem] = useState(false);
  const [loading, setLoading] = useState(false);

  const [itemDetails, setItemDetails] = useState([
    {
      itemName: "",
      quantity: 0,
      description: "",
      itemImage: "",
      imageFullPath: "",
    },
  ]);

  const handleItemValueChange = (index, event) => {
    let values = [...itemDetails];
    values[index][event.target.name] = event.target.value;
    setItemDetails(values);
  };
  const handleAddItem = () => {
    setIsAddItem(true);
    setItemDetails([
      ...itemDetails,
      {
        itemName: "",
        quantity: 0,
        description: "",
        itemImage: "",
        imageFullPath: "",
      },
    ]);
  };
  const handleRemoveItem = (index) => {
    if (itemDetails.length > 1) {
      let values = [...itemDetails];
      values.splice(index, 1);
      setItemDetails(values);
    }
  };
  console.log("the isEnabledUser ius", isEnabledUsers);
  const handleItemImageChange = (index, event) => {
    setLoading(true);
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", event.target.files[0]);

    axios({
      method: "POST",
      url: `https://app.gurugfood.com:8444/foocery/v1/api/admin/campaign/upload-image`,
      // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        let values = [...itemDetails];
        values[index].itemImage = response.data?.imageUrl;
        values[index].imageFullPath = response.data?.imageFullPath;
        setItemDetails(values);
        setLoading(false);
      })
      .catch((error) => {
        console.log("The error after upload image", error);
        setLoading(false);
      });
  };
  const handleChampaignImageChange = (e) => {
    setLoading(true);
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", e.target.files[0]);

    axios({
      method: "POST",
      url: `https://app.gurugfood.com:8444/foocery/v1/api/admin/campaign/upload-image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setCampaighImage(response.data?.imageUrl);
        setLoading(false);
      })
      .catch((error) => {
        console.log("The error after upload image", error);
        setLoading(false);
      });
  };

  const handleAddCampaign = (e) => {
    if (
      !campaignFor ||
      !campaignName ||
      !campaignDescription ||
      !price ||
      !itemDetails[0].itemName ||
      !itemDetails[0].quantity ||
      !itemDetails[0].description
    ) {
      return toast.error("Please fill all the fields");
    }
    dispatch({
      type: "ADD_CAMPAIGN_REQUEST",
      payload: {
        campaignFor: campaignFor,
        campaignName: campaignName,
        campaignImage: campaignImage,
        description: campaignDescription,
        price: price,
        itemData: itemDetails,
      },
    });
    dispatch({ type: "ADD_CAMPAIGN_MODAL" });
  };

  // useEffect(()=> {
  //   if(isEnabledUsers==="b") {
  //     dispatch({
  //       type: "ADD_CAMPAIGN_MODAL"
  //     })
  //   }
  // },[isEnabledUsers])

  console.log("the itemDetails", itemDetails);

  return (
    <div>
      <div className="Modal--heading">Add Campaign</div>
      <button className="campaign--addItemButton" onClick={handleAddItem}>
        Add Campaign Item
      </button>
      <div className="Modal--form">
        <div className="Modal--form--title">
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <label htmlFor="title">Campaign For: </label>
            <Radio.Group
              style={{
                marginLeft: "2rem",
                display: "flex",
              }}
              onChange={(e) => {
                setCampaignFor(e.target.value);
              }}
              value={campaignFor}
            >
              <Radio
                value="CUSTOMER"
                style={{
                  margin: "0.2rem",
                  width: "100%",
                }}
              >
                Customer
              </Radio>
              <Radio
                value="MERCHANT"
                style={{
                  margin: "0.2rem",
                  width: "100%",
                }}
              >
                Merchant
              </Radio>
              <Radio
                value="BOTH"
                style={{
                  margin: "0.2rem",
                  width: "100%",
                }}
              >
                Both
              </Radio>
            </Radio.Group>
          </p>
        </div>

        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Image : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) => handleChampaignImageChange(e)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Campaign Name: </label>
            <input
              type="text"
              value={campaignName}
              placeholder="Campaign Name"
              onChange={(e) => setCampaignName(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Campaign Description: </label>
            <input
              type="text"
              value={campaignDescription}
              placeholder="Campaign Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Campaign Price: </label>
            <input
              type="text"
              value={price}
              placeholder="Campaign Price"
              onChange={(e) => setPrice(e.target.value)}
            />
          </p>
        </div>
        {itemDetails?.map((item, index) => (
          <>
            <div className="campaignItem">
              <div
                className="item__remove--button"
                key={index + 1}
                onClick={() => handleRemoveItem(index)}
              >
                Remove
              </div>
              <div className="campaignItem--container">
                <div className="item--input" key={index + 1}>
                  <div className="Modal--form--title">
                    <p>
                      <label htmlFor="title">Item Name : </label>
                      <input
                        type="text"
                        value={item.itemName}
                        name="itemName"
                        placeholder="Item Name"
                        onChange={(event) =>
                          handleItemValueChange(index, event)
                        }
                      />
                    </p>
                  </div>
                  <div className="Modal--form--title">
                    <p>
                      <label htmlFor="title">Item Quantity: </label>
                      <input
                        type="text"
                        value={item.quantity}
                        name="quantity"
                        placeholder="Item Quantity"
                        onChange={(event) =>
                          handleItemValueChange(index, event)
                        }
                      />
                    </p>
                  </div>
                  <div className="Modal--form--title">
                    <p>
                      <label htmlFor="title">Item Description: </label>
                      <textarea
                        type="text"
                        value={item.description}
                        name="description"
                        placeholder="Item Description"
                        onChange={(event) =>
                          handleItemValueChange(index, event)
                        }
                      ></textarea>
                    </p>
                  </div>
                </div>
                <div className="item--image">
                  <Tooltip title="Click Here to uplaod Image">
                    <div>
                      <img src={item.imageFullPath} alt="" />
                      <input
                        type="file"
                        onChange={(event) =>
                          handleItemImageChange(index, event)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        ))}
        <div className="modal--container__group--buttons">
          <button
            style={{ marginBottom: "50px" }}
            className="btn btn-primary"
            onClick={handleAddCampaign}
            disabled={loading}
          >
            {loading ? "Upoading..." : buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCampaign;
