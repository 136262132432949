import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { connect, useDispatch } from "react-redux";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { IoMan } from "react-icons/io5";
import { GoLocation } from "react-icons/go";
import { GrDatabase } from "react-icons/gr";
import { IoIosPersonAdd } from "react-icons/io";
import { BsX } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { useHistory } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";

import Footer from "../Z_Footer/Footer";
import LoadingComponent from "../LoadingComponent";
import DeliveryAddDetailModal from "./DeliveryAddDetailModal";
import dayjs from "dayjs";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isDeliveryFormModal,
    isToogleSidebar,
    deliveryInfoBAToCartData,
    myCustomerId,
    isEnabledUser,
    placeButtonText,
  } = store.mainState;
  return {
    buttonText,
    isDeliveryFormModal,
    isToogleSidebar,
    deliveryInfoBAToCartData,
    myCustomerId,
    isEnabledUser,
    placeButtonText,
  };
};

function DeliveryDetailInputForm({
  buttonText,
  isDeliveryFormModal,
  isToogleSidebar,
  deliveryInfoBAToCartData,
  myCustomerId,
  isEnabledUser,
  placeButtonText,
}) {
  const [showSmModal, setShowSmModal] = useState(false);
  const [showAbortSmModal, setShowAbourtSmModal] = useState(false);
  const [showAbortingText, setShowAbortingText] = useState(false);
  const [scheduledDateTime, setScheduledDateTime] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: "ADD_DELIVERY_ADD_FORM_MODAL",
    });
  }, []);

  const { deliveryInfoDto, paymentObject } = deliveryInfoBAToCartData;

  const handleIsSheduledDateTime = (item) => {
    setScheduledDateTime(item);
  };

  const handlePlacePhoneOrder = () => {
    dispatch({
      type: "PLACE_ORDER_FROM_PHONEORDER_REQUEST",
      payload: {
        cartId: deliveryInfoBAToCartData.id,
        uniquePaymentId: "ZTjQnHp8MU11gM1QlluM2mA9R9a",
        subTotal: paymentObject?.cartSubTotal,
        deliveryInfo: {
          deliveryAddress: deliveryInfoDto?.deliveryAddress,
          deliveryLatitude: deliveryInfoDto?.deliveryLatitude,
          deliveryLongitude: deliveryInfoDto?.deliveryLongitude,
          isScheduled: scheduledDateTime?.isScheduled,
          scheduledTime: scheduledDateTime?.finalZZ,
          deliveryContactNo: deliveryInfoDto?.deliveryContactNo,
          deliveryAlternateContactNo:
            deliveryInfoDto?.deliveryAlternateContactNo,
          deliveryFullName: deliveryInfoDto?.deliveryFullName,
        },
        deliveryCharge: paymentObject?.totalDeliveryCharge,
        taxableAmount: paymentObject?.taxableAmount,
        taxAmount: paymentObject?.taxAmount,
        cartSubTotal: paymentObject?.cartSubTotal,
        paymentMethod: "CASH_ON_DELIVERY",
        grandTotal: paymentObject?.grandTotal,
        userId: myCustomerId,
        serviceCharge: paymentObject.serviceCharge,
      },
    });
  };
  useEffect(() => {
    if (isEnabledUser === "B") {
      dispatch({
        type: "ADD_DELIVERY_ADD_FORM_MODAL",
      });
    }
    if (isEnabledUser === "ordered") {
      setTimeout(() => {
        history.push("/manage-phone-order");
      }, 1000);
    }
  }, [isEnabledUser]);

  const handleAbortModal = () => {
    setShowAbortingText(true);
    setTimeout(() => {
      window.location.replace("/dashboard");
      setShowAbortingText(false);
    }, 1000);
  };

  return (
    <Layout>
      <div>
        <div className="showFinal--cartItem">
          <div className="showFinal--cart__restaurantDetails">
            <div className="showFinal--cart__restaurantDetails--para">
              <p>Restaurant Name : {deliveryInfoBAToCartData.restaurantName}</p>
              <p>
                Date :{" "}
                {dayjs(deliveryInfoBAToCartData.addedDate).format(
                  "YYYY-MMM-DD HH:MM A"
                )}
              </p>
            </div>
            <p>
              Delivery To :{" "}
              {deliveryInfoBAToCartData.deliveryInfoDto?.deliveryFullName}
            </p>
            <p>
              Delivery Phone No. :{" "}
              {deliveryInfoBAToCartData.deliveryInfoDto?.deliveryContactNo},{" "}
              {
                deliveryInfoBAToCartData.deliveryInfoDto
                  ?.deliveryAlternateContactNo
              }{" "}
            </p>
            <p>
              Delivery Address :{" "}
              {deliveryInfoBAToCartData.deliveryInfoDto?.deliveryAddress}
            </p>
          </div>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="cartTable--sn">S.N.</th>
                  <th className="cartTable--itemName">Item Name</th>
                  <th className="cartTable--note">Note</th>
                  <th className="cartTable--price">Price</th>
                  <th className="cartTable--quantity">Quantity</th>
                  <th className="cartTable--total">Total</th>
                </tr>
              </thead>
              <tbody>
                {deliveryInfoBAToCartData.cartItemDto?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.itemName}</td>
                    <td>{item.note}</td>
                    <td>$ {item.price}</td>
                    <td>{item.quantity}</td>
                    <td>$ {item.cartItemTotal}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="totalSection">
            {deliveryInfoBAToCartData.paymentObject !== undefined && (
              <div className="totalSection-container" style={{ width: "100%" }}>
                {deliveryInfoBAToCartData.paymentObject.isCouponCodeApplied && (
                  <p style={{ color: "green" }}>
                    Coupon Code Discount{" "}
                    <span>
                      ${" "}
                      {
                        deliveryInfoBAToCartData.paymentObject
                          .couponDiscountAmount
                      }
                    </span>
                  </p>
                )}
                {deliveryInfoBAToCartData.paymentObject.isCouponCodeApplied && (
                  <p style={{ color: "green" }}>
                    CouponCode:
                    <span
                      style={{
                        backgroundColor: "purple",
                        color: "white",
                        padding: "3px 10px",
                        borderRadius: "5px",
                      }}
                    >
                      {deliveryInfoBAToCartData.paymentObject.couponCode}
                    </span>
                  </p>
                )}
                <p style={{ color: "purple" }}>
                  Sub-Total{" "}
                  <span>
                    $ {deliveryInfoBAToCartData.paymentObject.cartSubTotal}
                  </span>
                </p>
                <div className="hrline"></div>
                <p>
                  Service Charge{" "}
                  <span>
                    $ {deliveryInfoBAToCartData.paymentObject.serviceCharge}
                  </span>
                </p>
                {deliveryInfoBAToCartData.paymentObject.totalDeliveryCharge !==
                  null && (
                  <p>
                    Delivery Charge{" "}
                    <span>
                      ${" "}
                      {
                        deliveryInfoBAToCartData.paymentObject
                          .totalDeliveryCharge
                      }
                    </span>
                  </p>
                )}
                <p>
                  Tax Amount{" "}
                  <span>
                    $ {deliveryInfoBAToCartData.paymentObject.taxAmount}
                  </span>
                </p>
                <div className="hrline"></div>
                <p>
                  Grand Total{" "}
                  <span>
                    $ {deliveryInfoBAToCartData.paymentObject.grandTotal}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="make--order_button">
            <button onClick={() => setShowAbourtSmModal(true)}>
              Abort An Order
            </button>
            <button onClick={() => setShowSmModal(true)}>
              {placeButtonText}{" "}
              {isEnabledUser === "ordering" && (
                <label className="my--loadingicon">
                  <AiOutlineLoading3Quarters />
                </label>
              )}{" "}
              {isEnabledUser === "ordered" && (
                <label className="myTick--icon">
                  <TiTick />
                </label>
              )}
            </button>
          </div>
        </div>

        <Footer />
      </div>
      {/* Add delivery info modal */}
      <div
        className={`${
          isDeliveryFormModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <DeliveryAddDetailModal
            handleIsSheduledDateTime={handleIsSheduledDateTime}
          />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({
                    type: "ADD_DELIVERY_ADD_FORM_MODAL",
                  });
                  history.goBack();
                  dispatch({
                    type: "SET_CART_BUTTON_TO_DEFAULT",
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* place order modal */}
      <div
        className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading"></div>
          <>
            <div
              className="delete--admin--container__body"
              style={{ padding: "20px" }}
            >
              <h1>By clicking "OK" button, your order will be placed.</h1>
              <div>
                {isEnabledUser === "ordering" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUser === "ordered" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowSmModal(!showSmModal)}>
                Cancel
              </button>
              <button onClick={handlePlacePhoneOrder}>Ok</button>
            </div>
          </>
        </div>
      </div>
      {/* Abort Phone order modal */}
      <div
        className={`${
          showAbortSmModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading"></div>
          <>
            <div
              className="delete--admin--container__body"
              style={{ padding: "20px" }}
            >
              {showAbortingText ? (
                <h1>Aborting...</h1>
              ) : (
                <h1>By clicking "OK" button, your order will be aborted.</h1>
              )}
              <div>
                {isEnabledUser === "ordering" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUser === "ordered" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowAbourtSmModal(!showAbortSmModal)}>
                Cancel
              </button>
              <button onClick={handleAbortModal}>Ok</button>
            </div>
          </>
        </div>
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(DeliveryDetailInputForm);
