import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import GoogleMap from "./GoogleMap";
import { getCookie } from "../../FrontendHelper";
import TimePicker from "react-time-picker";
import { MdDelete } from "react-icons/md";
import { GrDocumentCsv } from "react-icons/gr";
import { Checkbox, Radio, Tooltip } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import Menu from "./Menu/Menu";
import { BsX } from "react-icons/bs";
import AddSubCategory from "./MerchantSubCategory/AddSubCategory";
import AddMerchantCategory from "./MerchantCategory/AddMerchantCategory";

const mapStateToProps = (store) => {
  const {
    buttonText,
    operatingLocationList,
    isToogleSidebar,
    isEnabledUsers,
    merchantCategories,
    merchantSubCategories,
  } = store.mainState;
  return {
    buttonText,
    operatingLocationList,
    isToogleSidebar,
    isEnabledUsers,
    merchantCategories,
    merchantSubCategories,
  };
};

function AddRestaurant({
  buttonText,
  operatingLocationList,
  isToogleSidebar,
  isEnabledUsers,
  merchantCategories,
  merchantSubCategories,
}) {
  const dispatch = useDispatch();
  // const [isEnabledUser,setIsEnabledUser] = useState("")

  const [restaurantName, setRestaurantName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [slogan, setSlogan] = useState("");
  const [restEmail, setRestEmail] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const clearFileInputRef = useRef(null);
  const [contactPersonNo, setContactNo] = useState("");
  const [ContactPersonName, setContactPersonName] = useState("");
  const [commissionRate, setCommissionRate] = useState("");
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [operatingLocation, setOperatingLocation] = useState(null);
  const [orderMode, setOrderMode] = useState(["PICKUP", "DELIVERY"]);
  const [deliveryOption, setDeliveryOption] = useState("FOOCERY_DELIVERY");
  const [isVisibleForUser, setIsVisibleForuser] = useState("");
  const [isSupplier, setIsSupplier] = useState(false);
  const [isAddSubCategoryModalVisible, setIsAddSubCategoryModalVisible] =
    useState(false);
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] =
    useState(false);

  const [files, setFiles] = useState([]);
  const [restaurantImg, setRestaurantImg] = useState(null);
  const [csvFile, setCsvFile] = useState(null);

  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      url: `https://app.gurugfood.com:8444/foocery/v1/api/admin/restaurant/add-image`,
      // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/restaurant/add-image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
        setIsSuccessImgUrl(!isSuccessImgUrl);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);

  useEffect(() => {
    let postsPerPage = 50;
    let currentPage = 1;
    dispatch({
      type: "FETCH_OPERATING_LOCATION_REQUEST",
      payload: { postsPerPage, currentPage },
    });
    dispatch({
      type: "FETCH_MERCHANT_CATEGORY_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, []);

  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setLocation(address);
  };
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    let openingTimeX = `2021-01-15 ${openingTime}`;
    let closingTimeX = `2021-01-15 ${closingTime}`;
    let m = new Date(openingTimeX).toUTCString().split(" ");
    let n = new Date(closingTimeX).toUTCString().split(" ");
    dispatch({
      type: "ADD_RESTAURANTS_REQUEST",
      payload: {
        restaurantName: restaurantName,
        location: location,
        latitude: latitude,
        longitude: longitude,
        slogan: slogan,
        description: description,
        email: restEmail,
        phoneNumber: phoneNo,
        imageUrl: imageUrl,
        contactPersonName: ContactPersonName,
        contactPersonPhone: contactPersonNo,
        commissionRate: commissionRate,
        openingTime: m[4],
        closingTime: n[4],
        OperatingLocation: operatingLocation,
        isVisibleForUsers: isVisibleForUser,
        category: category,
        subCategory: subCategory,
        orderModes: orderMode,
        deliveryOption: deliveryOption,
        isSupplier: isSupplier,
      },
    });
  };

  useEffect(() => {
    // if (isEnabledUsers === "b") {
    //   setIsAddCategoryModalVisible(false);
    //   setIsAddSubCategoryModalVisible(false);
    //   dispatch({
    //     type: "FETCH_MERCHANT_CATEGORY_REQUEST",
    //   });
    //   dispatch({
    //     type: "FETCH_MERCHANT_SUB_CATEGORY_REQUEST",
    //     payload: {
    //       categoryId: category,
    //       postsPerPage: 1,
    //       currentPage: 10,
    //     },
    //   });
    // }
    if (isEnabledUsers === "resAdded") {
      setRestaurantName("");
      setLocation("");
      setLatitude("");
      setLongitude("");
      setPhoneNo("");
      setSlogan("");
      setDescription("");
      setRestEmail("");
      setCommissionRate("");
      setContactPersonName("");
      setContactNo("");
      clearFileInputRef.current.value = "";
      setOpeningTime("");
      setClosingTime("");
      setOperatingLocation(null);
      setIsVisibleForuser("");
      setCategory("");
      setSubCategory([]);
      setOrderMode([]);
      setIsSupplier(false);
      setDeliveryOption("");
      dispatch({
        type: "ADD_RES_WITHOUT_CSV_MODAL",
      });
      dispatch({
        type: "FETCH_RESTAURANTS_REQUEST",
        payload: { sortingParam: "id", currentPage: 1, postsPerPage: 10 },
      });
    }
  }, [isEnabledUsers]);
  const fileSelectedHandler = (e) => {
    setFiles([...files, ...e.target.files]);
  };
  // useEffect(()=> {
  //     let newFiles = files.filter((item)=> item.name !== deletingImg)
  //     setFiles(newFiles)
  // },[deletingImg])
  const onChange1 = (e) => {
    setIsVisibleForuser(e.target.value);
  };
  return (
    <div>
      <div className="Modal--heading">Add Merchant/Supplier</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <label htmlFor="title">Category: </label>
              <Radio.Group
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  display: "flex",
                }}
                onChange={(e) => {
                  console.log("category name", e.target.value);
                  setCategory(e.target.value);
                  dispatch({
                    type: "FETCH_MERCHANT_SUB_CATEGORY_REQUEST",
                    payload: {
                      categoryId: e.target.value,
                      postsPerPage: 1,
                      currentPage: 10,
                    },
                  });
                }}
                value={category}
              >
                {merchantCategories.data?.map((item) => {
                  return (
                    <Radio
                      value={item?.id}
                      style={{
                        margin: "0.2rem",
                        width: "100%",
                        // display: "inline-block",
                      }}
                    >
                      {item?.categoryName}
                    </Radio>
                  );
                })}
              </Radio.Group>
              <Tooltip title="Add Category">
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddCategoryModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </Tooltip>
            </p>
          </div>
          <div className="Modal--form--title">
            <p
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label htmlFor="title">Sub-Category: </label>
              <Checkbox.Group
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  display: "flex",
                }}
                onChange={(e) => {
                  console.log("sub-category name", e);
                  setSubCategory(e);
                }}
                value={subCategory}
              >
                {merchantSubCategories.data?.map((item) => {
                  return (
                    <Checkbox
                      value={item?.id}
                      style={{
                        margin: "0.2rem",
                        width: "100%",
                      }}
                    >
                      {item?.categoryName}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
              <Tooltip title="Add Sub-Category">
                <a
                  className="plusButton"
                  onClick={() => {
                    setIsAddSubCategoryModalVisible(true);
                  }}
                >
                  <AiOutlinePlus className="iconColor" />
                </a>
              </Tooltip>
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title"> Image : </label>
              <input
                ref={clearFileInputRef}
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title"> Name: </label>
              <input
                type="text"
                value={restaurantName}
                placeholder="Merchant's Name"
                onChange={(e) => setRestaurantName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title"> Phone No : </label>
              <input
                type="text"
                value={phoneNo}
                placeholder="Phone Number"
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Contact Person Name : </label>
              <input
                type="text"
                value={ContactPersonName}
                placeholder="Contact Person Name"
                onChange={(e) => setContactPersonName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Contact Person Phone No : </label>
              <input
                type="text"
                value={contactPersonNo}
                placeholder="Contact Person Phone Number"
                onChange={(e) => setContactNo(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Description: </label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                style={{ padding: "10px", minHeight: "50px" }}
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Commission Rate(%) : </label>
              <input
                type="text"
                value={commissionRate}
                placeholder="Comission Rate (%)"
                onChange={(e) => setCommissionRate(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Slogan : </label>
              <input
                type="text"
                value={slogan}
                placeholder="Slogan"
                onChange={(e) => setSlogan(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Email : </label>
              <input
                type="email"
                placeholder="Email"
                value={restEmail}
                onChange={(e) => setRestEmail(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <div className="restaurant--Modal--form__time">
              <p>
                <label htmlFor="title">Opening Time : </label>
                <TimePicker
                  format="hh:mm a"
                  onChange={setOpeningTime}
                  name="Opening_Time"
                  value={openingTime}
                  className="myTime--picker"
                  style={{ marginLeft: "20px" }}
                />
              </p>
            </div>
          </div>
          <div className="Modal--form--title">
            <div className="restaurant--Modal--form__time">
              <p>
                <label htmlFor="title">Closing Time : </label>
                <TimePicker
                  format="hh:mm a"
                  onChange={setClosingTime}
                  name="Opening_Time"
                  value={closingTime}
                  className="myTime--picker"
                />
              </p>
            </div>
          </div>
          <div>
            <GoogleMap
              handlelatLong={handlelatLong}
              handleAddress={handleAddress}
            />
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Location : </label>
              <input
                type="text"
                value={location}
                disabled
                placeholder="Location"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Latitude : </label>
              <input
                type="text"
                placeholder="Latitude"
                value={latitude}
                disabled
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Longitude : </label>
              <input
                type="text"
                placeholder="Longitude"
                value={longitude}
                disabled
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Operating Location : </label>
              <select onChange={(e) => setOperatingLocation(e.target.value)}>
                <option value="">Select</option>
                {operatingLocationList.data?.map((location) =>
                  location.isActive ? (
                    <option key={location.id} value={location.locationName}>
                      {location.locationName}
                    </option>
                  ) : null
                )}
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Order Mode: </label>
              <Checkbox.Group
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  width: "50%",
                }}
                onChange={(e) => {
                  console.log("sub-category name", e);
                  setOrderMode(e);
                }}
                value={orderMode}
              >
                <Checkbox value={"PICKUP"}>Pick-Up</Checkbox>
                <Checkbox value={"DELIVERY"}>Delivery</Checkbox>
              </Checkbox.Group>
            </p>
          </div>
          {orderMode.includes("DELIVERY") && (
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Delivery Option: </label>
                <Radio.Group
                  style={{
                    marginLeft: "2rem",
                    marginTop: "1rem",
                    width: "65%",
                  }}
                  onChange={(e) => {
                    setDeliveryOption(e.target.value);
                  }}
                  value={deliveryOption}
                >
                  <Radio value={"FOOCERY_DELIVERY"}>Foocery Delivery</Radio>
                  <Radio style={{ marginLeft: "2rem" }} value={"SELF_DELIVERY"}>
                    Self Delivery
                  </Radio>
                </Radio.Group>
              </p>
            </div>
          )}
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Supplier: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={(e) => {
                  setIsSupplier(e.target.value);
                }}
                value={isSupplier}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Visible For User: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={isVisibleForUser}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleLoyalitySubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
        <div
          className={`${
            isAddSubCategoryModalVisible ? "mymodal modal_activated" : "mymodal"
          }`}
        >
          <div
            className={`${
              !isToogleSidebar
                ? "manage_modal--container "
                : "manage_modal--container sidebarOpend_F_modal"
            }`}
          >
            {category !== null && (
              <AddSubCategory category={category} setCategory={setCategory} />
            )}
          </div>
          <div
            className={`${
              !isToogleSidebar
                ? "task--rectangle"
                : "task--rectangle withsidebarOpend"
            }`}
          >
            <div className="task--rectangle__circle">
              <div className="circle">
                {" "}
                <span
                  onClick={() => {
                    if (
                      (category !== null && category !== undefined) ||
                      category !== ""
                    ) {
                      dispatch({
                        type: "FETCH_MERCHANT_SUB_CATEGORY_REQUEST",
                        payload: {
                          categoryId: category,
                          postsPerPage: 1,
                          currentPage: 10,
                        },
                      });
                    }
                    setIsAddSubCategoryModalVisible(
                      !isAddSubCategoryModalVisible
                    );
                  }}
                >
                  <BsX />
                </span>
                <p>Close</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            isAddCategoryModalVisible ? "mymodal modal_activated" : "mymodal"
          }`}
        >
          <div
            className={`${
              !isToogleSidebar
                ? "manage_modal--container "
                : "manage_modal--container sidebarOpend_F_modal"
            }`}
          >
            {category !== null && <AddMerchantCategory />}
          </div>
          <div
            className={`${
              !isToogleSidebar
                ? "task--rectangle"
                : "task--rectangle withsidebarOpend"
            }`}
          >
            <div className="task--rectangle__circle">
              <div className="circle">
                {" "}
                <span
                  onClick={() => {
                    dispatch({
                      type: "FETCH_MERCHANT_CATEGORY_REQUEST",
                    });
                    setIsAddCategoryModalVisible(!isAddCategoryModalVisible);
                  }}
                >
                  <BsX />
                </span>
                <p>Close</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddRestaurant);
