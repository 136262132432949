import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify';
// import { LatLangGenerator } from '../Coupon/LatLangGenerator';
import { GetLocation } from './GetLocation';
import { BiTrendingUp } from 'react-icons/bi';


const mapStateToProps = store => {
    const { buttonText } = store.mainState
    return { buttonText }
}

function AddNewCustomer({ buttonText, getPhoneNo}) {
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState("")
    const [lastName,setLasName] = useState("")
    const [gender,setGender] = useState("Male")
    const [mobileNumber,setMobileNumber] = useState("")
    const [phNumber,setPhNumber] = useState("")
    const [email,setEmail] = useState("")
    const [isAgreementFlag,setIsAgreementFlag] = useState(false)

    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [location,setLocation] = useState("")
    const handleLoyalitySubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "ADD_NEW_CUSTOMER_REQUEST",
            payload: {
                "mobileNo":mobileNumber,
                "callingCode":"977",
                "deviceMac":"mac",
                "deviceUID":"uid",
                "deviceIp":"ip",
                "agreementFlag":true,
                "email":email,
                "phone":phNumber,
                "firstName":firstName,
                "lastName":lastName,
                "middleName":"",
                "gender":gender,
                "location":location,
                "longitude":longitude,
                "latitude": latitude,
                "isAllowedLocationAccess":false
            }
        })
        getPhoneNo(mobileNumber)

    }

    const handlelatLong = (myvalue) => {
        setLatitude(myvalue.lat)
        setLongitude(myvalue.lng)
    }
    const handleAddress = (address) => {
        setLocation(address)
    }

    return (
        <div>
            <ToastContainer />
            <div className="Modal--heading">
                Add New Customer
            </div>
            <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">First Name: </label>
                            <input type="text" value={firstName} placeholder='First Name' onChange={(e)=> setFirstName(e.target.value)} />
                            </p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Last Name: </label>
                            <input type="text" value={lastName} placeholder='Last Name' onChange={(e)=> setLasName(e.target.value)} />
                            </p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Gender: </label>
                            <select value={gender} onChange={(e) => setGender(e.target.value)}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Mobile Number: </label><input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Mobile Number" /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Alternative Ph. Number: </label><input type="number" value={phNumber} onChange={(e) => setPhNumber(e.target.value)} placeholder="Alternative Ph. Number" /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Email: </label><input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p style={{display: "flex",alignItems: "flex-end"}}><label htmlFor="title">Location: </label><GetLocation handlelatLong={handlelatLong} handleAddress={handleAddress} /></p>
                    </div>
                    {/* <div className="addnewCustomer--isAggrement">
                        <p ><input style={{width: "25px",height: "25px"}} type= "checkbox" value={isAgreementFlag} onChange={(e)=> setIsAgreementFlag(e.target.checked)} /> <label>Is Agreemen</label></p>
                    </div> */}

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleLoyalitySubmit} >
                            {buttonText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(AddNewCustomer)


