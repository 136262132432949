import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { connect, useDispatch } from "react-redux";
import { AiFillPhone, AiOutlineMail, AiOutlineDelete } from "react-icons/ai";

import { RiArrowLeftLine } from "react-icons/ri";
import { GoAlert } from "react-icons/go";
// for motion on hover
import { useSpring, animated, config } from "@react-spring/web";
import { useParams } from "react-router-dom";

import Footer from "../Z_Footer/Footer";
import AdjustItemsCount from "./AdjustItemsCount";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const mapStateToProps = (store) => {
  const {
    buttonText,
    searchMenuItemsList,
    cartItemArray,
    searchCustomerResult,
    addCartButton,
    myCartId,
    myMenuCategoryId,
    myMenuItemId,
    isCartItemPresent,
  } = store.mainState;
  return {
    buttonText,
    searchMenuItemsList,
    cartItemArray,
    searchCustomerResult,
    addCartButton,
    myCartId,
    myMenuCategoryId,
    myMenuItemId,
    isCartItemPresent,
  };
};

function ShowMenuItem({
  buttonText,
  searchMenuItemsList,
  cartItemArray,
  searchCustomerResult,
  addCartButton,
  myCartId,
  myMenuCategoryId,
  myMenuItemId,
  isCartItemPresent,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { restaurantId } = useParams();
  const [myCategoryName, setMyCategoryName] = useState("");
  const [myItemList, setMyItemList] = useState(null);
  const [activNode, setActiveNode] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [pushItemToArray, setPushItemToArray] = useState(false);

  const [showCartInfoModal, setShowCartInfoModal] = useState(false);

  const [myArraySum, setMyArraySum] = useState(0);
  const [restaurantDetailItem, setRestaurantDetailItem] = useState(null);

  // this ref is to alert message when user cliks outside the component to abort phone order
  const abortOrder = useRef();
  const [showAbortAlert, setShowAbourtAlert] = useState(false);
  const [closeAbortModal, setCloseAboutModal] = useState(false);

  useEffect(() => {
    if (searchCustomerResult.data === undefined) {
      history.push("/dashboard");
    } else {
      let customerId = searchCustomerResult.data[0].id;
      setCustomerId(customerId);
      dispatch({
        type: "CHECK_PREV_CART_ITEM__REQUEST",
        payload: { restaurantId, customerId },
      });
    }
  }, []);

  useEffect(() => {
    if (!closeAbortModal) {
      function handleClickOutside(event) {
        if (abortOrder.current && !abortOrder.current.contains(event.target)) {
          setShowAbourtAlert(!showAbortAlert);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showAbortAlert]);

  useEffect(() => {
    let searchKeyWord = "";
    let currentPage = 1;
    let postsPerPage = 50;
    dispatch({
      type: "FETCH_MENU_ITEMS_BY_RES_ID_REQUEST",
      payload: {
        searchKeyWord,
        currentPage,
        postsPerPage,
        restaurantId,
      },
    });
    setActiveNode(myMenuCategoryId);
  }, [restaurantId]);

  useEffect(() => {
    if (myCategoryName !== "") {
      let a = searchMenuItemsList.data?.filter(
        (item) => item.categoryName === myCategoryName
      );
      setMyItemList(a[0]);
    }
  }, [myCategoryName]);

  useEffect(() => {
    if (
      (myMenuCategoryId !== null) &
      (searchMenuItemsList.data !== undefined)
    ) {
      let a = searchMenuItemsList.data?.filter(
        (item) => item.categoryId === myMenuCategoryId
      );
      setMyItemList(a[0]);
      setPushItemToArray(true);
    }
  }, [searchMenuItemsList, myMenuCategoryId]);

  // useEffect(()=> {
  //   if(pushItemToArray) {
  //     myItemList?.itemsList.map((item)=> {
  //       if(item.id === myMenuItemId) {
  //         dispatch({
  //           type: "ADD_CART_ITEM_TO_ARRAY",
  //           payload: {
  //             "itemId":item.id,
  //             "itemName": item.itemName,
  //             "quantity":1,
  //             "price":item.price,
  //             "note":"mitho"
  //         }
  //         })
  //       }
  //     })
  //   }
  // },[pushItemToArray,myMenuItemId])

  useEffect(() => {
    if (cartItemArray !== null) {
      let sumArray = cartItemArray?.map((item) => {
        let a = item.price * item.quantity;
        return a;
      });
      let sum = 0;
      for (let i = 0; i < sumArray.length; i++) {
        sum += sumArray[i];
      }
      setMyArraySum(sum);
    }
  }, [cartItemArray]);

  const handleAddToCart = () => {
    if (isCartItemPresent) {
      setShowCartInfoModal(true);
    } else {
      dispatch({
        type: "ADD_ITEMS_TO_CART_REQUEST",
        payload: {
          userId: customerId,
          restaurantId: restaurantId,
          note: "test",
          items: cartItemArray,
        },
      });
      setPushItemToArray(false);
    }
  };

  useEffect(() => {
    if (addCartButton === "Cart Item Added") {
      let cartId = myCartId;

      setTimeout(() => {
        history.push(`/delivery-address-form/${cartId}`);
      }, 1000);
    }
  }, [addCartButton]);

  useEffect(() => {
    if (searchMenuItemsList.data !== undefined) {
      let myRestaurnatData = searchMenuItemsList?.data[0];
      setRestaurantDetailItem(myRestaurnatData);
    }
  }, [searchMenuItemsList]);

  const handleBackButton = () => {
    // setPushItemToArray(false);
    dispatch({
      type: "REMOVE_CARTITEM_FROM_ARRAY",
    });
    history.goBack();
  };

  return (
    <Layout>
      <div ref={abortOrder}>
        <div className="menuShowing__restaurantDetails">
          <p onClick={handleBackButton}>
            {" "}
            <span>
              <RiArrowLeftLine />
            </span>{" "}
            Back
          </p>
          <div className="menuShowing__restaurantDetails--container">
            {(restaurantDetailItem !== null) &
            (restaurantDetailItem !== undefined) ? (
              <>
                <p>
                  {" "}
                  <span>Restaurant Name : </span>{" "}
                  {restaurantDetailItem?.restaurantName}
                </p>
                <p>
                  {" "}
                  <span>Phone No : </span>{" "}
                  {restaurantDetailItem?.restaurantContact}
                </p>
                <p>
                  {" "}
                  <span>Address : </span>{" "}
                  {restaurantDetailItem?.restaurantAddress}
                </p>
              </>
            ) : null}
          </div>
        </div>
        <div className="menuItem--container">
          {searchMenuItemsList.data?.length > 0 ? (
            <p>Menu Category</p>
          ) : (
            <p>Menus are not available. First add menu item and come back.</p>
          )}
          <div className="menuItem--container--heading">
            {searchMenuItemsList.data?.map((item) => (
              <div
                className={`${
                  activNode === item.categoryId
                    ? "menuItem--container--heading--category active"
                    : "menuItem--container--heading--category"
                }`}
                onClick={() => {
                  setMyCategoryName(item.categoryName);
                  setActiveNode(item.categoryId);
                }}
                key={item.categoryId}
              >
                {item.categoryName}
              </div>
            ))}
          </div>
        </div>
        <div className="menuItem--items">
          <div className="menuItem--items--addToCart">
            {/* <div className="searchMenu--box">
                  <input type="text" placeholder="Search Item" value={searchKeyWord} onChange={(e)=> setSearchKeyWord(e.target.value)} />
            </div>
            <hr />
            */}
            {myItemList?.itemsList?.map((item) => (
              <div key={item.id}>
                <div className="cart--item">
                  {item.id === myMenuItemId ? (
                    <AdjustItemsCount prevCount="yes" item={item} />
                  ) : (
                    <AdjustItemsCount item={item} />
                  )}
                </div>
              </div>
            ))}
          </div>
          {cartItemArray.length > 0 && (
            <div className="menuItem--items--cartDetails">
              <div className="cartDetails--body">
                <table>
                  <thead>
                    <tr>
                      <th className="cartDetails--body--name">Item Name</th>
                      <th className="cartDetails--body--quantity">Price</th>
                      <th className="cartDetails--body--quantity">Quantity</th>
                      <th className="cartDetails--body--total">Total ($)</th>
                      <th className="cartDetails--body--delete">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItemArray !== undefined &&
                      cartItemArray.map((item) => (
                        <tr>
                          <td>{item.itemName}</td>
                          <td>{item.price}</td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {item.quantity}
                          </td>
                          <td>{item.price * item.quantity}</td>
                          <td>
                            <AiOutlineDelete
                              className="cartDelete--icon"
                              onClick={() => {
                                dispatch({
                                  type: "REMOVE_CARTITEM_FROM_CARTLIST",
                                  payload: item.itemId,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div
                  className="showcartItemTotalPrivate"
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#e8eeee",
                    padding: "5px",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ color: "green" }}>
                    Note : Your total price without tax, delivery charge,
                    service charge and wihtout applying coupon code is{" "}
                    <span style={{ fontWeight: "bold" }}>$ {myArraySum}</span>
                  </p>
                </div>
                <button
                  className="final--addToCartButton"
                  onClick={handleAddToCart}
                >
                  {addCartButton}
                </button>
              </div>
            </div>
          )}
        </div>
        {/* show pop up to show clearing cart message */}
        {showCartInfoModal && (
          <div className="operatingLocation">
            <div
              className="operatingLocation--container"
              style={{ height: "auto" }}
            >
              <div className="showAlrt--header">
                <p>
                  <GoAlert />
                </p>
                <p>Alert !!!</p>
              </div>

              <hr />
              <p style={{ backgroundColor: "#f8f9fa", padding: "10px" }}>
                Gurug-Food Informs you that your previous cart item will be
                removed
              </p>

              <div className="button_container">
                <button
                  onClick={() => {
                    setShowCartInfoModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    dispatch({
                      type: "ADD_ITEMS_TO_CART_REQUEST",
                      payload: {
                        userId: customerId,
                        restaurantId: restaurantId,
                        note: "test",
                        items: cartItemArray,
                      },
                    });
                    setPushItemToArray(false);
                  }}
                >
                  {addCartButton}
                </button>
              </div>
            </div>
          </div>
        )}
        {/* abort order pop up modal */}
        {showAbortAlert && (
          <div className="operatingLocation">
            <div
              className="operatingLocation--container"
              style={{ height: "auto" }}
            >
              <div className="showAlrt--header">
                <p>
                  <GoAlert />
                </p>
                <p>Alert !!!</p>
              </div>

              <hr />
              <p style={{ backgroundColor: "#f8f9fa", padding: "10px" }}>
                After Clicking "OK button, your current phone order will be
                aborted
              </p>

              <div className="button_container">
                <button
                  onClick={() => {
                    setShowAbourtAlert(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setCloseAboutModal(true);
                    dispatch({
                      type: "REMOVE_CARTITEM_FROM_ARRAY",
                    });
                    dispatch({
                      type: "MAKE_EMPTY_PHONESEARCH_CUSTOMER",
                    });

                    setShowAbourtAlert(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(ShowMenuItem);
