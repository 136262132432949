import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import Navbar from '../Components/A_Navbar/Navbar'
import Sidebar from '../Components/A_Sidebar/Sidebar'
// import '../css/my-app.css'
import '../sass/my-app.css'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify'

const mapStateToProps = store=> {
    const {isToogleSidebar} = store.mainState;
    return {isToogleSidebar}

}

function Layout({children,isToogleSidebar}) {
    return (
        <Fragment>
        <ToastContainer />
            <section id={`${isToogleSidebar ? "navbar_with_sidebar":"navbar"}`}><Navbar /></section>
            <section id={`${isToogleSidebar ? "my_children_sidebar--colaps": "my_children"}`}><div>{ children }</div></section>
            <section ><Sidebar /></section>
        </Fragment>
    )
}

export default connect(mapStateToProps,null) (Layout)
