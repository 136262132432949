import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsEyeFill, BsCheckCircle } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import UserDetails from "./UserDetails";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import moment from "moment";

const mapStateToProps = (store) => {
  const { isLoadingOnLogin, searchUsersList, isToogleSidebar, isEnabledUsers } =
    store.mainState;
  return { isLoadingOnLogin, searchUsersList, isToogleSidebar, isEnabledUsers };
};

function ManageUsers({
  isLoadingOnLogin,
  searchUsersList,
  isToogleSidebar,
  isEnabledUsers,
}) {
  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [reasonForEnableDisable, setReasonForEnableDisable] = useState("");

  const [myKeyword, setMyKeyword] = useState("");

  // for showing details of users
  const [openEye, setOpenEye] = useState(false);
  const [userDetailsId, setUserDetailsId] = useState(null);
  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dispatch = useDispatch();
  useEffect(() => {
    if (myKeyword !== "") {
      setCurrentPage(1);
      dispatch({
        type: "FETCH_USERS_LIST_REQUEST",
        payload: { myKeyword, currentPage, postsPerPage },
      });
    } else {
      setMyKeyword("");
      dispatch({
        type: "FETCH_USERS_LIST_REQUEST",
        payload: { myKeyword, currentPage, postsPerPage },
      });
    }
  }, [myKeyword, currentPage, postsPerPage]);

  for (let i = 1; i <= searchUsersList.totalPage; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        setShowSmModal(false);
        dispatch({
          type: "FETCH_USERS_LIST_REQUEST",
          payload: { myKeyword, currentPage, postsPerPage },
        });
        setReasonForEnableDisable("");
      }, 1000);
    }
  }, [isEnabledUsers]);

  const str = () =>
    searchUsersList.data !== undefined &&
    searchUsersList.data.map((item) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.fullName}</td>
        <td>{item.phone}</td>
        <td>{item.email}</td>
        <td>
          <span
            style={{
              backgroundColor: "green",
              borderRadius: "4px",
              color: "white",
            }}
          >
            {item.isActive ? "Active" : "Not Active"}
          </span>
        </td>
        <td>{moment.utc(item.joinedDate).format("YYYY-MM-DD")}</td>
        <td>
          <span className="advertisement--activeStatus">
            {item.registrationStatus}
          </span>
        </td>
        <td>
          <Tippy
            content={<span>{item.isActive ? "De-activate" : "Activate"}</span>}
            placement="top"
          >
            <span>
              <BsCheckCircle
                className="action--eye__admin"
                onClick={() => {
                  setShowSmModal(true);
                  setEnableDisableItem(item);
                }}
              />{" "}
            </span>
          </Tippy>
          <Tippy content="View" placement="bottom">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setUserDetailsId(item.id);
                  setOpenEye(true);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Customer</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>
            <div className="user_groups--button_n_search--search">
              <form>
                <input
                  type="text"
                  value={myKeyword}
                  placeholder="Search"
                  onChange={(e) => {
                    setMyKeyword(e.target.value);
                  }}
                />
              </form>
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="user--id">ID</th>
                  <th className="user--name">User Name</th>
                  <th className="user--mobile">Phone Number</th>
                  <th className="user--email">Email</th>
                  <th className="user--deleted">Active Status</th>
                  <th className="user--status">Registration Date</th>
                  <th className="user--status">Registration Status</th>
                  <th className="user--actions">Actions</th>
                </tr>
              </thead>
              <tbody>{str()}</tbody>
            </table>
            <div
              className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {userDetailsId !== null && <UserDetails id={userDetailsId} />}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span onClick={() => setOpenEye(!openEye)}>
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            {/* for Enable/Disable Security Question  */}
            <form>
              <div
                className={`${
                  showSmModal ? "delete--admin" : "delete--admin__hidden"
                }`}
              >
                <div className="delete--admin--container">
                  <div className="delete--admin--container__heading">
                    {enableDisableItem.isActive ? (
                      <h1>Are you sure to Disable this ??</h1>
                    ) : (
                      <h1>Are you sure to Enable this ??</h1>
                    )}
                  </div>
                  <>
                    <div className="delete--admin--container__body">
                      <div>
                        {isEnabledUsers === "a" && (
                          <div className="deleting--something">
                            {" "}
                            <p>Processing...</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "b" && (
                          <div className="deleting--something">
                            {" "}
                            <TiTick className="success--icon" />{" "}
                            <p>Successful</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "c" && (
                          <div className="deleting--something">
                            {" "}
                            <GiSkullCrossedBones
                              className="success--icon"
                              style={{ fontSize: "30px" }}
                            />{" "}
                            <p>Try Again</p>
                          </div>
                        )}
                      </div>
                      <div className="reason--enableDisable">
                        <label>Reason : </label>
                        <textarea
                          type="text"
                          required
                          placeholder="Enter Reason"
                          value={reasonForEnableDisable}
                          onChange={(e) =>
                            setReasonForEnableDisable(e.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="delete--admin--container__footer">
                      <button onClick={() => setShowSmModal(!showSmModal)}>
                        Cancel
                      </button>
                      {enableDisableItem.isActive ? (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            let enableDisableId = enableDisableItem.id;
                            dispatch({
                              type: "DISABLE_USERS_REQUEST",
                              payload: {
                                id: enableDisableId,
                                disableUserReason: reasonForEnableDisable,
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            let enableDisableId = enableDisableItem.id;
                            dispatch({
                              type: "ENABLE_USERS_REQUEST",
                              payload: {
                                id: enableDisableId,
                                enableUserReason: reasonForEnableDisable,
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </form>
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > searchUsersList.totalData
                  ? searchUsersList.totalData
                  : indexOfLastPost}{" "}
                of {searchUsersList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul
                  className="pagination"
                  style={{ maxWidth: "400px", overflowX: "auto" }}
                >
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(ManageUsers);
