import React, { useEffect, useState } from "react";
// import Layout from "../../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX, BsCheckCircle } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
// import LoadingComponent from "../../LoadingComponent";
// import Footer from "../../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
// import AddPreOTimeSlot from "./AddPreOTimeSlot";
import { useLocation, useParams } from "react-router";
import Layout from "../../Layout/Layout";
import LoadingComponent from "../LoadingComponent";
import AddMerchantPreorderTimeSlot from "./Component/AddMerchantPreorderTimeSlot";
import UpdateMerchantPreorderTimeslot from "./Component/UpdateMerchantPreorderTimeslot";
// import UpdatePreorderTimeSlot from "./UpdatePreorderTimeSlot";

function PreOrderMerchentTimeSlotList() {
  const { merchantPreOrderId } = useParams();
  const myDates = useLocation().state;
  const {
    isEnabledUsers,
    isLoadingOnLogin,
    isToogleSidebar,
    merchantPreOrderTimeSlotList,
  } = useSelector((state) => state.mainState);

  const [addPreOrderTimeSlotModal, setAddPreOrderTimeShotModal] =
    useState(false);
  const [editPreOrderTimeSlotModal, setEditPreOrderTimeShotModal] =
    useState(false);
  const [editingItem, setEditingItem] = useState({});

  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});

  //   console.log("merchant preorder dates", myDates);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);

  let pageNumbers = [];

  //   console.log("the preOrderTimeSlotList", merchantPreOrderTimeSlotList);

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // for (let i = 1; i <= userAnnouncementList.totalPage; i++) {
  //     pageNumbers.push(i)
  // }

  const [rate, setRate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_MERCHENT_PRE_ORDER_TIME_SLOT_REQUEST",
      payload: { currentPage, postsPerPage, merchantPreOrderId },
    });
  }, [currentPage, postsPerPage]);
  useEffect(() => {
    setTimeout(() => {
      if (isEnabledUsers === "b") {
        setTimeout(() => {
          setAddPreOrderTimeShotModal(false);
          setEditPreOrderTimeShotModal(false);
          dispatch({
            type: "FETCH_MERCHENT_PRE_ORDER_TIME_SLOT_REQUEST",
            payload: { currentPage, postsPerPage, merchantPreOrderId },
          });
        }, 1000);
      }
    });
  }, [isEnabledUsers]);

  return (
    <Layout>
      <div style={{ minHeight: "60vh" }}>
        <div className="group_list">
          <h4>Merchant Pre-Order Time Slot</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Time Slot</h4>
              <button onClick={() => setAddPreOrderTimeShotModal(true)}>
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
            {/* <div className="user_groups--button_n_search--search">
                            <input type="text" placeholder="Search" />
                        </div> */}
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            {merchantPreOrderTimeSlotList?.map((item, index) => (
              <div className="preO-time-slot_table" key={index}>
                <div className="preO-time-slot_table__button">
                  <p>Date: {item.slotDate}</p>
                  <p
                    onClick={() => {
                      setEditPreOrderTimeShotModal(true);
                      setEditingItem(item);
                    }}
                  >
                    Edit
                  </p>
                </div>
                <div className="preO-time-slot_table--item">
                  {item.timeResponses?.map((slot) => (
                    <div key={slot.preOrderTimeSlotId}>
                      {slot.startTime} - {slot.endTime}
                      <div className="time-slot__counts">
                        <div>
                          <p>Total Slot : {slot.slotData.totalSlot}</p>
                          <p>Occupied Slot : {slot.slotData.occupiedSlot}</p>
                          <p>Remaining Slot : {slot.slotData.remainingSlot}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {/* for active de-activation of announcement */}
            <form>
              <div
                className={`${
                  showSmModal ? "delete--admin" : "delete--admin__hidden"
                }`}
              >
                <div className="delete--admin--container">
                  <div className="delete--admin--container__heading">
                    {enableDisableItem.isActive ? (
                      <h1>Are you sure to De-Activate this ??</h1>
                    ) : (
                      <h1>Are you sure to Activate this ??</h1>
                    )}
                  </div>
                  <>
                    <div className="delete--admin--container__body">
                      <div>
                        {isEnabledUsers === "a" && (
                          <div className="deleting--something">
                            {" "}
                            <p>Processing...</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "b" && (
                          <div className="deleting--something">
                            {" "}
                            <TiTick className="success--icon" />{" "}
                            <p>Successful</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "c" && (
                          <div className="deleting--something">
                            {" "}
                            <GiSkullCrossedBones
                              className="success--icon"
                              style={{ fontSize: "30px" }}
                            />{" "}
                            <p>Try Again</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="delete--admin--container__footer">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSmModal(!showSmModal);
                        }}
                      >
                        Cancel
                      </button>
                      {enableDisableItem.isActive ? (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            let enableDisableId = enableDisableItem.id;
                            dispatch({
                              type: "DE_ACTIVATE_ANNOUNCEMENT_REQUEST",
                              payload: {
                                enableDisableId,
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            let enableDisableId = enableDisableItem.id;
                            dispatch({
                              type: "ACTIVATE_ANNOUNCEMENT_REQUEST",
                              payload: {
                                enableDisableId,
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </form>
            {/* add time slot modal*/}
            <div
              className={`${
                addPreOrderTimeSlotModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <AddMerchantPreorderTimeSlot
                  preOrderId={merchantPreOrderId}
                  myDates={myDates}
                />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setAddPreOrderTimeShotModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Add</p>
                  </div>
                </div>
              </div>
            </div>
            {/* edit time slot modal*/}
            <div
              className={`${
                editPreOrderTimeSlotModal
                  ? "mymodal modal_activated"
                  : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <UpdateMerchantPreorderTimeslot
                  editingItem={editingItem}
                  myDates={myDates}
                />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setEditPreOrderTimeShotModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PreOrderMerchentTimeSlotList;
