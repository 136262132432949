import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { connect, useDispatch } from 'react-redux'
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import dayjs from 'dayjs'

import Footer from '../Z_Footer/Footer'


const mapStateToProps = store => {
    const { buttonText } = store.mainState;
    return { buttonText }
}

function Notification({ buttonText }) {
    const [myTitle, setTitle] = useState("")
    const [myBody, setBody] = useState("")
    const [scheduledDate, setScheduledDate] = useState("")
    const [scheduledTime, setScheduledTime] = useState("00:00:00")
    const [isSchedule, setIsSchedule] = useState(false)

    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        let dateTime = [scheduledDate,scheduledTime].join(" ")
        let x =new Date(dateTime).toUTCString().split(" ")
        let finalZZ = [scheduledDate,x[4]].join(" ")
        dispatch({
            type: "ADD_MASS_NOTIFICATION_REQUEST",
            payload: {
                "title": myTitle,
                "body": myBody,
                "scheduleNotification":isSchedule,
                "scheduledDateTime" : finalZZ
            }
        })
        setTitle("")
        setBody("")
        setIsSchedule(false)
        setScheduledDate("")
        setScheduledTime("00:00:00")

    }
    return (
        <Layout >
            <div>
                <div className="group_list">
                    <h4>Add Mass Notification</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="Modal--form" style={{ marginTop: "20px",marginBottom: "60px" }}>
                            <form>

                                <div className="notification-container">
                                    <div className="notification-container--title">
                                        <p><label htmlFor="added_by">*Notification Title: </label>
                                            <textarea value={myTitle} onChange={(e) => setTitle(e.target.value)} placeholder="Title"></textarea></p>
                                    </div>

                                    <div className="notification-container--title">
                                        <p><label htmlFor="added_by">*Notification Body: </label>
                                            <textarea value={myBody} onChange={(e) => setBody(e.target.value)} placeholder="Notification Body"></textarea></p>
                                    </div>
                                    <div className="schedule_or_not">
                                        <p>Schedule Notification : <input type="checkbox" value={isSchedule} checked={isSchedule} onChange={(e) => setIsSchedule(!isSchedule)} style={{ width: "20px", height: "20px" }} /></p>
                                    </div>
                                    {isSchedule && <>
                                        <div className="addNotification--startDate">
                                            <div className="addNotification--startDate__date">
                                                <p><label htmlFor="title">*Schedule Date And Time : </label>
                                                   <input type="date" value={scheduledDate} onChange={(e)=> setScheduledDate(e.target.value)} /> <input type="time" value={scheduledTime} onChange={(e)=> setScheduledTime(e.target.value)} />
                                                </p>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                <div className="modal--container__group--buttons">
                                    <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleSubmit} >
                                        {buttonText}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>

        </Layout >
    )
}

export default connect(mapStateToProps, null)(Notification)

