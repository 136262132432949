import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

const mapStateToProps = (store) => {
  const { buttonText } = store.mainState;
  return { buttonText };
};

function AddCreditRate({ buttonText }) {
  const dispatch = useDispatch();
  const [creditCardMinimumAmount, setCreditCardMinimumAmount] = useState(null);
  const [creditCardSurcharge, setCreditCardSurcharge] = useState(null);
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "UPDATE_CREDIT_RATE_REQUEST",
      payload: {
        creditCardSurcharge: creditCardSurcharge,
        creditCardMinimumAmount: creditCardMinimumAmount,
      },
    });
  };

  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Add/Update Credit Amount</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Credit Card Surcharge (%): </label>
              <input
                type="number"
                value={creditCardSurcharge}
                onChange={(e) => setCreditCardSurcharge(e.target.value)}
                placeholder="Surcharge Rate(%)"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Credit Card Minimum Amount: </label>
              <input
                type="number"
                value={creditCardMinimumAmount}
                onChange={(e) => setCreditCardMinimumAmount(e.target.value)}
                placeholder="Minumum Amount"
              />
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleLoyalitySubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddCreditRate);
