import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import GetLocation from "./GetLocation";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import { useParams } from "react-router-dom";

function DeliveryAddDetailModal({ handleIsSheduledDateTime }) {
  const dispatch = useDispatch();
  const { cartId } = useParams();

  const [deliveryName, setDeliveryName] = useState("");
  const [deliveryContactNo, setDeliveryContactNo] = useState(null);
  const [deliveryAltContactNo, setDeliveryAltContactNo] = useState("");

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("");

  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);

  const buttonText = useSelector((state) => state.mainState.buttonText);

  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setLocation(address);
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const finalSheduledDate = [
    convert(scheduledDate),
    " ",
    scheduledTime,
    ":00",
  ].join("");
  const zz = new Date(finalSheduledDate).toUTCString().split(" ")[4];
  const finalZZ = [convert(finalSheduledDate), "T", zz].join("");

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (!deliveryName) {
      toast.error("Enter delivery name");
    } else if (deliveryName.length < 2) {
      toast.error("Enter valid Delivery Name");
    } else if (!deliveryContactNo) {
      toast.error("Enter delivery contact Number");
    } else if (deliveryContactNo.length < 9) {
      toast.error(
        "Delivery Contact Number is less than 9.Enter valid delivery contact number"
      );
    } else if (deliveryContactNo.length > 10) {
      toast.error(
        "Contact Number is more than 10. Enter valid delivery contact number"
      );
    } else if (!location) {
      toast.error("Enter delivery Location");
    } else if (isScheduled & (finalZZ === "NaN-aN-aNT")) {
      toast.error("Please enter your scheduled date and time correctly !!!");
    } else {
      dispatch({
        type: "ADD_DELIVERY_INFO_BEFOR_ADD_TO_CART_REQUEST",
        payload: {
          cartId: cartId,
          deliveryInfo: {
            deliveryAddress: location,
            deliveryLatitude: latitude,
            deliveryLongitude: longitude,
            isScheduled: isScheduled,
            scheduledTime: finalZZ !== "NaN-aN-aNT" ? finalZZ : null,
            deliveryContactNo: deliveryContactNo,
            deliveryAlternateContactNo: deliveryAltContactNo,
            deliveryFullName: deliveryName,
          },
        },
      });
    }
    handleIsSheduledDateTime({
      isScheduled: isScheduled,
      scheduledTime: finalZZ !== "NaN-aN-aNT" ? finalZZ : null,
    });
  };

  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Add Delivery Information</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Delivery Name: </label>
              <input
                type="text"
                placeholder="Delivery Full Name"
                value={deliveryName}
                onChange={(e) => setDeliveryName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Contact No: </label>
              <input
                type="number"
                value={deliveryContactNo}
                onChange={(e) => setDeliveryContactNo(e.target.value)}
                placeholder="Contact Number"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Alt. Contact No: </label>
              <input
                type="number"
                value={deliveryAltContactNo}
                onChange={(e) => setDeliveryAltContactNo(e.target.value)}
                placeholder="Alternative Contact Number"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p style={{ display: "flex", alignItems: "flex-end" }}>
              <label htmlFor="title">Location: </label>
              <GetLocation
                handlelatLong={handlelatLong}
                handleAddress={handleAddress}
              />
            </p>
          </div>
          <div className="Modal--form--checkbox">
            <p>
              <input
                type="checkbox"
                name="activStatus"
                checked={isScheduled}
                value={isScheduled}
                onChange={(e) => setIsScheduled(e.target.checked)}
              />
              <label htmlFor="added_by">Is Scheduled </label>
            </p>
          </div>
          <div className="deliveryInfo">
            {isScheduled && (
              <div className="deliveryInfo--container">
                <div className="deliveryInfo--container--startDate__date">
                  <p>
                    <label htmlFor="title">Date : </label>
                    <DatePicker
                      placeholderText="Select Date"
                      minDate={new Date()}
                      selected={scheduledDate}
                      onChange={(date) => setScheduledDate(date)}
                    />
                  </p>
                </div>
                <div className="deliveryInfo--container--startDate__time">
                  <p>
                    <label htmlFor="title" style={{ marginRight: "10px" }}>
                      Time :{" "}
                    </label>
                    <TimePicker
                      format="hh:mm a"
                      onChange={setScheduledTime}
                      placeholderText="Select Time"
                      value={scheduledTime}
                      className="myTime--picker"
                    />
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleButtonClick}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeliveryAddDetailModal;
