import React from 'react'
import { useParams } from 'react-router'
import Layout from '../../../Layout/Layout'

function CampaignOrderByIdList() {
    const {campaignId} = useParams()
    console.log("The campaign id",campaignId)
  return (
    <Layout>
        <div>CampaignOrderByIdList</div>
    </Layout>
  )
}

export default CampaignOrderByIdList