import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { toast } from "react-toastify";
// import { LatLangGenerator } from '../Coupon/LatLangGenerator';

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers, userId } = store.mainState;
  return { buttonText, isEnabledUsers, userId };
};

function AddKycInformation({
  deliveryUserId,
  buttonText,
  isEnabledUsers,
  userId,
}) {
  const [identificationDocumentFront, setidentificationDocumentFront] =
    useState(null);
  const [identificationDocumentFront1, setidentificationDocumentFront1] =
    useState(null);
  const [identificationDocumentFront2, setidentificationDocumentFront2] =
    useState(null);
  const [activeImage, setActiveImage] = useState(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [identificationDocumentBack, setidentificationDocumentBack] =
    useState(null);
  const [identificationDocumentBack1, setidentificationDocumentBack1] =
    useState(null);
  const [identificationDocumentBack2, setidentificationDocumentBack2] =
    useState(null);

  console.log("anishUser id", userId);

  const [liscenseNo, setLiscenseNo] = useState("");

  const [liscenseImage, setLiscenseImage] = useState("");
  const [liscenseImage1, setLiscenseImage1] = useState("");
  const [liscenseImage2, setLiscenseImage2] = useState("");

  const [licenseExpiryDate, setlicenseExpiryDate] = useState("");
  const [blueBookNumber, setblueBookNumber] = useState("");

  const [blueBookLastRenewImg, setblueBookLastRenewImg] = useState("");
  const [blueBookLastRenewImg1, setblueBookLastRenewImg1] = useState("");
  const [blueBookLastRenewImg2, setblueBookLastRenewImg2] = useState("");

  const [blueBookLastOwnerImg, setblueBookLastOwnerImg] = useState("");
  const [blueBookLastOwnerImg1, setblueBookLastOwnerImg1] = useState("");
  const [blueBookLastOwnerImg2, setblueBookLastOwnerImg2] = useState("");

  const [blueBookVehicleNumberImg, setblueBookVehicleNumberImg] = useState("");
  const [blueBookVehicleNumberImg1, setblueBookVehicleNumberImg1] =
    useState("");
  const [blueBookVehicleNumberImg2, setblueBookVehicleNumberImg2] =
    useState("");

  const [blueBookVehicleDetailsImg, setblueBookVehicleDetailsImg] =
    useState("");
  const [blueBookVehicleDetailsImg1, setblueBookVehicleDetailsImg1] =
    useState("");
  const [blueBookVehicleDetailsImg2, setblueBookVehicleDetailsImg2] =
    useState("");

  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const clearFileInputRef = useRef(null);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const Token = getCookie("token");
  //   let myDataform = new FormData();
  //   myDataform.append("attachment", identificationDocumentFront);
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/add-delivery-user/image`,
  //     // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
  //     data: myDataform,
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setidentificationDocumentFront1(response.data.imageUrl);
  //       setidentificationDocumentFront2(response.data.imageFullPath);
  //       setLoading(false);
  //       setIsSuccessImgUrl(!isSuccessImgUrl);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [identificationDocumentFront]);
  // useEffect(() => {
  //   const Token = getCookie("token");
  //   let myDataform = new FormData();
  //   myDataform.append("attachment", identificationDocumentBack);
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/add-delivery-user/image`,
  //     // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
  //     data: myDataform,
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setidentificationDocumentBack1(response.data.imageUrl);
  //       setidentificationDocumentBack2(response.data.imageFullPath);
  //       setLoading(false);
  //       setIsSuccessImgUrl(!isSuccessImgUrl);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [identificationDocumentBack]);
  // useEffect(() => {
  //   const Token = getCookie("token");
  //   let myDataform = new FormData();
  //   myDataform.append("attachment", liscenseImage);
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/add-delivery-user/image`,
  //     // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
  //     data: myDataform,
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setLiscenseImage1(response.data.imageUrl);
  //       setLiscenseImage2(response.data.imageFullPath);
  //       setLoading(false);
  //       setIsSuccessImgUrl(!isSuccessImgUrl);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [liscenseImage]);
  // useEffect(() => {
  //   const Token = getCookie("token");
  //   let myDataform = new FormData();
  //   myDataform.append("attachment", blueBookLastRenewImg);
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/add-delivery-user/image`,
  //     // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
  //     data: myDataform,
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setblueBookLastRenewImg1(response.data.imageUrl);
  //       setblueBookLastRenewImg2(response.data.imageFullPath);
  //       setLoading(false);
  //       setIsSuccessImgUrl(!isSuccessImgUrl);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [blueBookLastRenewImg]);
  // useEffect(() => {
  //   const Token = getCookie("token");
  //   let myDataform = new FormData();
  //   myDataform.append("attachment", blueBookLastOwnerImg);
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/add-delivery-user/image`,
  //     // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
  //     data: myDataform,
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setblueBookLastOwnerImg1(response.data.imageUrl);
  //       setblueBookLastOwnerImg2(response.data.imageFullPath);
  //       setLoading(false);
  //       setIsSuccessImgUrl(!isSuccessImgUrl);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [blueBookLastOwnerImg]);
  // useEffect(() => {
  //   const Token = getCookie("token");
  //   let myDataform = new FormData();
  //   myDataform.append("attachment", blueBookVehicleNumberImg);
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/add-delivery-user/image`,
  //     // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
  //     data: myDataform,
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setblueBookVehicleNumberImg1(response.data.imageUrl);
  //       setLoading(false);
  //       setIsSuccessImgUrl(!isSuccessImgUrl);
  //       setblueBookVehicleNumberImg2(response.data.imageFullPath);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [blueBookVehicleNumberImg]);
  // useEffect(() => {
  //   const Token = getCookie("token");
  //   let myDataform = new FormData();
  //   myDataform.append("attachment", blueBookVehicleDetailsImg);
  //   setLoading(true);
  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/add-delivery-user/image`,
  //     // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
  //     data: myDataform,
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setblueBookVehicleDetailsImg1(response.data.imageUrl);
  //       setblueBookVehicleDetailsImg2(response.data.imageFullPath);
  //       setLoading(false);
  //       setIsSuccessImgUrl(!isSuccessImgUrl);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [blueBookVehicleDetailsImg]);
  function getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  const handleDeliverySubmit = () => {
    // if (
    //   !identificationDocumentBack ||
    //   !identificationDocumentFront ||
    //   !liscenseImage ||
    //   !blueBookLastRenewImg ||
    //   !blueBookLastOwnerImg ||
    //   !blueBookVehicleNumberImg ||
    //   !blueBookVehicleDetailsImg
    // ) {
    //   return toast.error("Please upload all the images");
    // }

    const kycFormData = new FormData();
    // kycFormData.append("email", null);
    // kycFormData.append("phone", null);
    // kycFormData.append("userImage", null);
    // kycFormData.append("bikeNo", null);
    // kycFormData.append("bikeType", null);
    // kycFormData.append("identificationType", null);
    // kycFormData.append("identificationNo", null);
    // kycFormData.append("bikeModel", null);
    // kycFormData.append("fullName", null);
    // kycFormData.append("firstName", null);
    // kycFormData.append("middleName", null);
    // kycFormData.append("lastName", null);
    // kycFormData.append("gender", null);
    // kycFormData.append("dob", null);
    // kycFormData.append("country", null);
    kycFormData.append("licenseExpiryDate", licenseExpiryDate);
    kycFormData.append("licenseNumber", liscenseNo);
    kycFormData.append("blueBookNumber", blueBookNumber);
    kycFormData.append("licenseImage", liscenseImage);
    kycFormData.append(
      "identificationDocumentFront",
      identificationDocumentFront
    );
    kycFormData.append(
      "identificationDocumentBack",
      identificationDocumentBack
    );
    kycFormData.append("blueBookLastRenewImg", blueBookLastRenewImg);
    kycFormData.append("blueBookLastOwnerImg", blueBookLastOwnerImg);
    kycFormData.append("blueBookVehicleNumberImg", blueBookVehicleNumberImg);
    kycFormData.append("blueBookVehicleDetailsImg", blueBookVehicleDetailsImg);

    dispatch({
      type: "UPDATE_KYC_INFO_REQUEST",
      payload: {
        deliveryUserId: deliveryUserId,
        formData: kycFormData,
        // formData: {
        //   identificationDocumentFront:
        //     identificationDocumentFront1 === ""
        //       ? null
        //       : identificationDocumentFront1,
        //   identificationDocumentBack:
        //     identificationDocumentBack1 === ""
        //       ? null
        //       : identificationDocumentBack1,
        //   licenseNumber: liscenseNo === "" ? null : liscenseNo,
        //   licenseImage: liscenseImage1 === "" ? null : liscenseImage1,
        //   licenseExpiryDate:
        //     licenseExpiryDate === "" ? null : licenseExpiryDate,
        //   blueBookNumber: blueBookNumber === "" ? null : blueBookNumber,
        //   blueBookLastRenewImg:
        //     blueBookLastRenewImg1 === "" ? null : blueBookLastRenewImg1,
        //   blueBookLastOwnerImg:
        //     blueBookLastOwnerImg1 === "" ? null : blueBookLastOwnerImg1,
        //   blueBookVehicleNumberImg:
        //     blueBookVehicleNumberImg1 === "" ? null : blueBookVehicleNumberImg1,
        //   blueBookVehicleDetailsImg:
        //     blueBookVehicleDetailsImg1 === ""
        //       ? null
        //       : blueBookVehicleDetailsImg1,
        // },
      },
    });
  };
  useEffect(() => {
    if (isEnabledUsers === "c") {
      clearFileInputRef.current.value = "";
      setidentificationDocumentFront("");
      setidentificationDocumentBack("");
      setLiscenseNo("");
      setLiscenseImage("");
      setlicenseExpiryDate("");
      setblueBookNumber("");
      setblueBookLastRenewImg("");
      setblueBookLastOwnerImg("");
      setblueBookVehicleNumberImg("");
      setblueBookVehicleDetailsImg("");
      setidentificationDocumentBack2("");
      setidentificationDocumentFront2("");
      setLiscenseImage2("");
      setblueBookLastRenewImg2("");
      setblueBookLastOwnerImg2("");
      setblueBookVehicleNumberImg2("");
      setblueBookVehicleDetailsImg2("");
    }
  }, [isEnabledUsers]);
  return (
    <div>
      <div
        className={`${
          isImageModalVisible ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            {" "}
            <h1>Image Preview</h1>
          </div>
          <>
            <div className="delete--admin--container__body">
              <img
                src={activeImage}
                style={{ height: "400px", width: "400px" }}
              />
            </div>
            <div className="delete--admin--container__footer">
              <p
                style={{ border: "none" }}
                onClick={() => setIsImageModalVisible(!isImageModalVisible)}
              ></p>
              <button
                onClick={() => setIsImageModalVisible(!isImageModalVisible)}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      <div className="Modal--heading">Add KYC Informations</div>
      <div className="Modal--form">
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Identification Document Front : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) =>
                setidentificationDocumentFront(e.target.files[0])
              }
            />

            {identificationDocumentFront2 && (
              <img
                onClick={() => {
                  setActiveImage(identificationDocumentFront2);
                  setIsImageModalVisible(true);
                }}
                src={identificationDocumentFront2}
                alt="identificationDocumentFront"
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "auto",
                  marginLeft: "1rem",
                }}
              />
            )}
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Identification Document Back : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) => setidentificationDocumentBack(e.target.files[0])}
            />
            {identificationDocumentBack2 && (
              <img
                onClick={() => {
                  setActiveImage(identificationDocumentBack2);
                  setIsImageModalVisible(true);
                }}
                src={identificationDocumentBack2}
                alt="identificationDocumentBack"
                style={{
                  height: "100px",
                  width: "100px",
                  marginLeft: "1rem",
                }}
              />
            )}
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Liscense No : </label>
            <input
              type="number"
              value={liscenseNo}
              placeholder="Liscense Number"
              onChange={(e) => setLiscenseNo(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Liscense Image : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) => setLiscenseImage(e.target.files[0])}
            />
            {liscenseImage2 && (
              <img
                onClick={() => {
                  setActiveImage(liscenseImage2);
                  setIsImageModalVisible(true);
                }}
                src={liscenseImage2}
                alt="liscenseImage"
                style={{ height: "100px", width: "100px", marginLeft: "1rem" }}
              />
            )}
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Liscense Expiry Date : </label>
            <input
              type="date"
              value={licenseExpiryDate}
              placeholder="Liscense Expiry Date"
              onChange={(e) => setlicenseExpiryDate(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Bluebook No : </label>
            <input
              type="number"
              value={blueBookNumber}
              placeholder="Bluebook Number"
              onChange={(e) => setblueBookNumber(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Bluebook Last Renew Image : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) => setblueBookLastRenewImg(e.target.files[0])}
            />
            {blueBookLastRenewImg2 && (
              <img
                onClick={() => {
                  setActiveImage(blueBookLastRenewImg2);
                  setIsImageModalVisible(true);
                }}
                src={blueBookLastRenewImg2}
                alt="blueBookLastRenewImg"
                style={{ height: "100px", width: "100px", marginLeft: "1rem" }}
              />
            )}
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Bluebook Last Owner Image : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) => setblueBookLastOwnerImg(e.target.files[0])}
            />
            {blueBookLastOwnerImg2 && (
              <img
                onClick={() => {
                  setActiveImage(blueBookLastOwnerImg2);
                  setIsImageModalVisible(true);
                }}
                src={blueBookLastOwnerImg2}
                alt="blueBookLastOwnerImg"
                style={{ height: "100px", width: "100px", marginLeft: "1rem" }}
              />
            )}
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Bluebook Vehicle No Image : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) => setblueBookVehicleNumberImg(e.target.files[0])}
            />
            {blueBookVehicleNumberImg2 && (
              <img
                onClick={() => {
                  setActiveImage(blueBookVehicleNumberImg2);
                  setIsImageModalVisible(true);
                }}
                src={blueBookVehicleNumberImg2}
                alt="blueBookVehicleNumberImg"
                style={{ height: "100px", width: "100px", marginLeft: "1rem" }}
              />
            )}
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Bluebook Vehicle Details Image : </label>
            <input
              type="file"
              ref={clearFileInputRef}
              name="image"
              accept="image/*"
              onChange={(e) => setblueBookVehicleDetailsImg(e.target.files[0])}
            />
            {blueBookVehicleDetailsImg2 && (
              <img
                onClick={() => {
                  setActiveImage(blueBookVehicleDetailsImg2);
                  setIsImageModalVisible(true);
                }}
                src={blueBookVehicleDetailsImg2}
                alt="blueBookVehicleDetailsImg"
                style={{ height: "100px", width: "100px", marginLeft: "1rem" }}
              />
            )}
          </p>
        </div>
        <div className="modal--container__group--buttons">
          <button
            style={{ marginBottom: "50px" }}
            type="submit"
            className="btn btn-primary"
            onClick={handleDeliverySubmit}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddKycInformation);
