import dayjs from "dayjs";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import moment from "moment";
import { Button } from "antd";
import LoadingComponent from "../LoadingComponent";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const { isLoadingOnLogin, isVerifyChanged, isPreOrderSettled } =
    store.mainState;
  return { isLoadingOnLogin, isVerifyChanged, isPreOrderSettled };
};

function PreOrderDetails({
  detailShowingItem,
  isVerifyChanged,
  isLoadingOnLogin,
  isPreOrderSettled,
  setShowPreOrderDetailsModal,
}) {
  console.log("th detailShowingItem", detailShowingItem);
  const [settleModal, setSettleModal] = useState("");
  const dispatch = useDispatch();
  const [remark, setRemark] = useState("");
  const [settleAmmount, setSettleAmount] = useState("");

  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  useEffect(() => {
    if (isPreOrderSettled) {
      setRemark("");
      setSettleAmount("");
      setSettleModal(false);
      setShowPreOrderDetailsModal(false);
    }
  }, [isPreOrderSettled]);
  return (
    <div>
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Pre Order Details</span>
      </div>
      <div>
        <div
          className={`${
            settleModal ? "delete--admin" : "delete--admin__hidden"
          }`}
        >
          <div className="delete--admin--container">
            <div className="delete--admin--container__heading">
              {" "}
              <h1>Settle this Preorder</h1>
            </div>
            <>
              <div className="delete--admin--container__body">
                {isLoadingOnLogin && <LoadingComponent />}

                <form style={{ width: "70%" }}>
                  <div>
                    <p style={{ display: "flex", flexDirection: "column" }}>
                      <label htmlFor="title">Settlement Amount: </label>
                      <input
                        type="number"
                        value={settleAmmount}
                        placeholder="Settlement Amount"
                        onChange={(e) => setSettleAmount(e.target.value)}
                        style={{
                          border: "0.01px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          width: "100%",
                        }}
                      />
                    </p>
                  </div>
                  <div>
                    <p style={{ display: "flex", flexDirection: "column" }}>
                      <label htmlFor="title">Remark: </label>
                      <textarea
                        type="text"
                        value={remark}
                        placeholder="Remark"
                        onChange={(e) => setRemark(e.target.value)}
                        style={{
                          border: "0.01px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          width: "100%",
                        }}
                      />
                    </p>
                  </div>
                </form>
              </div>
              <div className="delete--admin--container__footer">
                <button onClick={() => setSettleModal(!settleModal)}>
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    if (!remark || !settleAmmount) {
                      return toast.error("Please fill all the fields !");
                    } else {
                      dispatch({
                        type: "SETTLE_PREORDER_REQUEST",
                        payload: {
                          id: detailShowingItem.planId,
                          values: {
                            settlementAmount: settleAmmount,
                            settlementRemarks: remark,
                          },
                        },
                      });
                    }
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          </div>
        </div>
        <div className="organization--details">
          <div className="organization--details__header">
            <div className="organization--details__header__body">
              <div className="planImage--container">
                <p>
                  <img
                    src={
                      detailShowingItem?.planImage &&
                      detailShowingItem.planImage
                    }
                    alt="plan Image"
                  />
                  <span>Pre Order Image</span>
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <h4>Plan Detail :-</h4>
                {!detailShowingItem?.preOrderSettlementData?.isSettled && (
                  <Button
                    style={{
                      background: "#3E455E",
                      color: "white",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setSettleModal(true);
                    }}
                  >
                    Settle preorder
                  </Button>
                )}
              </div>
              <hr />
              <div className="organization--details__header__body--para">
                <p>
                  <span>Plan Name</span> : {detailShowingItem?.planName}
                </p>
                <p>
                  <span>Plan Title</span> : {detailShowingItem?.planTitle}{" "}
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p>
                  <span>Pre Order Start Date</span> :{" "}
                  {dayjs(
                    new Date(
                      getLocalDateTime(detailShowingItem?.preOrderStartDate)
                    )
                  ).format("YYYY MMM DD h:m:a")}
                </p>
                <p>
                  <span>Pre Order End Date</span> :{" "}
                  {dayjs(
                    new Date(
                      getLocalDateTime(detailShowingItem?.preOrderEndDate)
                    )
                  ).format("YYYY MMM DD h:m:a")}{" "}
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p style={{ width: "100%" }}>
                  <span>Description</span> : {detailShowingItem?.description}
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p>
                  <span>Delivery Start Date</span> :{" "}
                  {dayjs(
                    new Date(
                      getLocalDateTime(detailShowingItem?.deliveryStartDate)
                    )
                  ).format("YYYY MMM DD h:m:a")}
                </p>
                <p>
                  <span>Delivery End Date</span> :{" "}
                  {dayjs(
                    new Date(
                      getLocalDateTime(detailShowingItem?.deliveryEndDate)
                    )
                  ).format("YYYY MMM DD h:m:a")}{" "}
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p>
                  <span>Plan Price</span> : $ {detailShowingItem?.planPrice}
                </p>
                <p>
                  <span>Active Status</span> :{" "}
                  <span
                    style={{
                      backgroundColor: "green",
                      padding: "1px 5px",
                      borderRadius: "4px",
                      color: "white",
                    }}
                  >
                    {detailShowingItem?.isActive ? "Active" : "Not Active"}
                  </span>{" "}
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p>
                  <span>Max Request Count</span> :{" "}
                  {detailShowingItem?.maxRequestCount}
                </p>
                <p>
                  <span>Min Request Count</span> :{" "}
                  {detailShowingItem?.minimumRequestCount}{" "}
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p>
                  <span>Global Delivery</span> :{" "}
                  <span
                    style={{
                      backgroundColor: "purple",
                      padding: "1px 5px",
                      borderRadius: "4px",
                      color: "white",
                    }}
                  >
                    {detailShowingItem?.isGlobalDelivery ? "Yes" : "No"}
                  </span>
                </p>
                <p>
                  <span>isAlreadyOrdered</span> :{" "}
                  <span
                    style={{
                      backgroundColor: "purple",
                      padding: "1px 5px",
                      borderRadius: "4px",
                      color: "white",
                    }}
                  >
                    {detailShowingItem?.isAlreadyOrdered ? "Yes" : "No"}{" "}
                  </span>
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p>
                  <span>Delivery Valid Location</span> :{" "}
                  {detailShowingItem?.deliveryValidLocation}
                </p>
                <p>
                  <span>Valid Radius</span> : {detailShowingItem?.validRadius}{" "}
                </p>
              </div>
              <h4>Contact Information :-</h4>
              <hr />
              <div className="organization--details__header__body--para">
                <p>
                  <span>Plan Contact Person Name</span> :{" "}
                  {detailShowingItem?.planContactPersonFullName}
                </p>
                <p>
                  <span>Plan Contact Person Email</span> :{" "}
                  {detailShowingItem?.planContactPersonEmail}{" "}
                </p>
              </div>
              <div className="organization--details__header__body--para">
                <p>
                  <span>Plan Contact Person Phone</span> :{" "}
                  {detailShowingItem?.planContactNumber}
                </p>
              </div>
              {detailShowingItem?.assignedRestaurantName !== null && (
                <>
                  <h4>Restaurant Info :-</h4>
                  <hr />
                  <div className="organization--details__header__body--para">
                    <p>
                      <span>Restaurant Name</span> :{" "}
                      {detailShowingItem?.assignedRestaurantName}
                    </p>
                    <p>
                      <span>Restaurant Location</span> :{" "}
                      {detailShowingItem?.restaurantLocation}{" "}
                    </p>
                  </div>
                </>
              )}
              {detailShowingItem?.preOrderSettlementData !== null && (
                <>
                  <h4>Pre Order Settlement Info :-</h4>
                  <hr />
                  <div className="organization--details__header__body--para">
                    <p>
                      <span>Pre Ordered Count</span> :{" "}
                      {
                        detailShowingItem?.preOrderSettlementData
                          ?.preOrderedCount
                      }
                    </p>
                    <p>
                      <span>Total Sales Amount</span> : ${" "}
                      {
                        detailShowingItem?.preOrderSettlementData
                          ?.totalSalesAmount
                      }{" "}
                    </p>
                  </div>
                  <div className="organization--details__header__body--para">
                    <p>
                      <span>Total Sales Quantity</span> :{" "}
                      {
                        detailShowingItem?.preOrderSettlementData
                          ?.totalSalesQuantity
                      }
                    </p>
                    <p>
                      <span>Settled Amount</span> : ${" "}
                      {detailShowingItem?.preOrderSettlementData?.settledAmount}{" "}
                    </p>
                  </div>
                  <div className="organization--details__header__body--para">
                    <p>
                      <span>Settled By</span> :{" "}
                      {detailShowingItem?.preOrderSettlementData?.settledBy}
                    </p>
                    <p>
                      <span>Settlement Remark</span> :{" "}
                      {
                        detailShowingItem?.preOrderSettlementData
                          ?.settlementRemark
                      }{" "}
                    </p>
                  </div>
                  <div className="organization--details__header__body--para">
                    <p>
                      <span>Settled Date Time</span> :{" "}
                      {detailShowingItem?.preOrderSettlementData
                        ?.settledDateTime &&
                        moment(
                          detailShowingItem?.preOrderSettlementData
                            ?.settledDateTime
                        ).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </>
              )}

              <h4>Pick Up Info :-</h4>
              <hr />
              {detailShowingItem?.isTakeAwayAllowed &&
              detailShowingItem?.takeAwayStallsList?.length > 0 ? (
                <div className="organization--details__header__body--para">
                  <p>
                    <span>Stall Location</span> :{" "}
                    {detailShowingItem?.takeAwayStallsList[0]?.stallLocation}{" "}
                  </p>
                  <p>
                    <span>Stall Number</span> :{" "}
                    {detailShowingItem?.takeAwayStallsList[0].stallName}
                  </p>
                </div>
              ) : (
                <div className="organization--details__header__body--para">
                  <p style={{ color: "red" }}>
                    Note*: This preOrder is not Pick up Type. It means Delivery
                    Type
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(PreOrderDetails);
