import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { BsEyeFill, BsCheckCircle } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { MdAssignmentTurnedIn, MdDelete } from "react-icons/md";
// import UserDetails from './UserDetails';
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { AiFillEdit } from "react-icons/ai";
import dayjs from "dayjs";
import { Button, Select } from "antd";
import { Tabs } from "antd";
import "./Settlement.css";
import {
  Card,
  Col,
  Row,
  Statistic,
  Input,
  DatePicker,
  Collapse,
  Popover,
} from "antd";
import moment from "moment";
import OrderSummary from "./OrderSummary";

const { TabPane } = Tabs;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    settlementData,
    isToogleSidebar,
    isEnabledUsers,
    exportButton,
    isSettlementSuccess,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    settlementData,
    isToogleSidebar,
    isEnabledUsers,
    exportButton,
    isSettlementSuccess,
  };
};

function Settlement({
  isLoadingOnLogin,
  settlementData,
  isToogleSidebar,
  isEnabledUsers,
  exportButton,
  isSettlementSuccess,
}) {
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [releaseModal, setReleaseModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [currentStatus, setCurrentStatus] = useState("PENDING");
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState("");
  const [selectedYear, setSelectedYear] = useState("2022");
  const [selectedTimePeriod, setTimePeriod] = useState("2022-08-01 2022-08-15");

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("The start ",s)
    dispatch({
      type: "SETTLEMENT_DATA_REQUEST",
      payload: {
        startDate: selectedTimePeriod.split(" ")[0] + " 00:00:00",
        endDate: selectedTimePeriod.split(" ")[1] + " 00:00:00",
        currentPage,
        postsPerPage,
        settlementState: currentStatus,
      },
    });
  }, [currentPage, postsPerPage]);

  for (let i = 1; i <= settlementData.totalPage; i++) {
    pageNumbers.push(i);
  }

  const tableBody = () =>
    settlementData.data !== undefined &&
    settlementData.data.map((item, index) => (
      <tr key={index + 1}>
        <td>{index + 1}</td>
        <td style={{ color: "blue", cursor: "pointer" }}>
          {" "}
          <Popover
            className="settlement-pop"
            placement="bottom"
            content={
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-around",
                }}
              >
                <p>
                  <span style={{ color: "#8C8C8C", fontWeight: "normal" }}>
                    Name
                  </span>
                  : {item.contactPersonName}
                </p>{" "}
                <p>
                  <span style={{ color: "#8C8C8C", fontWeight: "normal" }}>
                    Contact
                  </span>
                  : {item.contactPersonPhoneNumber}
                </p>{" "}
                <p>
                  <span style={{ color: "#8C8C8C", fontWeight: "normal" }}>
                    Commission
                  </span>
                  : $ {item.commissionAmount}
                </p>{" "}
                <p>
                  <span style={{ color: "#8C8C8C", fontWeight: "normal" }}>
                    Total Sales
                  </span>
                  : {item.totalSales}
                </p>
                <p>
                  <span style={{ color: "#8C8C8C", fontWeight: "normal" }}>
                    Net Pay
                  </span>
                  : $ {item.netPayoutAmount}
                </p>
              </div>
            }
            trigger="click"
          >
            <span>{item.restaurantName}</span>
          </Popover>{" "}
        </td>
        <td>{item.restaurantLocation}</td>
        <td
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => {
            setIsOrderModalOpen(true);
            setRestaurantDetails(item);
          }}
        >
          {item.orderDetails?.length} Orders
        </td>
        <td>$ {item.netPayoutAmount}</td>
        <td>$ {item.totalSum}</td>
        <td style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontWeight: "bold" }}>
            {item.restaurantCommission} %
          </span>
          <span>Amount: $ {item.commissionAmount}</span>
        </td>
        {currentStatus !== "RELEASED" && (
          <td>
            {currentStatus === "PENDING" && (
              <Button
                style={{
                  background: "black",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setApproveModal(true);
                  setCurrentItem(item);
                }}
              >
                Approve
              </Button>
            )}
            {currentStatus === "APPROVED" && (
              <Button
                style={{
                  background: "black",
                  color: "white",
                  fontSize: "12px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setReleaseModal(true);
                  setCurrentItem(item);
                }}
              >
                Release
              </Button>
            )}
          </td>
        )}
      </tr>
    ));
  const onChange = (key) => {
    const timePeriodArr = selectedTimePeriod.split(" ");
    const [start, end] = timePeriodArr;

    const startTime = selectedYear + start.slice(4) + " 00:00:00";
    const endTime = selectedYear + end.slice(4) + " 00:00:00";
    console.log("startDate", selectedTimePeriod);
    console.log("startDate", selectedYear);
    setCurrentStatus(key);
    dispatch({
      type: "SETTLEMENT_DATA_REQUEST",
      payload: {
        // startDate: selectedTimePeriod.split(" ")[0] + " 00:00:00",
        // endDate: selectedTimePeriod.split(" ")[1] + " 00:00:00",
        startDate: startTime,
        endDate: endTime,
        currentPage,
        postsPerPage,
        settlementState: key,
      },
    });
  };
  useEffect(() => {
    setTimeout(() => {
      if (isSettlementSuccess) {
        setReleaseModal(false);
        setApproveModal(false);
        window.location.reload();
      }
    }, [1000]);
  }, [isSettlementSuccess]);

  return (
    <Layout>
      <div
        className={`${
          isOrderModalOpen ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <OrderSummary
            restaurantDetails={restaurantDetails}
            orderState={currentStatus}
          />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span onClick={() => setIsOrderModalOpen(!isOrderModalOpen)}>
                <BsX />
              </span>
              <p>Close</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`${
            releaseModal ? "delete--admin" : "delete--admin__hidden"
          }`}
        >
          <div className="delete--admin--container">
            <div className="delete--admin--container__heading">
              <h2>Sure To Change Status To RELEASED ??</h2>{" "}
            </div>
            <>
              {isLoadingOnLogin && (
                <div style={{ margin: "auto" }}>
                  <LoadingComponent />
                  <p style={{ marginTop: "-2rem" }}>Releasing...</p>
                </div>
              )}
              {isSettlementSuccess && (
                <div className="deleting--something">
                  {" "}
                  <TiTick className="success--icon" /> <p>Successful</p>
                </div>
              )}

              {/* <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div> */}
              <div className="delete--admin--container__footer">
                <button onClick={() => setReleaseModal(!releaseModal)}>
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({
                      type: "CHANGE_SETTLEMENT_STATUS_REQUEST",
                      payload: {
                        orderIdList: currentItem.orderDetails?.map(
                          (item) => item.customerOrderId
                        ),
                        updateState: "RELEASED",
                      },
                    });
                    setTimeout(() => {
                      setReleaseModal(!releaseModal);
                    }, [500]);
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          </div>
        </div>
        <div
          className={`${
            approveModal ? "delete--admin" : "delete--admin__hidden"
          }`}
        >
          <div className="delete--admin--container">
            <div className="delete--admin--container__heading">
              <h2>Sure To Change Status To APPROVED ??</h2>{" "}
            </div>
            <>
              {isLoadingOnLogin && (
                <div style={{ margin: "auto" }}>
                  <LoadingComponent />
                  <p style={{ marginTop: "-2rem" }}>Approving...</p>
                </div>
              )}
              {isSettlementSuccess && (
                <div className="deleting--something">
                  {" "}
                  <TiTick className="success--icon" /> <p>Successful</p>
                </div>
              )}
              {/* <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div> */}
              <div className="delete--admin--container__footer">
                <button onClick={() => setApproveModal(!approveModal)}>
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({
                      type: "CHANGE_SETTLEMENT_STATUS_REQUEST",
                      payload: {
                        orderIdList: currentItem.orderDetails?.map(
                          (item) => item.customerOrderId
                        ),
                        updateState: "APPROVED",
                      },
                    });
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          </div>
        </div>
        <div className="group_list">
          <h4>Manage Settlement</h4>
        </div>
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          className="settlement-tab"
        >
          <TabPane tab="Pending" key="PENDING"></TabPane>
          <TabPane tab="Approved" key="APPROVED"></TabPane>
          <TabPane tab="Released" key="RELEASED"></TabPane>
        </Tabs>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button top-container-settlement">
              <div
                style={{ display: "flex", gap: "1.1rem" }}
                className="top-card-container"
              >
                <Card>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <span style={{ fontSize: "14px", color: "#8C8C8C" }}>
                      Time Period
                    </span>
                    <Select
                      defaultValue="2022-09-01 2022-09-15"
                      style={{ width: 120 }}
                      onChange={(e) => {
                        console.log("date of settlement", e);
                        setTimePeriod(e);
                        dispatch({
                          type: "SETTLEMENT_DATA_REQUEST",
                          payload: {
                            startDate: e.split(" ")[0] + " 00:00:00",
                            endDate: e.split(" ")[1] + " 00:00:00",
                            currentPage,
                            postsPerPage,
                            settlementState: currentStatus,
                          },
                        });
                      }}
                    >
                      <Option
                        value={
                          selectedYear +
                          "-01-01" +
                          " " +
                          selectedYear +
                          "-01-15"
                        }
                      >
                        Jan 01 - Jan 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-01-16" +
                          " " +
                          selectedYear +
                          "-01-30"
                        }
                      >
                        Jan 16 - Jan 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-02-01" +
                          " " +
                          selectedYear +
                          "-02-15"
                        }
                      >
                        FEB 01 - FEB 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-02-16" +
                          " " +
                          selectedYear +
                          "-02-30"
                        }
                      >
                        FEB 16 - FEB 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-03-01" +
                          " " +
                          selectedYear +
                          "-03-15"
                        }
                      >
                        MAR 01 - MAR 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-03-16" +
                          " " +
                          selectedYear +
                          "-03-30"
                        }
                      >
                        MAR 16 - MAR 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-04-01" +
                          " " +
                          selectedYear +
                          "-04-15"
                        }
                      >
                        APR 01 - APR 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-04-16" +
                          " " +
                          selectedYear +
                          "-04-30"
                        }
                      >
                        APR 16 - APR 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-05-01" +
                          " " +
                          selectedYear +
                          "-05-15"
                        }
                      >
                        MAY 01 - MAY 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-05-16" +
                          " " +
                          selectedYear +
                          "-05-30"
                        }
                      >
                        MAY 16 - MAY 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-06-01" +
                          " " +
                          selectedYear +
                          "-06-15"
                        }
                      >
                        JUN 01 - JUN 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-06-16" +
                          " " +
                          selectedYear +
                          "-06-30"
                        }
                      >
                        JUN 16 - JUN 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-07-01" +
                          " " +
                          selectedYear +
                          "-07-15"
                        }
                      >
                        JUL 01 - JUL 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-07-16" +
                          " " +
                          selectedYear +
                          "-07-30"
                        }
                      >
                        JUL 16 - JUL 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-08-01" +
                          " " +
                          selectedYear +
                          "-08-15"
                        }
                      >
                        AUG 01 - AUG 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-08-16" +
                          " " +
                          selectedYear +
                          "-08-30"
                        }
                      >
                        AUG 16 - AUG 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-09-01" +
                          " " +
                          selectedYear +
                          "-09-15"
                        }
                      >
                        SEP 01 - SEP 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-09-16" +
                          " " +
                          selectedYear +
                          "-09-30"
                        }
                      >
                        SEP 16 - SEP 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-10-01" +
                          " " +
                          selectedYear +
                          "-10-15"
                        }
                      >
                        OCT 01 - OCT 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-10-16" +
                          " " +
                          selectedYear +
                          "-10-30"
                        }
                      >
                        OCT 16 - OCT 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-11-01" +
                          " " +
                          selectedYear +
                          "-11-15"
                        }
                      >
                        NOV 01 - NOV 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-11-16" +
                          " " +
                          selectedYear +
                          "-11-30"
                        }
                      >
                        NOV 16 - NOV 30
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-12-01" +
                          " " +
                          selectedYear +
                          "-12-15"
                        }
                      >
                        DEC 01 - DEC 15
                      </Option>
                      <Option
                        value={
                          selectedYear +
                          "-12-16" +
                          " " +
                          selectedYear +
                          "-12-30"
                        }
                      >
                        DEC 16 - DEC 30
                      </Option>
                    </Select>
                  </div>
                </Card>
                <Card className="ant-select-date">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <span style={{ fontSize: "14px", color: "#8C8C8C" }}>
                      Year
                    </span>
                    <Select
                      defaultValue="2022"
                      style={{ width: 120 }}
                      onChange={(e) => {
                        console.log("Year of settlement", e);
                        // setTimePeriod(e);
                        const timePeriodArr = selectedTimePeriod.split(" ");
                        const [start, end] = timePeriodArr;

                        const startTime = e + start.slice(4) + " 00:00:00";
                        const endTime = e + end.slice(4) + " 00:00:00";
                        setSelectedYear(e);
                        dispatch({
                          type: "SETTLEMENT_DATA_REQUEST",
                          payload: {
                            startDate: startTime,
                            endDate: endTime,
                            currentPage,
                            postsPerPage,
                            settlementState: currentStatus,
                          },
                        });
                      }}
                    >
                      <Option value="2022">2022</Option>
                      <Option value="2023">2023</Option>
                      <Option value="2024">2024</Option>
                      <Option value="2025">2025</Option>
                      <Option value="2026">2026</Option>
                    </Select>
                  </div>
                </Card>

                <Card>
                  <Statistic
                    title="Total Restaurant Pay"
                    value={
                      "$ " +
                      Math.floor(settlementData.netPayableInDuration).toFixed(2)
                    }
                    valueStyle={{
                      color: "black",
                      fontSize: "18px",

                      fontWeight: "bold",
                    }}
                  />
                </Card>
                <Card>
                  <Statistic
                    title="Total Amount"
                    value={
                      "$ " +
                      Math.floor(settlementData.totalRevenueInDuration).toFixed(
                        2
                      )
                    }
                    valueStyle={{
                      color: "black",
                      fontSize: "18px",

                      fontWeight: "bold",
                    }}
                  />
                </Card>

                <Card>
                  <Statistic
                    title="Total Sales"
                    value={Math.ceil(settlementData.totalSalesInDuration)}
                    valueStyle={{
                      color: "black",
                      fontSize: "18px",

                      fontWeight: "bold",
                    }}
                  />
                </Card>

                <Card>
                  <Statistic
                    title="Total Commission"
                    value={
                      "$ " +
                      Math.floor(
                        settlementData.totalCommissionInDuration
                      ).toFixed(2)
                    }
                    valueStyle={{
                      color: "black",
                      fontSize: "18px",

                      fontWeight: "bold",
                    }}
                  />
                </Card>
              </div>
            </div>

            <div className="user_groups--button_n_search--search"></div>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", width: "30%", gap: "1rem" }}>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                }}
              >
                Per Page
              </label>
              <select
                className="settlement-select"
                name="pageSize"
                style={{ background: "white", color: "black", width: "70%" }}
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div> */}
          {/* <div
              className="user_groups--button_n_search--search"
              style={{
                marginTop: "17px",
                marginRight: "10px",
                position: "absolute",
                right: "40px",
              }}
            >
              <form>
                <input
                  type="text"
               
                  placeholder="Search"
                
                />
              </form>
            </div> */}
          {/* </div> */}
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="settlement--sn">S.N</th>
                  <th className="settlement--name">Restaurant Name</th>
                  <th className="settlement--name">Location</th>
                  <th className="settlement--contactName">Total Orders</th>
                  <th className="settlement--mobile">Restaurant Pay</th>
                  <th className="settlement--commission">Total Amount</th>
                  <th
                    className="settlement--commission"
                    style={{ width: "15vw" }}
                  >
                    Agreement Commision
                  </th>
                  {currentStatus != "RELEASED" && (
                    <th className="settlement--commission">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>{tableBody()}</tbody>
            </table>

            {/* <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > settlementData.totalData
                  ? settlementData.totalData
                  : indexOfLastPost}{" "}
                of {settlementData.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Settlement);
