import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'

const mapStateToProps = store => {
    const { userDetails } = store.mainState;
    return { userDetails }
}

function UserDetails({ id, userDetails }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "FETCH_USER_BY_ID_REQUEST",
            payload: id
        })
    }, [id]);
    return (
        <div>
            <div className="Modal--heading">
                <span style={{ color: "#3e445e" }}>Customer Details</span>
            </div>
            <div className="Modal--paymentContainer">
             {userDetails !== undefined &&
                    <div className="tasker--workDetails">
                        <div className="payment_Parameter" style={{ marginTop: "10px", backgroundColor: "rgba(205, 197, 198, 0.849)" }}>
                            <p><span>Full Name</span>:  {userDetails.fullName}</p>
                            <div className="payment_Parameter--para">
                                <p><span>Email</span>:  {userDetails.email} </p>
                            </div>
                            <div className="payment_Parameter--para">
                                <p><span>Phone Number</span>:   {userDetails.phone}</p>
                            </div>
                        </div>
                        <div className="payment_Parameter" style={{ marginTop: "10px", backgroundColor: "rgba(205, 197, 198, 0.849)" }}>
                            <p><span>Country</span>:  {userDetails.country} </p>
                            <div className="payment_Parameter--para">
                                <p>Gender: {userDetails.gender}</p>
                            </div>
                            <div className="payment_Parameter--para">
                                <p>Number of OTPResend: {userDetails.numberOTPResend}</p>
                            </div>
                        </div>
                        <div className="payment_Parameter" style={{ marginTop: "10px", backgroundColor: "rgba(205, 197, 198, 0.849)" }}>
                            <p>Active Status: <span style={{padding: "2px 10px", backgroundColor: "purple",borderRadius: "4px",color: "white"}}>{userDetails.isActive ? "Active": "Not Active"}</span></p>
                            <div className="payment_Parameter--para">
                                <p>Registration Status: <span style={{padding: "2px 10px", backgroundColor: "green",borderRadius: "4px",color: "white"}}>{userDetails.registrationStatus}</span></p>
                            </div>
                            <div className="payment_Parameter--para">
                                <p>Deleted Status:</p>
                            </div>
                        </div>

                    </div>
                }
               
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(UserDetails)
