import axios from "axios";
import { getCookie } from "./FrontendHelper";

// FOR ADMIN LOGIN
const Token = getCookie("token");
export const API = axios.create({
  // baseURL: "https://app.gurugfood.com:8443/restaurant/v1/api",
  // baseURL: "http://192.168.10.73:8080/foocery/v1/api",
  // baseURL: `${process.env.REACT_APP_API}/foocery/v1/api`,
  baseURL: `https://app.gurugfood.com:8444/foocery/v1/api`,
  headers: {
    Authorization: `Bearer ${Token}`,
  },
});
export const API2 = axios.create({
  // baseURL: "https://app.gurugfood.com:8443/restaurant/v1/api",
  // baseURL: "http://192.168.10.73:8080/foocery/v1/api",
  // baseURL: `${process.env.REACT_APP_API}/foocery/v2/api`,
  baseURL: `https://app.gurugfood.com:8444/foocery/v2/api`,
  headers: {
    Authorization: `Bearer ${Token}`,
  },
});
