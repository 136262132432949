import React, { useState,useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'

const mapStateToProps = store => {
    const { buttonText } = store.mainState
    return { buttonText }
}

function UpdateReferralLoyality({ buttonText,item,myValue }) {
    const dispatch = useDispatch();
    const [mySchemeName, setSchmeName] = useState("REFERED_CUSTOMER_LOYALTY")
    const [myLoyaliatyPoint, setLoyalityPoint] = useState("");
    const handleLoyalitySubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "POST_REFERAL_LOYALTY_REQUEST",
            payload: {
                "configKey": mySchemeName,
                "configValue": Number(myLoyaliatyPoint)
            }
        })

        setSchmeName("REFERED_CUSTOMER_LOYALTY");
        setLoyalityPoint("")

    }
    useEffect(()=> {
        setSchmeName(item);
        setLoyalityPoint(myValue)
    },[item])

    return (
        <div>
            <div className="Modal--heading">
                Add/Update Referral And Loyalty
            </div>
            <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Scheme Name: </label>
                            <select value={mySchemeName} onChange={(e) => setSchmeName(e.target.value)}>
                                <option value={mySchemeName}>{mySchemeName}</option>
                            </select></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Point: </label><input type="number" value={myLoyaliatyPoint} onChange={(e) => setLoyalityPoint(e.target.value)} placeholder="Enter point..." /></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleLoyalitySubmit} >
                            {buttonText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(UpdateReferralLoyality)
