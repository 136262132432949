import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsEyeFill, BsCheckCircle } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { ImAccessibility } from "react-icons/im";
import { MdAssignmentTurnedIn, MdDelete } from "react-icons/md";
// import UserDetails from './UserDetails';
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { AiFillEdit } from "react-icons/ai";
import UpdateDelivery from "./UpdateDelivery";
import { getCookie } from "../../FrontendHelper";
import { toast } from "react-toastify";
import axios from "axios";
import DeliveryDetails from "./DeliveryDetails";
import DraftDriverDetails from "./DraftDriverDetails";
import { Checkbox, Popover } from "antd";
import moment from "moment";
import AddKycInformation from "./AddKycInformation";
import { GrDocumentUpdate, GrDocumentUpload } from "react-icons/gr";
import { RiFileUploadLine } from "react-icons/ri";

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    deliveryUsersList,
    isToogleSidebar,
    isEnabledUsers,
    isVerifyChanged,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    deliveryUsersList,
    isToogleSidebar,
    isEnabledUsers,
    isVerifyChanged,
  };
};

function Delivery({
  isLoadingOnLogin,
  deliveryUsersList,
  isToogleSidebar,
  isEnabledUsers,
  isVerifyChanged,
}) {
  const [showSmModal, setShowSmModal] = useState(false);
  const [verifyDriverModal, setVerifyDriverModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [approvingItem, setApprovingItem] = useState(null);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [reasonForEnableDisable, setReasonForEnableDisable] = useState("");
  const [editingItem, setEditingItem] = useState(null);
  const [isOpenEdingItem, setIsOpenEditingItem] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [showDeleteSmModal, setShowDeleteSmModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [isLocalDelete, setIsLocaDelete] = useState("");

  const [showDraftDriverModal, setShowDraftDriverModal] = useState(false);
  const [detailShoingDraftItem, setDetailShowingDraftItem] = useState(null);
  const [verifyingDriver, setVerifyingDriver] = useState("");

  // for showing details of users
  const [openEye, setOpenEye] = useState(false);
  const [userDetailsId, setUserDetailsId] = useState(null);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentStatus, setCurrentStatus] = useState("normal");
  const [normalSearchParam, setNormalSearchParam] = useState("");
  const [draftSearchParam, setDraftSearchParam] = useState("");
  const [isSendSMSForLogin, setisSendSMSForLogin] = useState(false);
  const [updatingId, setUpdatingId] = useState(null);
  const [isUpdateKYCModal, setIsUpdateKYCModal] = useState(false);

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentStatus === "normal") {
      dispatch({
        type: "FETCH_DELIVERY_REQUEST",
        payload: {
          postsPerPage,
          currentPage,
          normalSearchParam,
        },
      });
    } else if (currentStatus === "approved") {
      alert("This functionality is under construction");
    } else {
      dispatch({
        type: "FETCH_DELIVERY_DRAFT_REQUEST",
        payload: {
          postsPerPage,
          currentPage,
          normalSearchParam,
        },
      });
    }
  }, [
    postsPerPage,
    currentPage,
    currentStatus,
    isVerifyChanged,
    normalSearchParam,
  ]);

  for (let i = 1; i <= deliveryUsersList.totalPage; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        setShowSmModal(false);
        dispatch({
          type: "FETCH_DELIVERY_REQUEST",
          payload: {
            postsPerPage,
            currentPage,
          },
        });
        setReasonForEnableDisable("");
      }, 1000);
    }
  }, [isEnabledUsers]);
  const Token = getCookie("token");

  const handleAcceptDraft = () => {
    let id = approvingItem;

    dispatch({
      type: "ACCEPT_DELIVER_DRAFT_REQUEST",
      payload: { id },
    });
    alert("let accept");
  };
  const handleVerifyDriver = () => {
    let id = verifyingDriver;

    dispatch({
      type: "VERIFY_DRIVER_REQUEST",
      payload: {
        id,
        confirmationText: confirmationText,
        isSendSMSForLogin: isSendSMSForLogin,
      },
    });
    setTimeout(() => {
      setVerifyDriverModal(false);
    }, [2000]);
  };

  const handleDeleteRestaurant = () => {
    setIsLocaDelete("a");
    let URL = "";
    if (currentStatus === "normal") {
      URL = `https://app.gurugfood.com:8444/foocery/v1/api/admin/delete-delivery-users/${deletingId}`;
    } else if (currentStatus === "approved") {
    } else {
      URL = `https://app.gurugfood.com:8444/foocery/v1/api/delivery-user/delete-driver-draft/${deletingId}`;
    }

    // let URL =
    axios
      .delete(URL, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        data: {
          confirmationText: confirmationText,
        },
      })
      .then((res) => {
        toast.success("Delivery Person Deleted Successfully !!!");
        setIsLocaDelete("b");

        setTimeout(() => {
          dispatch({
            type: "FETCH_DELIVERY_REQUEST",
            payload: {
              postsPerPage,
              currentPage,
            },
          });
          setShowDeleteSmModal(false);
          setIsLocaDelete("");
          setConfirmationText("");
        }, 1000);
      })
      .catch((error) => {
        setIsLocaDelete("");
        setConfirmationText("");
        toast.error("Couldn't delete driver . Try Again !!!");
      });
  };

  const draftTable = () =>
    deliveryUsersList.data !== undefined &&
    deliveryUsersList.data.map((item) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>
          <img className="myLargeImage" src={item.userImage} alt="image" />
        </td>
        <td>{item.fullName}</td>
        <td>{item.phoneNumber}</td>
        <td>{item.identificationNo}</td>
        <td>{item.email}</td>
        <td>{item.gender}</td>
        <td>
          {item.registrationStatus ? item.registrationStatus : "NOT REGISTERED"}
        </td>

        <td>
          <span
            style={{
              backgroundColor: "green",
              borderRadius: "4px",
              color: "white",
            }}
          >
            {item.isActive ? "Active" : "Not Active"}
          </span>
        </td>
        <td>
          <Tippy content={<span>Accept</span>}>
            <span>
              <ImAccessibility
                className="action--eye__admin"
                onClick={() => {
                  setApprovingItem(item.id);
                  setShowAcceptModal(true);
                }}
              />
            </span>
          </Tippy>

          <Tippy content="Delete" placement="top">
            <span>
              <MdDelete
                className="action--eye__admin"
                onClick={() => {
                  setDeletingId(item.id);
                  setShowDeleteSmModal(true);
                }}
              />{" "}
            </span>
          </Tippy>
          <Tippy content="View" placement="top">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setShowDraftDriverModal(true);
                  setDetailShowingDraftItem(item);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));

  const approveTable = () => <div>this is is under construction</div>;
  const str = () =>
    deliveryUsersList.data !== undefined &&
    deliveryUsersList.data.map((item) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>
          <img className="myLargeImage" src={item.userImage} alt="image" />
        </td>

        <td style={{ cursor: "pointer" }}>
          {" "}
          <Popover
            title="Wallet Data"
            content={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <span>
                  Available Balance: $ {item.walletData.availableBalance}
                </span>
                <span>Usable Balance: $ {item.walletData.usableBalance}</span>{" "}
                <span>
                  Withheld Balance: $ {item.walletData.withHeldBalance}
                </span>{" "}
                <span>
                  Last Transaction Date:
                  {item.walletData.lastTransactionDateTime
                    ? moment(item.walletData.lastTransactionDateTime).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : ""}
                </span>
              </div>
            }
          >
            {item.fullName}
          </Popover>
        </td>
        <td>{item.phone}</td>
        <td>{item.identificationNo}</td>
        <td>{item.email}</td>
        <td>{item.gender}</td>
        <td>{item.registrationStatus}</td>

        <td>
          <span
            style={{
              backgroundColor: "green",
              borderRadius: "4px",
              color: "white",
            }}
          >
            {item.isActive ? "Active" : "Not Active"}
          </span>
        </td>
        <td>
          {item.registrationStatus != "VERIFIED" && (
            <Tippy content={"Verify"}>
              <span>
                <TiTick
                  className="action--eye__admin"
                  onClick={() => {
                    setVerifyDriverModal(true);
                    setVerifyingDriver(item.id);
                  }}
                />
              </span>
            </Tippy>
          )}

          <Tippy
            content={
              <span>{`${item.isActive ? "De-activate" : "Activate"}`}</span>
            }
          >
            <span>
              <BsCheckCircle
                className="action--eye__admin"
                onClick={() => {
                  setShowSmModal(true);
                  setEnableDisableItem(item);
                }}
              />
            </span>
          </Tippy>

          <Tippy content="Edit" placement="bottom">
            <span>
              <AiFillEdit
                className="action--edit__admin"
                onClick={() => {
                  setEditingItem(item);
                  setIsOpenEditingItem(true);
                }}
              />
            </span>
          </Tippy>
          {item.kycStatus === "KYC_UNVERIFIED" && (
            <Tippy content="Update KYC" placement="top">
              <span>
                <RiFileUploadLine
                  className="action--eye__admin"
                  onClick={() => {
                    setUpdatingId(item.id);
                    setIsUpdateKYCModal(true);
                  }}
                />{" "}
              </span>
            </Tippy>
          )}
          <Tippy content="View" placement="top">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setUserDetailsId(item.id);
                  setOpenEye(true);
                }}
              />{" "}
            </span>
          </Tippy>
          <Tippy content="Delete" placement="top">
            <span>
              <MdDelete
                className="action--eye__admin"
                onClick={() => {
                  setDeletingId(item.id);
                  setShowDeleteSmModal(true);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Delivery</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Delivery</h4>
              <button onClick={() => dispatch({ type: "ADD_DELIVERY_MODAL" })}>
                Add <i className="bi bi-plus c-plus" />
              </button>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </div>
          <div className="driver--navigator">
            <div className="driver--controller">
              <p>
                <input
                  type="radio"
                  value="normal"
                  defaultChecked={true}
                  name="myStatus"
                  onChange={(e) => setCurrentStatus(e.target.value)}
                />{" "}
                <span>Normal</span>
              </p>
              {/* <p><input type="radio" value="approved" name="myStatus" onChange={(e)=> setCurrentStatus(e.target.value)} /> <span>Approved</span></p> */}
              <p>
                <input
                  type="radio"
                  value="draft"
                  name="myStatus"
                  onChange={(e) => setCurrentStatus(e.target.value)}
                />{" "}
                <span>Web Draft</span>
              </p>
            </div>
            <div className="user_groups--button_n_search--search">
              <form>
                <input
                  type="text"
                  placeholder="Search"
                  value={normalSearchParam}
                  onChange={(e) => setNormalSearchParam(e.target.value)}
                />
              </form>
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="delivery--id">ID</th>
                  <th className="delivery--image">Image</th>
                  <th className="delivery--name">Name</th>
                  <th className="delivery--mobile">Phone Number</th>
                  <th className="delivery--idNo">Identification No.</th>
                  <th className="delivery--email">Email</th>
                  <th className="delivery--gender">Gender</th>
                  <th className="delivery--status">Registeration Status</th>
                  <th className="delivery--status">Active Status</th>
                  <th className="delivery--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentStatus === "normal" && str()}
                {currentStatus === "approved" && approveTable()}
                {currentStatus === "draft" && draftTable()}
              </tbody>
            </table>

            {/* Show normal driver details */}
            <div
              className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {userDetailsId !== null && (
                  <DeliveryDetails id={userDetailsId} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span onClick={() => setOpenEye(!openEye)}>
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Show draft driver details */}
            <div
              className={`${
                showDraftDriverModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {detailShoingDraftItem !== null && (
                  <DraftDriverDetails item={detailShoingDraftItem} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        // setOpenEye(!openEye)
                        setShowDraftDriverModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Update delivery user details */}
            <div
              className={`${
                isOpenEdingItem ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {editingItem !== null && <UpdateDelivery item={editingItem} />}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setIsOpenEditingItem(!isOpenEdingItem);
                        dispatch({
                          type: "FETCH_DELIVERY_REQUEST",
                          payload: {
                            postsPerPage,
                            currentPage,
                            searchParam: "",
                          },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            {/* for Enable/Disable Security Question  */}
            <form>
              <div
                className={`${
                  showSmModal ? "delete--admin" : "delete--admin__hidden"
                }`}
              >
                <div className="delete--admin--container">
                  <div className="delete--admin--container__heading">
                    {enableDisableItem.isActive ? (
                      <h1>Are you sure to Disable this ??</h1>
                    ) : (
                      <h1>Are you sure to Enable this ??</h1>
                    )}
                  </div>
                  <>
                    <div className="delete--admin--container__body">
                      <div>
                        {isEnabledUsers === "a" && (
                          <div className="deleting--something">
                            {" "}
                            <p>Processing...</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "b" && (
                          <div className="deleting--something">
                            {" "}
                            <TiTick className="success--icon" />{" "}
                            <p>Successful</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "c" && (
                          <div className="deleting--something">
                            {" "}
                            <GiSkullCrossedBones
                              className="success--icon"
                              style={{ fontSize: "30px" }}
                            />{" "}
                            <p>Try Again</p>
                          </div>
                        )}
                      </div>
                      <div className="reason--enableDisable">
                        <label>Confirmation Text : </label>
                        <textarea
                          type="text"
                          placeholder="Confirmation Text"
                          value={reasonForEnableDisable}
                          onChange={(e) =>
                            setReasonForEnableDisable(e.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="delete--admin--container__footer">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSmModal(!showSmModal);
                        }}
                      >
                        Cancel
                      </button>
                      {enableDisableItem.isActive ? (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            let enableDisableId = enableDisableItem.id;
                            dispatch({
                              type: "DE_ACTIVATE_DELIVERY_REQUEST",
                              payload: {
                                enableDisableId,
                                reasonForEnableDisable: {
                                  confirmationText: reasonForEnableDisable,
                                },
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            let enableDisableId = enableDisableItem.id;
                            dispatch({
                              type: "ACTIVATE_DELIVERY_REQUEST",
                              payload: {
                                enableDisableId,
                                reasonForEnableDisable: {
                                  confirmationText: reasonForEnableDisable,
                                },
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </form>
            {/* Delete Delivery person */}
            <div
              className={`${
                showDeleteSmModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading">
                  Are you Sure To Delete ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div>
                      {isLocalDelete === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isLocalDelete === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isLocalDelete === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter Reason"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setShowDeleteSmModal(!showDeleteSmModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" onClick={handleDeleteRestaurant}>
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* Accept delivery */}
            <div
              className={`${
                showAcceptModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading">
                  Are you sure to accept this draft?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div>
                      {isLocalDelete === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isLocalDelete === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isLocalDelete === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter Reason"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setShowAcceptModal(!showAcceptModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" onClick={handleAcceptDraft}>
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* Verify Driver Modal */}
            <div
              className={`${
                verifyDriverModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading">
                  Are you sure to verify this driver?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div>
                      {isLoadingOnLogin && (
                        <div className="deleting--something">
                          {" "}
                          <p>Verifying...</p>
                        </div>
                      )}
                    </div>

                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        required
                        placeholder="Enter Reason"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="reason--enableDisable">
                      <label>
                        Is SMS Send ? :{" "}
                        <Checkbox
                          value={isSendSMSForLogin}
                          onChange={(e) =>
                            setisSendSMSForLogin(e.target.checked)
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setVerifyDriverModal(!verifyDriverModal)}
                    >
                      Cancel
                    </button>
                    <button type="submit" onClick={handleVerifyDriver}>
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* update KYC Modal */}
            <div
              className={`${
                isUpdateKYCModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <AddKycInformation deliveryUserId={updatingId} />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setIsUpdateKYCModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > deliveryUsersList.totalData
                  ? deliveryUsersList.totalData
                  : indexOfLastPost}{" "}
                of {deliveryUsersList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Delivery);
