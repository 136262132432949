import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify';

const mapStateToProps = store => {
    const { updateButtonText } = store.mainState
    return { updateButtonText }
}

function EditCommissionFee({ updateButtonText,myCommission }) {
    const dispatch = useDispatch();
    const [commissionFee, setCommissionFee] = useState("")


    const handleLoyalitySubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "SET_DELIVERY_COMMISSION_FEE_REQUEST",
            payload: {
                "deliveryCommission":commissionFee, //make in percent
                }
        })

    }
    useEffect(()=> {
        setCommissionFee(myCommission)
    },[myCommission])

    return (
        <div>
            <ToastContainer />
            <div className="Modal--heading">
                Edit Delivery Commission Fee
            </div>
            <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Commission Fee (%): </label><input value={commissionFee} onChange={(e) => setCommissionFee(e.target.value)} placeholder="Commission Fee" /></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleLoyalitySubmit} >
                            {updateButtonText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(EditCommissionFee)

