import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

const mapStateToProps = (store) => {
  const { buttonText } = store.mainState;
  return { buttonText };
};

function AddServiceRate({ buttonText }) {
  const dispatch = useDispatch();
  const [mySchemeName, setSchmeName] = useState("SERVICE_RATE");
  const [serviceRate, setServiceRate] = useState("");
  const [serviceRateType, setServiceRateType] = useState("FLAT");
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "POST_SERVICE_RATE_REQUEST",
      payload: {
        configKey: "SERVICE_RATE",
        serviceType: serviceRateType,
        serviceFee: serviceRate,
        doubleConfigValue: Number(serviceRate),
      },
    });
    dispatch({ type: "OPEN_SERVICE_RATE_MODAL" });
    setServiceRate("");
  };

  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Add/Update Service Rate</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Scheme Name: </label>
              <select
                value={mySchemeName}
                onChange={(e) => setSchmeName(e.target.value)}
              >
                <option value="SERVICE_RATE">SERVICE_RATE</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Type: </label>
              <select
                value={serviceRateType}
                onChange={(e) => setServiceRateType(e.target.value)}
              >
                <option value="FLAT">FLAT</option>
                <option value="PERCENTAGE">PERCENTAGE</option>
              </select>
            </p>
          </div>
          {serviceRateType === "PERCENTAGE" ? (
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Service Rate (%): </label>
                <input
                  type="number"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  placeholder="Service Rate(%)"
                />
              </p>
            </div>
          ) : (
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Service Rate(Flat): </label>
                <input
                  type="number"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  placeholder="Service Rate(FLAT)"
                />
              </p>
            </div>
          )}

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleLoyalitySubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddServiceRate);
