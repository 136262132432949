import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import moment from "moment";
import { BsEyeFill } from "react-icons/bs";

function BatchDetail({ item }) {
  console.log("The item", item);
  const dispatch = useDispatch();
  const batchPackage = useSelector((state) => state.mainState.batchPackage);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [sortingParam, setSortingParam] = useState("id");

  useEffect(() => {
    dispatch({
      type: "FETCH_BATCH_PACKAGE_REQUEST",
      payload: item?.id,
    });
  }, [item]);

  console.log("batchPackage", batchPackage);

  const campaignOrder = () =>
    batchPackage !== undefined &&
    batchPackage &&
    batchPackage.map((item) => (
      <tr key={item.batchId}>
        <td>{item.batchId}</td>
        <td>{item.batchInfo?.preOrderData?.preOrderTitle}</td>
        <td>
          {" "}
          <span>$ {item?.batchInfo?.preOrderData?.paidAmount}</span>
          <span style={{ fontWeight: "bold", display: "block" }}>
            {" "}
            ${" "}
            {item?.batchInfo?.preOrderData?.planPrice +
              "*" +
              item?.batchInfo?.preOrderData?.quantity}
          </span>
        </td>
        <td>{item.batchInfo?.preOrderData?.fullName}</td>
        <td>
          {moment.utc(item.createdOn).local().format("YYYY-MM-DD HH:mm:ss")}
        </td>
        <td>
          {moment
            .utc(item?.batchInfo?.preOrderData?.deliveryTime)
            .local()
            .format("YYYY-MM-DD HH:mm:ss") || "Not Scheduled"}
        </td>
        <td>
          {" "}
          <span>{item?.batchInfo?.preOrderData?.contactNumber}</span>
          <span style={{ fontWeight: "bold", display: "block" }}>
            {item?.batchInfo?.preOrderData?.alternativeContactNumber}
          </span>
        </td>

        <td>{item?.batchInfo?.preOrderData?.orderState}</td>

        {/* <td>
                    <Tippy content="View" placement="bottom">
                        <span>
                            <BsEyeFill className="action--edit__admin" onClick={() => {
                            setUserDetailsId(item);
                            setOpenEye(true)
                        }}  /> </span>
                    </Tippy>

                </td> */}
      </tr>
    ));
  // const preOrder = () => (
  //     campaignOrderList.data !== undefined && campaignOrderList.data.reverse().map((item) =>
  //         <tr key={item.id}>
  //             <td>{item.code}</td>
  //             <td>{item.campaignName}</td>
  //             <td>$ {item.campaignPrice}</td>
  //             <td>{item.userFullName}</td>
  //             <td>{moment.utc(item.createdOn).local().format("YYYY-MM-DD HH:mm A")}</td>
  //             <td>{moment.utc(item?.deliveryData?.scheduledTime).local().format("YYYY-MM-DD HH:mm A") || "Not Scheduled"}</td>
  //             <td>{item.deliveryContactNo} {item.deliveryAlternateContactNo}</td>
  //             <td>{item.completionStatus}</td>
  //             <td><span style={{ backgroundColor: "purple", color: "white", padding: "3px 5px", borderRadius: "4px" }}>{item.paymentMethod}</span></td>
  //             <td>
  //                 <Tippy content="View" placement="bottom">
  //                     <span>
  //                         <BsEyeFill className="action--edit__admin" onClick={() => {
  //                         setUserDetailsId(item);
  //                         setOpenEye(true)
  //                     }}  /> </span>
  //                 </Tippy>

  //             </td>
  //         </tr>
  //     )
  // )

  return (
    <div>
      <div className="Modal--heading">Batch Details</div>
      <div className="batch--details">
        <div className="batch--details_generalInfo">
          <div>
            <p>Package Name : {item?.packageName}</p>
            <div>
              <p>Package Type : {item?.packageType}</p>
            </div>
          </div>
          <div>
            <p>Pick Up Location : {item?.pickUpLocation}</p>
            <div>
              <p>Pick Up Date : {item?.pickUpDate}</p>
            </div>
          </div>
          <div>
            <p>Number of Order : {item?.numberOfOrders}</p>
            <div>
              <p>Bonous Per Order : {item?.bonusPerOrder}</p>
            </div>
          </div>
          <div>
            <p>Commission Amount : {item?.commissionAmount}</p>
            <div>
              <p>Max Drop Radius : {item?.maxDropOffRadius}</p>
            </div>
          </div>
          <div>
            <p>
              Completed :{" "}
              {!item?.isCompleted ? (
                <span className="batch-completed__status">Yes</span>
              ) : (
                <span className="batch-Notcompleted__status">Not</span>
              )}
            </p>
            <div>
              <p>Batch Note : {item?.batchNote}</p>
            </div>
          </div>
        </div>

        <div className="batch--details_orderInfo">
          <div className="user_groups">
            <hr />
            <h3>Orders</h3>
            <div
              className="user_groups--button_n_search"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <div className="user_groups--button_n_search--button">
                <label
                  htmlFor="pageSize"
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Per Page
                </label>
                <select
                  name="pageSize"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                  style={{ width: "60px" }}
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
              <div className="user_groups--button_n_search--button">
                <label
                  htmlFor="pageSize"
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Sort By
                </label>
                <select
                  name="pageSize"
                  value={sortingParam}
                  onChange={(e) => setSortingParam(e.target.value)}
                  style={{ width: "120px" }}
                >
                  <option value="descending">Descending</option>
                  <option value="ascending">Ascending</option>
                </select>
              </div>
            </div>
            {/* <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p> */}
            <div className="table-container">
              {/* <hr />
                        <div className='filter-by__date'>
                            <p><label htmlFor="">Filter By Date :  <DatePicker placeholderText='Enter your date' className='filter-by__Date--input' dateFormat="yyyy-MM-dd" selected={filterPreorderDate}
                                onChange={(date) => setFilterPreorderDate(date)} /></label> <input type="radio" name='order-date' style={{marginLeft: "20px"}} onChange={()=> {
                                    let a = new Date()
                                    setFilterPreorderDate(a)
                                } } /> <label htmlFor="order-date">Today</label> <input style={{marginLeft: "20px"}} name='order-date' type="radio" onChange={()=> {
                                    const today = new Date()
                                    const yesterday = new Date(today)

                                    yesterday.setDate(yesterday.getDate() - 1)

                                    setFilterPreorderDate(yesterday)
                                } } /> <label htmlFor="order-date">Yesterday</label></p>
                        </div> */}
              {item?.packageType === "CAMPAIGN_ORDER" ? (
                <table className="table_x">
                  <thead>
                    <tr>
                      <th className="camporder--id">Order ID</th>
                      <th className="camporder--campaignName">Campaign Name</th>
                      <th className="camporder--price">Campaign Price</th>
                      <th className="camporder--name">Customer Name</th>
                      <th className="camporder--status">Order Date</th>
                      <th className="camporder--status">Delivery Date</th>
                      <th className="camporder--status">Contact Number</th>
                      <th className="camporder--status">Status</th>
                    </tr>
                  </thead>
                  <tbody>{campaignOrder()}</tbody>
                </table>
              ) : (
                <table className="table_x">
                  <thead>
                    <tr>
                      <th className="camporder--id">Order ID</th>
                      <th className="camporder--campaignName">PreOrder Name</th>
                      <th className="camporder--price">PreOrder Price</th>
                      <th className="camporder--name">Customer Name</th>
                      <th className="camporder--status">Order Date</th>
                      <th className="camporder--status">Delivery Date</th>
                      <th className="camporder--status">Contact Number</th>
                      <th className="camporder--status">Status</th>
                    </tr>
                  </thead>
                  <tbody>{campaignOrder()}</tbody>
                </table>
              )}
              {/* <div className="pagination">
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > campaignOrderList.totalData ? campaignOrderList.totalData : indexOfLastPost} of {campaignOrderList.totalData} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination" style={{ maxWidth: "400px", overflowX: "auto" }}>
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BatchDetail;
