import React from 'react';
import ReactDOM from 'react-dom';
import MainRoutes from './Routes/MainRoutes'
import reportWebVitals from './reportWebVitals';
import store from './Redux/configureStore';
import {Provider} from 'react-redux'

ReactDOM.render(
  <React.StrictMode>
    <Provider store= {store} >
      <MainRoutes />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
