import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { Radio, Checkbox, Col, Row } from "antd";

const mapStateToProps = (store) => {
  const { updateButtonText } = store.mainState;
  return { updateButtonText };
};

function UpdateDelivery({ updateButtonText, item }) {
  console.log("the editing item int eh update compoennt ", item);
  const [fullName, setFullName] = useState("");
  const [location, setLocation] = useState("");
  const [bikeType, setBikeType] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const clearFileInputRef = useRef(null);
  const [bikeNumber, setBikeNumber] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [identificationNo, setIdentificationNo] = useState("");
  const [bypassDriver, setBypassDriver] = useState(false);
  const [workShift, setWorkShift] = useState([]);
  const [isNightShiftAvailable, setIsNightShiftAvailable] = useState(false);
  const [dayTimeAvailability, setDayTimeAvailability] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      url: `https://app.gurugfood.com:8444/foocery/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
        setIsSuccessImgUrl(!isSuccessImgUrl);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);

  const handleDeliverySubmit = (e) => {
    e.preventDefault();
    if (imageUrl !== "") {
      dispatch({
        type: "UPDATE_DELIVERY_REQUEST",
        payload: {
          id: item.id,
          imageUrl: imageUrl,
          fullName: fullName,
          location: location,
          bikeNo: bikeNumber,
          bikeType: bikeType,
          bikeModel: bikeModel,
          identificationType: identificationType,
          identificationNo: identificationNo,
          deliveryRadius: 10,
          country: "Nepal",
          workShift: workShift,
          nightTimeAvailability: isNightShiftAvailable,
          dayTimeAvailability: dayTimeAvailability,
        },
      });
    } else {
      dispatch({
        type: "UPDATE_DELIVERY_REQUEST",
        payload: {
          id: item.id,
          fullName: fullName,
          location: location,
          bikeNo: bikeNumber,
          bikeType: bikeType,
          bikeModel: bikeModel,
          identificationType: identificationType,
          identificationNo: identificationNo,

          deliveryRadius: 10,
          country: "Nepal",
          workShift: workShift,
          nightTimeAvailability: isNightShiftAvailable,
          dayTimeAvailability: dayTimeAvailability,
        },
      });
    }

    clearFileInputRef.current.value = "";
  };
  useEffect(() => {
    setFullName(item.fullName);
    setLocation(item.location);
    setBikeModel(item.bikeModel);
    setBikeType(item.bikeType);
    setBikeNumber(item.bikeNo);
    setIdentificationNo(item.identificationNo);
    setIdentificationType(item.identificationType);
    setBypassDriver(item.bypassDriver);
    setIsNightShiftAvailable(item.nightTimeAvailability);
    setWorkShift(item.workShift);
    setDayTimeAvailability(item.dayTimeAvailability);
  }, [item]);

  const onChange1 = (e) => {
    setBypassDriver(e.target.value);
  };
  const onNightShiftChange = (e) => {
    setIsNightShiftAvailable(e.target.value);
  };
  const onChange = (checkedValues) => {
    setWorkShift(checkedValues);
  };
  const onDayShiftChange = (e) => {
    setDayTimeAvailability(e.target.value);
  };

  return (
    <div>
      <div className="Modal--heading">Update Delivery</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Image : </label>
              <input
                type="file"
                placeholder="Email"
                ref={clearFileInputRef}
                name="image"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Full Name: </label>
              <input
                type="text"
                value={fullName}
                placeholder="Full Name"
                onChange={(e) => setFullName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Identification Type: </label>
              <select
                value={identificationType}
                onChange={(e) => setIdentificationType(e.target.value)}
              >
                <option>Select</option>
                <option value="CITIZENSHIP">Citizenship</option>
                <option value="LICENCE">Licence</option>
                <option value="OTHER">Other</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Identification No. : </label>
              <input
                type="text"
                placeholder="Identification Number"
                value={identificationNo}
                onChange={(e) => setIdentificationNo(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bike Type: </label>
              <select
                value={bikeType}
                onChange={(e) => setBikeType(e.target.value)}
              >
                <option>Select</option>
                <option value="CLASSIC">Classic</option>
                <option value="ROYAL">ROYAL</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bike Model : </label>
              <input
                type="text"
                value={bikeModel}
                placeholder="Bike Model"
                onChange={(e) => setBikeModel(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bike Number : </label>
              <input
                type="text"
                value={bikeNumber}
                placeholder="Bike Number"
                onChange={(e) => setBikeNumber(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Work Shift : </label>
              <Checkbox.Group
                value={workShift}
                onChange={onChange}
                style={{ marginLeft: "2rem", width: "25%", marginTop: "1rem" }}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox style={{ width: "200px" }} value="PART_TIME">
                      Part Time
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox style={{ width: "200px" }} value="FULL_TIME">
                      Full Time
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="CASUAL" style={{ width: "200px" }}>
                      Casual
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Night Shift Available : </label>
              <Radio.Group
                defaultValue={false}
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onNightShiftChange}
                value={isNightShiftAvailable}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Day Shift Available : </label>
              <Radio.Group
                defaultValue={false}
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onDayShiftChange}
                value={dayTimeAvailability}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bypassed Driver Except Flow: </label>
              <Radio.Group
                defaultValue={false}
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={bypassDriver}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleDeliverySubmit}
            >
              {updateButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default connect(mapStateToProps, null)(UpdateDelivery);
