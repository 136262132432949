import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { MdAssignmentInd } from "react-icons/md";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import LoadingComponent from "../LoadingComponent";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Alert, Button, Checkbox, Tooltip } from "antd";

const mapStateToProps = (store) => {
  const { activeDeliveryList, isEnabledUsers, isLoadingOnLogin } =
    store.mainState;
  return { activeDeliveryList, isEnabledUsers, isLoadingOnLogin };
};

function AssignOrderToD({
  activeDeliveryList,
  isEnabledUsers,
  id,
  isLoadingOnLogin,
}) {
  const [showSmModal, setShowSmModal] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [deliveryId, setDeliveryId] = useState(null);

  //multiselect

  const [multipleSelectFlag, setMultipleSelectFlag] = useState(false);
  const [checkall, setCheckAll] = useState();
  const [checkall2, setCheckAll2] = useState([]);
  const [sendsmsFlag, setSendsmsFlag] = useState(false);

  let pageNumbers = [];

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (let i = 1; i <= activeDeliveryList.totalPage; i++) {
    pageNumbers.push(i);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_ACTIVE_DELIVERY_REQUEST",
      payload: {
        currentPage,
        postsPerPage,
      },
    });
  }, [id, currentPage]);

  const str = () =>
    activeDeliveryList.data !== undefined &&
    activeDeliveryList.data.map((item, index) => (
      <tr key={item.id}>
        <td>
          <input
            type="checkbox"
            checked={checkall2.includes(item.id)}
            key={item.id}
            value={checkall}
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                setCheckAll2((pre) => [...pre, item.id]);
              } else {
                const test = checkall2.filter((value) => value !== item.id);
                setCheckAll2(test);
              }
            }}
          />
        </td>
        <td>{item.id}</td>
        <td>
          <img className="myLargeImage" src={item.userImage} alt="image" />
        </td>
        <td>{item.fullName}</td>
        <td>{item.phone}</td>
        <td>{item.identificationNo}</td>
        <td>{item.email}</td>
        <td>{item.gender}</td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              color: "white",
              borderRadius: "4px",
            }}
          >
            {item.onlineFlag ? "Online" : "Not Online"}
          </span>
        </td>
        <td>
          <Tippy content="Assign Order" placement="bottom">
            <span>
              <MdAssignmentInd
                style={{ color: "purple" }}
                className="action--edit__admin"
                onClick={() => {
                  setDeliveryId(item.id);
                  setShowSmModal(true);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        setShowSmModal(false);
        setConfirmationText("");
      }, 1000);
    }
  }, [isEnabledUsers]);

  return (
    <div>
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Assign Order To Delivery Boys</span>
      </div>
      <p className="privacy-loading--onFetching">
        {isLoadingOnLogin && <LoadingComponent />}
      </p>

      <div className="table-container" style={{ margin: "30px" }}>
        <h1 style={{ color: "purple" }}>Active Delivery Boys List :</h1>
        {checkall2.length > 0 && (
          <Alert
            message={`Selected ${checkall2.length} items.`}
            type="success"
            closable
            // afterClose={() => {
            //   setSelectedVisible(false);
            // }}
            action={
              <Tooltip title={"Assign Order"}>
                <Button
                  size="small"
                  type="primary"
                  onClick={(e) => {
                    setShowSmModal(true);
                    setMultipleSelectFlag(true);
                  }}
                >
                  Assign Order
                </Button>
              </Tooltip>
            }
            style={{ marginBottom: "1rem" }}
          />
        )}
        <table className="table_x">
          <thead>
            <tr>
              <th className="delivery--gender">
                <Checkbox
                  checked={checkall2.length > 0}
                  onChange={(e) => {
                    const { checked } = e.target;

                    if (checked) {
                      activeDeliveryList.data.map((item, index) =>
                        setCheckAll2((prev) => [...prev, item?.id])
                      );
                    } else {
                      setCheckAll2([]);
                    }
                    // setCheckAll(!checkall);

                    // checkDeliveryRef.current.checked = true;
                    // checkDeliveryRef?.current?.state?.checked = true;
                    // checkDeliveryRef.current?.link?.click();
                    console.log("checked on delivary boys", e);
                  }}
                />
              </th>
              <th className="user--id">ID</th>
              <th className="delivery--image">Image</th>
              <th className="delivery--name">Name</th>
              <th className="delivery--mobile">Phone Number</th>
              <th className="delivery--idNo">Identification No.</th>
              <th className="delivery--email">Email</th>
              <th className="delivery--gender">Gender</th>
              <th className="delivery--gender">IsOnline</th>
              <th className="delivery--actions">Actions</th>
            </tr>
          </thead>
          <tbody>{str()}</tbody>
        </table>

        <div className="pagination">
          <p>
            Showing {indexOfFirstPost} to{" "}
            {indexOfLastPost > activeDeliveryList.totalData
              ? activeDeliveryList.totalData
              : indexOfLastPost}{" "}
            of {activeDeliveryList.totalData} entries
          </p>
          <nav aria-label="...">
            <ul className="pagination">
              {currentPage === 1 ? (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronLeft />
                  </a>
                </li>
              ) : (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronLeft
                      onClick={() => setCurrentPage(currentPage - 1)}
                    />
                  </a>
                </li>
              )}
              {pageNumbers.map((x) => (
                <li key={x} className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => setCurrentPage(x)}
                  >
                    {x}
                  </a>
                </li>
              ))}
              {currentPage == pageNumbers.length ? (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronRight />
                  </a>
                </li>
              ) : (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronRight
                      onClick={() => setCurrentPage(currentPage + 1)}
                    />
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
        {/* Assign order small modal */}
        <div
          className={`${
            showSmModal ? "delete--admin" : "delete--admin__hidden"
          }`}
        >
          <div className="delete--admin--container">
            <div className="delete--admin--container__heading">
              Assign Order
            </div>
            <>
              <div className="delete--admin--container__body">
                <div>
                  {isEnabledUsers === "a" && (
                    <div className="deleting--something">
                      {" "}
                      <p>Processing...</p>
                    </div>
                  )}
                </div>
                <div>
                  {" "}
                  {isEnabledUsers === "b" && (
                    <div className="deleting--something">
                      {" "}
                      <TiTick className="success--icon" /> <p>Successful</p>
                    </div>
                  )}
                </div>
                <div>
                  {" "}
                  {isEnabledUsers === "c" && (
                    <div className="deleting--something">
                      {" "}
                      <GiSkullCrossedBones
                        className="success--icon"
                        style={{ fontSize: "30px" }}
                      />{" "}
                      <p>Try Again</p>
                    </div>
                  )}
                </div>
                <div className="reason--enableDisable">
                  <label>Driver Note : </label>
                  <textarea
                    style={{ padding: "10px" }}
                    type="text"
                    required
                    placeholder="Driver Note"
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                  ></textarea>
                </div>
                {multipleSelectFlag && (
                  <p>
                    <input
                      type="checkbox"
                      value={sendsmsFlag}
                      onChange={(e) => setSendsmsFlag(e.target.checked)}
                    />{" "}
                    Send SMS
                  </p>
                )}
              </div>
              <div className="delete--admin--container__footer">
                <button onClick={() => setShowSmModal(!showSmModal)}>
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    if (multipleSelectFlag === false) {
                      dispatch({
                        type: "ASSIGN_ORDER_T_DELIVERY_REQUEST",
                        payload: {
                          id: deliveryId,
                          values: {
                            orderId: id,
                            driverNote: confirmationText,
                          },
                        },
                      });
                    } else {
                      dispatch({
                        type: "ASSIGN_ORDER_T_MULTIPLE_DELIVERY_REQUEST",
                        payload: {
                          values: {
                            deliveryUserId: [...checkall2],
                            orderId: id,
                            driverNote: confirmationText,
                            confirmationText: "confirm",
                            orderDecision: "order",
                            sendSms: sendsmsFlag,
                          },
                        },
                      });
                    }
                  }}
                >
                  Ok
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AssignOrderToD);
