import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import Footer from "../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import moment from "moment";
// import RequestedPreOrderDetail from "../RequestedPreOrderDetail";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import { PdfDocument } from "./PdfDoc";
import LoadingComponent from "../LoadingComponent";
import { DatePicker, Input, Select } from "antd";
import AssignOrderPreOrderMerchant from "./AssignOrderPreOrderMerchant";
import { Button } from "antd";

const { Option } = Select;

const mapStateToProps = (store) => {
  const {
    buttonText,
    isLoadingOnLogin,
    allPreOrderRequestedList,
    isToogleSidebar,
    isEnabledUsers,
    preOrderTimeSlotList,
    allMerchantPreOrderRequestedList,
    isOperationSuccessful,
  } = store.mainState;
  return {
    buttonText,
    isLoadingOnLogin,
    allPreOrderRequestedList,
    isToogleSidebar,
    isEnabledUsers,
    preOrderTimeSlotList,
    allMerchantPreOrderRequestedList,
    isOperationSuccessful,
  };
};

function RequestMerchantPreOrder({
  buttonText,
  isLoadingOnLogin,
  allPreOrderRequestedList,
  isToogleSidebar,
  isEnabledUsers,
  preOrderTimeSlotList,
  allMerchantPreOrderRequestedList,
  isOperationSuccessful,
}) {
  const { preOrderId } = useParams();

  const [showEditPreOrderModal, setShowEditPreOrderModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const [sortingParam, setSortingParams] = useState("descending");

  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showDeclineModal, setShowDiclineModal] = useState(false);
  const [statusChangingItem, setStatusChanginItem] = useState(null);

  const [preOrderType, setPreOrderType] = useState("All");
  const dispatch = useDispatch();
  const [filterPreorderDate, setFilterPreorderDate] = useState("");
  const [finalPreorderDate, setFinalPreorderDate] = useState("");
  const [orderState, setOrderState] = useState("All");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [timeSlot, setTimeSlot] = useState(
    preOrderTimeSlotList?.filter(
      (timeSlot) =>
        timeSlot.slotDate == dayjs(filterPreorderDate).format("YYYY-MM-DD")
    )
  );
  const [selectedPreOrderTimeSlot, setSelectedPreOrderTimeSlot] = useState("");
  const [activeTimeSlot, setActiveTimeSlot] = useState("");
  const [orderId, setMyOrderId] = useState(null);
  const [showAssignOrderModal, setShowAssignOrderModal] = useState(false);
  useEffect(() => {
    let a = dayjs(filterPreorderDate).format("YYYY-MM-DD 00:00:00");
    if (filterPreorderDate) {
      setFinalPreorderDate(a);
    } else {
      setFinalPreorderDate("");
    }
    setTimeSlot(
      preOrderTimeSlotList?.filter(
        (timeSlot) =>
          timeSlot.slotDate == dayjs(filterPreorderDate).format("YYYY-MM-DD")
      )
    );
  }, [filterPreorderDate, preOrderTimeSlotList]);

  // Search pre order request
  const [myKeyword, setMyKeyword] = useState("");

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  let pageNumbers = [];

  for (let i = 1; i <= allMerchantPreOrderRequestedList.totalPage; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    dispatch({
      type: "FETCH_PREORDER_MERCHANT_LIST_REQUEST",
      payload: {
        postsPerPage,
        currentPage,
        searchParam: myKeyword,
        preOrderId,
      },
    });
  }, [postsPerPage, currentPage, myKeyword, isOperationSuccessful]);

  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  useEffect(() => {
    if (isOperationSuccessful) {
      setTimeout(() => {
        setShowApproveModal(false);
        setShowDispatchModal(false);
        setShowAssignOrderModal(false);
      }, 100);
    }
  }, [isOperationSuccessful]);
  useEffect(() => {
    dispatch({
      type: "FETCH_PRE_ORDER_TIME_SLOT_REQUEST",
      payload: { currentPage, postsPerPage, preOrderId },
    });
  }, [currentPage, postsPerPage]);
  const onDateChange = (value, dateString) => {
    setSelectedDate(moment(value).format("YYYY-MM-DD"));
  };
  const myData = {};

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Requested Merchant Preorders</h4>
        </div>
        <div className="user_groups">
          <div class="count-down-container" style={{ marginBottom: "1rem" }}>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="days">
                  {myData?.data?.todayData.todayDeliveryOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Today Delivery Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="hours">{myData?.data?.todayData.todayOrderCount}</h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Today Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="minutes">
                  {myData?.data?.todayData.todayOrderQuantity
                    ? myData?.data?.todayData.todayOrderQuantity
                    : 0}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Today Order Quantity{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.todayData.totalApprovedOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Approved Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.todayData.totalDispatchedOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Dispatched Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.todayData.totalRequestedOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Requested Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.totalSalesAmount
                    ? myData?.data?.totalSalesAmount
                    : 0}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Sales Amount{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.totalSalesQuantity
                    ? myData?.data?.totalSalesQuantity
                    : 0}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Sales Quantity{" "}
                </p>
              </div>
            </div>
          </div>
          <div
            className="user_groups--button_n_search"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <div className="user_groups--button_n_search--button">
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
                style={{ width: "60px" }}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>

            <div className="user_groups--button_n_search--button">
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Order State
              </label>
              <select
                name="pageSize"
                onChange={(e) => setOrderState(e.target.value)}
                style={{ width: "120px" }}
              >
                <option value={"All"}>All</option>
                <option value="REQUESTED">Requested</option>
                <option value="APPROVED">Approved</option>
                <option value="DISPATCH">Dispatched</option>
                <option value="DECLINED">Declined</option>
              </select>
            </div>
            <div
              className="pdf-download--btn"
              style={{ marginTop: "2rem", marginLeft: "2rem" }}
            >
              {/* <PDFDownloadLink
                document={
                  <PdfDocument
                    finalPreorderDate={finalPreorderDate}
                    allPreOrderRequestedList={allPreOrderRequestedList}
                  />
                }
                fileName="Template.pdf"
                style={{ marginTop: "1rem" }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download Pdf"
                }
              </PDFDownloadLink> */}
            </div>
            <div
              className="user_groups--button_n_search--search"
              style={{
                marginTop: "17px",
                marginRight: "10px",
                position: "absolute",
                right: "40px",
              }}
            >
              <input
                type="text"
                value={myKeyword}
                placeholder="Search"
                onChange={(e) => {
                  setMyKeyword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <p className="privacy-loading--onFetching">
          {isLoadingOnLogin && <LoadingComponent />}
        </p>
        <div className="table-container">
          <div className="filter-by__date">
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <label htmlFor="">
                  Filter By Date :{" "}
                  <DatePicker
                    defaultValue={moment(
                      moment().format("YYYY-MM-DD"),
                      "YYYY-MM-DD"
                    )}
                    format={"YYYY-MM-DD"}
                    style={{
                      marginLeft: "2rem",
                      marginTop: "1rem",
                      borderRadius: "5px",
                      width: "53.5%",
                    }}
                    // showTime
                    onChange={onDateChange}
                  />
                </label>{" "}
                <label htmlFor="">
                  Time Slot :
                  <Select
                    placeholder="Select Time Slot"
                    style={{ width: 200, marginLeft: "2rem" }}
                    onChange={(e) => {
                      dispatch({
                        type: "FETCH_PREORDER_MERCHANT_LIST_REQUEST",
                        payload: {
                          postsPerPage,
                          currentPage,
                          preOrderId,
                          deliveryStartTime:
                            e.split(" ")[0] + " " + e.split(" ")[1],
                          deliveryEndTime:
                            e.split(" ")[2] + " " + e.split(" ")[3],
                        },
                      });
                    }}
                  >
                    <Option
                      value={`${selectedDate} 01:00:00 ${selectedDate} 02:00:00`}
                    >
                      1-2
                    </Option>
                    <Option
                      value={`${selectedDate} 02:00:00 ${selectedDate} 03:00:00`}
                    >
                      2-3
                    </Option>
                    <Option
                      value={`${selectedDate} 03:00:00 ${selectedDate} 04:00:00`}
                    >
                      3-4
                    </Option>
                    <Option
                      value={`${selectedDate} 04:00:00 ${selectedDate} 05:00:00`}
                    >
                      4-5
                    </Option>
                    <Option
                      value={`${selectedDate} 05:00:00 ${selectedDate} 06:00:00`}
                    >
                      5-6
                    </Option>
                    <Option
                      value={`${selectedDate} 06:00:00 ${selectedDate} 07:00:00`}
                    >
                      6-7
                    </Option>
                    <Option
                      value={`${selectedDate} 07:00:00 ${selectedDate} 08:00:00`}
                    >
                      7-8
                    </Option>
                    <Option
                      value={`${selectedDate} 08:00:00 ${selectedDate} 09:00:00`}
                    >
                      8-9
                    </Option>
                    <Option
                      value={`${selectedDate} 09:00:00 ${selectedDate} 10:00:00`}
                    >
                      9-10
                    </Option>
                    <Option
                      value={`${selectedDate} 10:00:00 ${selectedDate} 11:00:00`}
                    >
                      10-11
                    </Option>
                    <Option
                      value={`${selectedDate} 11:00:00 ${selectedDate} 12:00:00`}
                    >
                      11-12
                    </Option>
                    <Option
                      value={`${selectedDate} 12:00:00 ${selectedDate} 13:00:00`}
                    >
                      12-13
                    </Option>
                    <Option
                      value={`${selectedDate} 13:00:00 ${selectedDate} 14:00:00`}
                    >
                      13-14
                    </Option>
                    <Option
                      value={`${selectedDate} 14:00:00 ${selectedDate} 15:00:00`}
                    >
                      14-15
                    </Option>
                    <Option
                      value={`${selectedDate} 15:00:00 ${selectedDate} 16:00:00`}
                    >
                      15-16
                    </Option>
                    <Option
                      value={`${selectedDate} 16:00:00 ${selectedDate} 17:00:00`}
                    >
                      16-17
                    </Option>
                    <Option
                      value={`${selectedDate} 17:00:00 ${selectedDate} 18:00:00`}
                    >
                      17-18
                    </Option>
                    <Option
                      value={`${selectedDate} 18:00:00 ${selectedDate} 19:00:00`}
                    >
                      18-19
                    </Option>
                    <Option
                      value={`${selectedDate} 19:00:00 ${selectedDate} 20:00:00`}
                    >
                      19-20
                    </Option>
                    <Option
                      value={`${selectedDate} 20:00:00 ${selectedDate} 21:00:00`}
                    >
                      20-21
                    </Option>
                    <Option
                      value={`${selectedDate} 21:00:00 ${selectedDate} 22:00:00`}
                    >
                      21-22
                    </Option>
                    <Option
                      value={`${selectedDate} 22:00:00 ${selectedDate} 23:00:00`}
                    >
                      22-23
                    </Option>
                    <Option
                      value={`${selectedDate} 23:00:00 ${selectedDate} 24:00:00`}
                    >
                      23-24
                    </Option>
                  </Select>
                </label>
              </div>
              <div
              // className="user_groups--button_n_search--search"
              >
                <input
                  type="text"
                  value={myKeyword}
                  style={{
                    border: "1px solid #ebe9e6",
                    height: "35px",
                    width: "250px",
                    padding: "20px",
                    borderRadius: "5px",
                  }}
                  placeholder="Search"
                  onChange={(e) => {
                    setMyKeyword(e.target.value);
                  }}
                />
              </div>
            </p>
          </div>

          <table className="table_x">
            <thead>
              <tr>
                <th className="preOrder--name">PreOrderId</th>
                <th className="preOrder--des">Plan Name</th>
                <th className="preOrder--startDate">Paid Amount</th>
                <th className="preOrder--startDate">Payment Method</th>
                <th className="preOrder--contactname">Purchase Note</th>
                <th className="preOrder--contactNo">Delivery Date</th>
                <th className="preOrder--startDate">Delivery Address</th>
                <th className="preOrder--startDate">Contact No</th>
                <th className="preOrder--startDate">Delivery Full Name</th>
                <th className="preOrder--startDate">Order Status</th>
                <th className="preOrder--startDate">Assigned Delivery</th>

                <th className="preOrder--startDate">Action</th>
              </tr>
            </thead>
            <tbody>
              {allMerchantPreOrderRequestedList !== undefined &&
                allMerchantPreOrderRequestedList.data?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>

                    <td>{item.merchantPreOrder.preOrderName}</td>
                    <td> $ {item.paidAmount}</td>
                    <td>{item.paymentMethod}</td>
                    <td>{item.purchaseNote}</td>
                    <td>
                      {moment(item.deliveryDateTime).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </td>
                    <td
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${item.deliveryLatitude},${item.deliveryLongitude}`
                        )
                      }
                      className="requestedPreorder--location"
                    >
                      {item.deliveryAddress}
                    </td>
                    <td>{item.deliveryContactNo}</td>
                    <td>{item.deliveryFullName}</td>
                    <td>{item.orderState}</td>
                    <td>{item.deliveryUserName} </td>
                    <td>
                      {" "}
                      {item.orderState == "PENDING" && (
                        <Button
                          className="button--controll--facebook"
                          onClick={() => {
                            setShowApproveModal(true);
                            setMyOrderId(item.id);
                          }}
                          style={{ marginTop: "5px", borderRadius: "5px" }}
                        >
                          <span>Approve Order</span>
                        </Button>
                      )}{" "}
                      {item.orderState == "ACCEPTED" && (
                        <Button
                          className="button--controll--facebook"
                          onClick={() => {
                            setShowAssignOrderModal(true);
                            setMyOrderId(item.id);
                          }}
                          style={{ marginTop: "5px", borderRadius: "5px" }}
                        >
                          <span>
                            {item.deliveryState == "ASSIGNED"
                              ? "Re Assign Order"
                              : "Assign Order"}{" "}
                          </span>
                        </Button>
                      )}
                      {item.orderState == "ACCEPTED" &&
                        item.deliveryState != "DISPATCHED" && (
                          <Button
                            className="button--controll--facebook"
                            onClick={() => {
                              setShowDispatchModal(true);
                              setMyOrderId(item.id);
                            }}
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                          >
                            <span>Dispatch Order</span>
                          </Button>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div
            className={`${
              showAssignOrderModal ? "mymodal modal_activated" : "mymodal"
            }`}
          >
            <div
              className={`${
                !isToogleSidebar
                  ? "manage_modal--container "
                  : "manage_modal--container sidebarOpend_F_modal"
              }`}
            >
              {orderId !== null && <AssignOrderPreOrderMerchant id={orderId} />}
            </div>
            <div
              className={`${
                !isToogleSidebar
                  ? "task--rectangle"
                  : "task--rectangle withsidebarOpend"
              }`}
            >
              <div className="task--rectangle__circle">
                <div className="circle">
                  {" "}
                  <span
                    onClick={() => {
                      setShowAssignOrderModal(!showAssignOrderModal);
                    }}
                  >
                    <BsX />
                  </span>
                  <p>Close</p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${
              showEditPreOrderModal ? "mymodal modal_activated" : "mymodal"
            }`}
          >
            <div
              className={`${
                !isToogleSidebar
                  ? "manage_modal--container "
                  : "manage_modal--container sidebarOpend_F_modal"
              }`}
            >
              {/* <RequestedPreOrderDetail /> */}
            </div>
            <div
              className={`${
                !isToogleSidebar
                  ? "task--rectangle"
                  : "task--rectangle withsidebarOpend"
              }`}
            >
              <div className="task--rectangle__circle">
                <div className="circle">
                  {" "}
                  <span
                    onClick={() => {
                      setShowEditPreOrderModal(false);
                    }}
                  >
                    <BsX />
                  </span>
                  <p>Edit</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              showApproveModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading"></div>
              <>
                <div className="delete--admin--container__body">
                  <h1>Are you sure to Approve ??</h1>
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={() => setShowApproveModal(!showApproveModal)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      dispatch({
                        type: "APPROVE_PREORDER_MERCHANT_REQUEST",
                        payload: {
                          id: orderId,
                          values: {
                            confirmationText: "ok",
                            orderDecision: "ACCEPT",
                          },
                        },
                      });
                    }}
                  >
                    Ok
                  </button>
                </div>
              </>
            </div>
          </div>
          <div
            className={`${
              showDispatchModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading"></div>
              <>
                <div className="delete--admin--container__body">
                  <h1>Are you sure to Dispatch this Order ??</h1>
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={() => setShowDispatchModal(!showDispatchModal)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      dispatch({
                        type: "DISPATCH_PREORDER_MERCHANT_REQUEST",
                        payload: {
                          id: orderId,
                          values: {
                            confirmationText: "ok",
                            orderDecision: "ACCEPT",
                          },
                        },
                      });
                    }}
                  >
                    Ok
                  </button>
                </div>
              </>
            </div>
          </div>

          <div className="pagination">
            <p>
              Showing {indexOfFirstPost} to{" "}
              {indexOfLastPost > allMerchantPreOrderRequestedList.totalData
                ? allMerchantPreOrderRequestedList.totalData
                : indexOfLastPost}{" "}
              of {allMerchantPreOrderRequestedList.totalData} entries
            </p>
            <nav aria-label="...">
              <ul
                className="pagination"
                style={{ maxWidth: "400px", overflowX: "auto" }}
              >
                {currentPage === 1 ? (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft />
                    </a>
                  </li>
                ) : (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft
                        onClick={() => setCurrentPage(currentPage - 1)}
                      />
                    </a>
                  </li>
                )}
                {pageNumbers.map((x) => (
                  <li key={x} className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => setCurrentPage(x)}
                    >
                      {x}
                    </a>
                  </li>
                ))}
                {currentPage == pageNumbers.length ? (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight />
                    </a>
                  </li>
                ) : (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight
                        onClick={() => setCurrentPage(currentPage + 1)}
                      />
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export default connect(mapStateToProps, null)(RequestMerchantPreOrder);
