import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";

function CampaignHeading({ item, setPreOrderId }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
      onClick={() => setPreOrderId(item.id)}
    >
      <p className="">
        <img
          style={{ height: "60px", width: "60px" }}
          src={item.campaignImage}
          alt=""
        />
      </p>
      <p className="">{item.campaignName}</p>
      <p className="">$ {item.price}</p>
    </div>
  );
}

export default CampaignHeading;
