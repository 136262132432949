import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsList } from "react-icons/bs";
import { BsChevronDown, BsLock } from "react-icons/bs";
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";
import profileImg from "../../Images/logo512.png";
import LogoImg from "../../Images/foocery-logo.png";
import SmLogoImg from "../../Images/foocery-logo-sm.png";

import { connect, useDispatch } from "react-redux";
import { toogleSidebar } from "../../Redux/actions";
import { isAuth, removeCookie, removeLocalStorage } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const { isToogleSidebar } = store.mainState;
  return { isToogleSidebar };
};

function Navbar({ isToogleSidebar, toogleSidebar }) {
  const [showAdminOption, setShowAdminOption] = useState(false);
  const [myTime, setMyTime] = useState(0);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (showAdminOption) {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShowAdminOption(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showAdminOption]);

  const handleLogOut = (e) => {
    e.preventDefault();
    removeLocalStorage("adminProfile");
    removeCookie("token");
    window.location.replace("/");
    dispatch({
      type: "LOG_OUT_REQUEST",
    });
  };
  useEffect(() => {
    setInterval(() => {
      removeLocalStorage("adminProfile");
      removeCookie("token");
      window.location.replace("/");
      dispatch({
        type: "LOG_OUT_REQUEST",
      });
    }, 1800000);
  }, []);
  return (
    <div className="_navbar">
      <div
        className={`${
          isToogleSidebar ? "_navbar--logo _navbar--logo_sm" : "_navbar--logo"
        }`}
      >
        {isToogleSidebar ? (
          <img src={SmLogoImg} alt="Logo" />
        ) : (
          <Link to="/dashboard">
            {" "}
            <img src={LogoImg} alt="Logo" />
          </Link>
        )}
      </div>
      <div className="_navbar--searcn_n_icon">
        <div className="nav_icon" onClick={() => toogleSidebar()}>
          <BsList />
        </div>
      </div>
      <div className="_navbar--user_n_notification">
        <div className="vertical_line">
          <p></p>
        </div>
        <div className="_nav_profile">
          <img src={profileImg} alt="avatar1" />{" "}
          <span style={{ textTransform: "uppercase" }}>{isAuth()}</span>{" "}
          <span>
            <BsChevronDown
              onClick={() => setShowAdminOption(!showAdminOption)}
              className="down-arow--icon"
            />
          </span>
          <div
            className={`${
              showAdminOption ? "show-admin--option" : "admin_options"
            }`}
            ref={wrapperRef}
            style={{ height: "fit-content" }}
          >
            <div className="admin_options--container">
              <Link to="admin-room" style={{ textDecoration: "none" }}>
                <p>
                  <AiOutlineUser className="admin--option_icons" /> Admin
                  Control Room
                </p>
              </Link>
              <Link to="admin-profile" style={{ textDecoration: "none" }}>
                <p>
                  <AiOutlineUser className="admin--option_icons" /> Admin
                  Profile
                </p>
              </Link>
              {/* <Link to="#" style={{ textDecoration: "none" }}><p><BsLock className="admin--option_icons" /> Change Password</p></Link> */}
              <form style={{ margin: "0" }}>
                <p onClick={handleLogOut}>
                  <AiOutlineLogout className="admin--option_icons" /> Log Out
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toogleSidebar: () => dispatch(toogleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
