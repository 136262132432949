import React, { useState, useEffect,useRef } from 'react'
import Layout from '../../../Layout/Layout'
import { connect, useDispatch } from 'react-redux'
import {AiFillPhone,AiOutlineMail} from 'react-icons/ai'
import {IoMan} from 'react-icons/io5'
import {GoLocation} from 'react-icons/go'
import {GrDatabase} from 'react-icons/gr'
import {IoIosPersonAdd} from 'react-icons/io'
import { BsX } from "react-icons/bs";
import {history, useParams} from "react-router-dom"
// for motion on hover
import { useSpring, animated, config } from "@react-spring/web";
import { useHistory } from 'react-router-dom';


import Footer from '../../Z_Footer/Footer'
import LoadingComponent from '../../LoadingComponent';
import { toast } from 'react-toastify'
import AddNewCustomer from '../../Phone Order/AddNewCustomer'
// import IdleTimer from '../../Layout/IdleTimer';

const calc = (x, y, rect) => [
    -(y - rect.top - rect.height / 2) / 9,
    (x - rect.left - rect.width / 2) / 9,
    1.01
  ];
  const trans = (x, y, s) =>
    `perspective(950px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;


const mapStateToProps = store => {
    const { buttonText,searchCustomerResult,isCustomerData,isAddNewCustomerModal,isToogleSidebar,isEnabledUser,activeOperatingLocationList } = store.mainState;
    return { buttonText,searchCustomerResult,isCustomerData,isAddNewCustomerModal,isToogleSidebar,isEnabledUser ,activeOperatingLocationList}
}

function FindCustomer({ buttonText,searchCustomerResult,isCustomerData,isAddNewCustomerModal,isToogleSidebar,isEnabledUser ,activeOperatingLocationList}) {
    const {campaignId} = useParams()
    const history = useHistory();
    const ref = useRef(null);
    const [xys, set] = useState([0, 0, 1]);
    const props = useSpring({ xys});

    const dispatch = useDispatch();

    const [phoneNumber,setPhoneNumber] = useState("")
    const [operatingLocation,setOperatingLocation] = useState("");
    const [showOpeLocationModal,setShowOpeLocationModal] = useState(false)
    const [customerId,setCustomerId] = useState(null)

    useEffect(()=> {
        dispatch({
            type: "FETCH_ACTIVE_OPERATING_LOCATION_REQUEST"
        })
    },[])
    useEffect(()=> {
        dispatch({
            type: "SET_OERATING_LOCATION",
            payload: operatingLocation
        })
    },[operatingLocation])
     
    useEffect(()=> {
        if(isEnabledUser==="b") {
            dispatch({ type: "ADD_NEW_CUSTOMER_MODAL" })
            setTimeout(()=> {
                dispatch({
                    type: "SEARCH_CUSTOMER_BY_PHN_REQUEST",
                    payload: {
                        phoneNumber
                    }
                })
            },500)
        }
        

    },[isEnabledUser])

    const getPhoneNo = (phNo)=> {
        setPhoneNumber(phNo)
    }
    return (
        <Layout >
            <div style={{minHeight: "70vh"}}>
                <div className="group_list">
                    <h4>Search Customer By Phone Number</h4>
                </div>
                <form>
                <div className="customerSearch">
                    <p><input type='text' value={phoneNumber} onChange={(e)=> setPhoneNumber(e.target.value)} placeholder='Enter Phone Number' /> <button type="submit" onClick={(e)=> {
                        e.preventDefault()
                        dispatch({
                            type: "SEARCH_CUSTOMER_BY_PHN_REQUEST",
                            payload: {
                                phoneNumber
                            }
                        })

                    }}>Search</button></p>
                </div>
                </form>
                <p className="privacy-loading--onFetching">{isCustomerData && <LoadingComponent />}</p>
                <p></p>
                {(searchCustomerResult.data?.length <1) ? <div className='noSearchData--container'>
                    <div className='noSearchData--container--para'>
                        <p>No Data</p>
                        <p><GrDatabase /></p>
                    </div>
                    <div className="noSearchData--container--body">
                        <p onClick={()=> dispatch({type: "ADD_NEW_CUSTOMER_MODAL"})}> <IoIosPersonAdd className='noSearchData--icon' />Add New Customer</p>
                    </div>
                    </div> : 
                    <div className="showCustomer">
                    {searchCustomerResult.data?.map((item,index)=> 
                    <animated.div className='showCustomer--item' key={index} ref={ref} style={{ transform: props.xys.to(trans) }}
                    onMouseLeave={() => set([0, 0, 1])}
                    onMouseMove={(e) => {
                      const rect = ref.current.getBoundingClientRect();
                      set(calc(e.clientX, e.clientY, rect));
                    }} onClick={()=> {
                        history.push(({
                            pathname: `/campaign-phone-order/delivery-info/${item.id}`,
                            state: { campaignId:  campaignId, customerInfo: searchCustomerResult?.data[0]}
                          }))
                    }}>
                        <div className="showCustomer--image-container">
                            <img src={item.imageUrl} alt="Avatar" />
                        </div>
                        <div className="showCustomer--item__name">
                            <p>@{item.userName}</p>
                            <p>{item.fullName}</p>
                        </div>
                        <div className="showCustomer--item__details">
                            <p> < AiFillPhone className='showCustomer--icons' /> <label htmlFor="">{item.phoneNumber}</label></p>
                            <p> <AiOutlineMail className='showCustomer--icons' /> <label htmlFor="">{item.email}</label></p>
                            <p> <IoMan className='showCustomer--icons' /> <label htmlFor="">{item.gender}</label></p>
                            <p> <GoLocation className='showCustomer--icons' /> <label htmlFor="">{item.location}</label></p>
                        </div>
                    </animated.div>)}
                </div>}
            {/* Add new customer modal */}
            <div className={`${isAddNewCustomerModal ? "mymodal modal_activated" : "mymodal"}`}>
                <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                    <AddNewCustomer getPhoneNo = {getPhoneNo} />
                </div>
                <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                    <div className="task--rectangle__circle">
                        <div className='circle'> <span onClick={() => {
                            dispatch({ type: "ADD_NEW_CUSTOMER_MODAL" });
                        }}><BsX /></span><p>Add</p></div>
                    </div>
                </div>
            </div>

            </div>
            <Footer />

        </Layout >
    )
}

export default connect(mapStateToProps, null)(FindCustomer)
