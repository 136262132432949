import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
// import { LatLangGenerator } from '../Coupon/LatLangGenerator';
import { Radio } from "antd";
import { toast } from "react-toastify";
const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers, isApproveSuccess } = store.mainState;
  return { buttonText, isEnabledUsers, isApproveSuccess };
};

function EditArchives({
  buttonText,
  isEnabledUsers,
  isApproveSuccess,
  myReconcilementList,
  item
}) {
  const [handOverTo, setHandOverTo] = useState("");
  const [handOverBy, setHandOverBy] = useState("");
  const [isBankDepositedYet, setIsBankDepositedYet] = useState("");
  const [depositedAmount, setDepositedAmount] = useState("");
  const [bankDepositSlip, setBankDepositSlip] = useState("");
  const [depositedBy, setDepositedBy] = useState("");
  const [approvalComment, setApprovalComment] = useState("");
  const dispatch = useDispatch();

  const onChange1 = (e) => {
    setIsBankDepositedYet(e.target.value);
  };

  const handleDeliverySubmit = (e) => {
    e.preventDefault();
    if (isBankDepositedYet) {
      if (
        !handOverBy ||
        !handOverTo ||
        !isBankDepositedYet ||
        !bankDepositSlip ||
        !depositedAmount ||
        !depositedBy
      ) {
        return toast.error("Please fill all the fields !!");
      }
      dispatch({
        type: "REAPPROVE_RECONCILEMENT_REQUEST",
        payload: {
            reconcilementId: item.reconcilementId,
          reconcilementList: myReconcilementList?.map(
            (item) => item.customerOrderId
          ),
          handOverTo,
          handOverBy,
          isBankDepositedYet,
          bankDepositSlip,
          depositAmount: depositedAmount,
          depositedBy,
          approvalComment,
        },
      });
    } else {
      if (!handOverBy || !handOverTo) {
        return toast.error("Please fill all the fields !!");
      }
      dispatch({
        type: "REAPPROVE_RECONCILEMENT_REQUEST",
        payload: {
            reconcilementId: item.reconcilementId,
          reconcilementList: myReconcilementList?.map(
            (item) => item.customerOrderId
          ),
          handOverTo,
          handOverBy,
          isBankDepositedYet,
          approvalComment,
        },
      });
    }
  };
  useEffect(() => {
    if (isApproveSuccess) {
      setHandOverTo("");
      setHandOverBy("");
      setIsBankDepositedYet("");
      setDepositedAmount("");
      setBankDepositSlip("");
      setDepositedBy("");
      setApprovalComment("");
    }
  }, [isApproveSuccess]);
  useEffect(() => {
    setHandOverBy(item.handOverBy)
    setHandOverTo(item.handOverTo)
    setApprovalComment(item.approvalComment)
    setIsBankDepositedYet(item.isBankDepositedYet)
    setDepositedAmount(item.depositAmount)
    setBankDepositSlip(item.bankDepositSlip)
    setDepositedBy(item.depositedBy)
  }, [item]);

  return (
    <div>
      <div className="Modal--heading">Edit Reconcilement</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Hand Over To: </label>
              <input
                type="text"
                value={handOverTo}
                placeholder="Hand Over To"
                onChange={(e) => setHandOverTo(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Hand Over By: </label>
              <input
                type="text"
                value={handOverBy}
                placeholder="HandOverBy"
                onChange={(e) => setHandOverBy(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Approval Comment : </label>
              <input
                type="text"
                value={approvalComment}
                placeholder="Approval Comment"
                onChange={(e) => setApprovalComment(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Bank Deposited: </label>
              <Radio.Group
                defaultValue={false}
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={isBankDepositedYet}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          {isBankDepositedYet && (
            <>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Deposited Amount : </label>
                  <input
                    type="text"
                    value={depositedAmount}
                    placeholder="Deposited Amount"
                    onChange={(e) => setDepositedAmount(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Bank Deposit Slip : </label>
                  <input
                    type="text"
                    placeholder="Bank Deposit Slip"
                    value={bankDepositSlip}
                    onChange={(e) => setBankDepositSlip(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="title">Deposited By : </label>
                  <input
                    type="text"
                    placeholder="Deposited By"
                    value={depositedBy}
                    onChange={(e) => setDepositedBy(e.target.value)}
                  />
                </p>
              </div>
            </>
          )}

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleDeliverySubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(EditArchives);
