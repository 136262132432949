import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { BsLock } from 'react-icons/bs'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify'
import { removeCookie, removeLocalStorage } from '../../FrontendHelper'

const mapStateToProps = store => {
    const { buttonText } = store.mainState
    return { buttonText }
}

function ChangePassword({ buttonText }) {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: "CHANGE_PASSWORD_REQUEST",
            payload: {
                "currentPassword": currentPassword,
                "newPassword": newPassword,
                "confirmPassword": confirmPassword
            }
        })
    }
    useEffect(() => {
        if (buttonText === "Submitted") {
           setTimeout(()=> {
                removeLocalStorage("admin");
            removeLocalStorage("adminId");
            removeCookie("token");
            window.location.replace("/")
            dispatch({
                type: "LOG_OUT_REQUEST"
            })
           },1000)
        }
    }, [buttonText])
    return (
        <form>
            <ToastContainer />
            <div className="change-password">
                <div className="change-password--container">

                    <div className="change-password--heading">Change Password</div>
                    <hr />
                    <div className="change-password--body">
                        <input type="password" placeholder="Current Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} /><span><BsLock /></span>
                        <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} /><span><BsLock /></span>
                        <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /><span><BsLock /></span>
                    </div>
                    <hr />
                    <div className="change-password--footer"><button type="submit" onClick={handleSubmit}>{buttonText}</button></div>


                </div>

            </div>
        </form>
    )
}

export default connect(mapStateToProps, null)(ChangePassword)
