import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify';
import { Divider } from 'antd';

const mapStateToProps = store => {
    const { buttonText } = store.mainState
    return { buttonText }
}

function UpdatePreorderTimeSlot({ buttonText,preOrderId,editingItem }) {

    const dispatch = useDispatch();
    const [removingItem,setRemovingItem] = useState({})
    const [preOrderDate,setPreOrderDate] = useState("")
    const [timeSlotItem,setTimeSlotItem] = useState([{
            "preOrderTimeSlotId": "",
            "preOrderDate": "",
            "startHour": "",
            "endHour": "",
            "totalOrder": ""
        }
    ])

    const handleTimeSlotOnChange = (index, event) => {
        let values = [...timeSlotItem]
        values[index][event.target.name] = event.target.value
        setTimeSlotItem(values)

    }


    const handleAddTimeSlotSubmit = (e) => {
        e.preventDefault();

        dispatch({
            type: "EDIT_PRE_ORDER_TIME_SLOT_REQUEST",
            payload: timeSlotItem,
        })


    }


    useEffect(()=> {
        if(editingItem) {
            // setPreOrderDate()
            let xx = editingItem?.timeResponses?.reduce((myArr,item,index)=> {
                myArr.push({
                    "preOrderTimeSlotId": item.preOrderTimeSlotId,
                    "preOrderDate": editingItem.slotDate,
                    "startHour": item.startTime,
                    "endHour": item.endTime,
                    "totalOrder": item.slotData?.totalSlot
                })
                return myArr
            },[])
            setTimeSlotItem(xx)
        }
    },[editingItem])


    return (
        <div>
            <ToastContainer />
            <div className="billing__Modal--heading">
                <span style={{ color: "#3e445e" }}>Edit Pre-Order Time Slot</span>
                {/* <button className='addBilling--new' onClick={handleAddTimeSlot}>Add</button> */}

            </div>


            <div className="Modal--form" style={{marginTop: "100px"}}>
                <form>
                <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Pre Order Date: </label>
                        <input type='date' disabled value={preOrderDate} onChange={(event)=> setPreOrderDate(event.target.value)} />
                        </p>
                    </div>
                    <Divider />
                    {timeSlotItem?.map((item,index)=> <Fragment key={index}>

                        <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Start Time: </label>
                        <input disabled type='text' value={item.startHour} name="startHour" placeholder="Start Time" onChange={(event)=> handleTimeSlotOnChange(index,event)} />
                        </p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">End Time: </label>
                        <input type='text' disabled value={item.endHour} name="endHour" placeholder="End Time" onChange={(event)=> handleTimeSlotOnChange(index,event)} />
                        </p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Total Order: </label>
                        <input type='number' value={item.totalOrder} name="totalOrder" placeholder="Total Order" onChange={(event)=> handleTimeSlotOnChange(index,event)} />
                        </p>
                    </div>
                    {timeSlotItem.length >1 && 
                                        <div className="remove--timeSlot__btn">
                        <div onClick={()=> setRemovingItem(item)}>Remove</div>
                    </div>
                    }
                    </Fragment>
                    )}
                    



                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleAddTimeSlotSubmit} >
                            {buttonText}
                        </button>
                    </div>
  
                </form>
            </div>

        </div>
    )
}

export default connect(mapStateToProps, null)(UpdatePreorderTimeSlot)
