import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { connect, useDispatch, useSelector } from "react-redux";
import { IoSearchSharp } from "react-icons/io5";
import { BsPhone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { IoPricetagsOutline } from "react-icons/io5";
import { IoRestaurantOutline } from "react-icons/io5";
import { AiFillStar } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { GoAlert } from "react-icons/go";
import { RiArrowLeftLine } from "react-icons/ri";

import { useTransition, animated, config } from "react-spring";

const mapStateToProps = (store) => {
  const { searchRestaurantList, operatingLoc } = store.mainState;
  return { searchRestaurantList, operatingLoc };
};

function ShowRestaurantList({ searchRestaurantList, operatingLoc }) {
  const [keyWord, setKeyWord] = useState("");
  const [menuKeyword, setMenuKeyword] = useState("");
  const [typeOfAdd, setTypeOfAdd] = useState("External");
  const [restaurantId, setRestaurnatId] = useState(null);

  // this ref is to alert message when user cliks outside the component to abort phone order
  const abortOrder = useRef();
  const [showAbortAlert, setShowAbourtAlert] = useState(false);
  const [closeAbortModal, setCloseAboutModal] = useState(false);

  // const [menuId,setMe]

  const transition = useTransition(typeOfAdd, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: typeOfAdd,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let keyWord = "";
    let currentPage = 1;
    let postsPerPage = 50;
    dispatch({
      type: "FETCH_RESTAURANT_LIST_REQUEST",
      payload: { keyWord, currentPage, postsPerPage, operatingLoc },
    });
  }, []);

  useEffect(() => {
    if (typeOfAdd === "External") {
      let keyWord = "";
      let currentPage = 1;
      let postsPerPage = 50;
      dispatch({
        type: "FETCH_RESTAURANT_LIST_REQUEST",
        payload: { keyWord, currentPage, postsPerPage, operatingLoc },
      });
    } else {
      let menuKeyword = "";
      dispatch({
        type: "FETCH_MENU_ITEMS_WITHOUT_RES_ID_REQUEST",
        payload: { menuKeyword, operatingLoc },
      });
    }
  }, [typeOfAdd]);

  useEffect(() => {
    if (restaurantId !== null) {
      history.push(`/active-menu-list/${restaurantId}`);
    }
  }, [restaurantId]);

  useEffect(() => {
    if (!closeAbortModal) {
      function handleClickOutside(event) {
        if (abortOrder.current && !abortOrder.current.contains(event.target)) {
          setShowAbourtAlert(!showAbortAlert);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showAbortAlert]);
  const handleBackButton = () => {
    dispatch({
      type: "REMOVE_CARTITEM_FROM_ARRAY",
    });
    history.goBack();
  };

  return (
    <Layout>
      <div ref={abortOrder}>
        <div className="menuShowing__restaurantDetails">
          <p onClick={handleBackButton}>
            {" "}
            <span>
              <RiArrowLeftLine />
            </span>{" "}
            Back
          </p>
        </div>
        <div className="activ-restaurant--heading">
          <div className="addSelector--button">
            <div className="addSelector--button__container">
              <div
                className={typeOfAdd === "External" && "external--link"}
                onClick={() => {
                  setTypeOfAdd("External");
                }}
              >
                Merchant
              </div>
              <p>a</p>
              <div
                className={typeOfAdd === "Internal" && "external--link"}
                onClick={() => setTypeOfAdd("Internal")}
              >
                Menu Item
              </div>
            </div>
          </div>
          {typeOfAdd === "External" ? (
            <div className="searchByRestaurant--input">
              <p>
                <input
                  type="text"
                  placeholder="Search Merchant"
                  value={keyWord}
                  onChange={(e) => setKeyWord(e.target.value)}
                />
                <label
                  htmlFor=""
                  onClick={() => {
                    let currentPage = 1;
                    let postsPerPage = 50;
                    dispatch({
                      type: "FETCH_RESTAURANT_LIST_REQUEST",
                      payload: {
                        keyWord,
                        currentPage,
                        postsPerPage,
                        operatingLoc,
                      },
                    });
                  }}
                >
                  <IoSearchSharp />
                </label>
              </p>
            </div>
          ) : (
            <div className="searchByMenu--input">
              <p>
                <input
                  type="text"
                  placeholder="Search Menu"
                  value={menuKeyword}
                  onChange={(e) => setMenuKeyword(e.target.value)}
                />
                <label
                  htmlFor=""
                  onClick={() => {
                    dispatch({
                      type: "FETCH_MENU_ITEMS_WITHOUT_RES_ID_REQUEST",
                      payload: { menuKeyword, operatingLoc },
                    });
                  }}
                >
                  <IoSearchSharp />
                </label>
              </p>
            </div>
          )}
        </div>
        <hr />
        <div className="showRestaurants--container">
          {transition(({ opacity }, addtype) =>
            addtype === "External" ? (
              <animated.div
                style={{
                  position: "absolute",
                  opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                }}
                className="searchByRestaurant"
              >
                <div className="searchByRestaurant--body">
                  {searchRestaurantList.data?.map((rest) => (
                    <div
                      className="searchByRestaurant--body__item"
                      onClick={() => {
                        setRestaurnatId(rest.id);
                        dispatch({
                          type: "SET_MENU_CATEGORY_ID",
                          payload: null,
                        });
                        dispatch({
                          type: "SET_MENU_ITEM_ID",
                          payload: null,
                        });
                      }}
                    >
                      <div className="restaurant--details--container">
                        <p>{rest.restaurantName} </p>
                        <p>
                          <BsPhone className="my-ui--icons" />
                          {rest.phoneNumber}
                        </p>
                        <p>
                          <IoLocationOutline className="my-ui--icons" />
                          {rest.location}
                        </p>
                      </div>
                      {rest.isPopular ? (
                        <div className="restaurant--image--container">
                          <img src={rest.imageUrl} alt="Restaurant Image" />
                          <div className="popular--star">
                            <AiFillStar />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </animated.div>
            ) : (
              <animated.div
                style={{
                  position: "absolute",
                  opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
                }}
                className="searchByMenu"
              >
                <div className="searchByMenu--body">
                  {searchRestaurantList.data?.map((menu) => (
                    <div
                      className="searchByRestaurant--body__item"
                      onClick={() => {
                        setRestaurnatId(menu.restaurantId);
                        dispatch({
                          type: "SET_MENU_CATEGORY_ID",
                          payload: menu.categoryId,
                        });
                        dispatch({
                          type: "SET_MENU_ITEM_ID",
                          payload: menu.id,
                        });
                      }}
                    >
                      <div className="restaurant--details--container">
                        <p> {menu.itemName}</p>

                        <p>
                          <IoPricetagsOutline className="my-ui--icons" />${" "}
                          {menu.price}
                        </p>
                        <p>
                          <IoRestaurantOutline className="my-ui--icons" />
                          {menu.restaurantName}
                        </p>
                        <p> Category : {menu.categoryName}</p>
                      </div>
                      <div className="restaurant--image--container">
                        <img src={menu.imageUrl} alt="Restaurant Image" />
                      </div>
                    </div>
                  ))}
                </div>
              </animated.div>
            )
          )}
        </div>
        {/* abort order pop up modal */}
        {showAbortAlert && (
          <div className="operatingLocation">
            <div
              className="operatingLocation--container"
              style={{ height: "auto" }}
            >
              <div className="showAlrt--header">
                <p>
                  <GoAlert />
                </p>
                <p>Alert !!!</p>
              </div>

              <hr />
              <p style={{ backgroundColor: "#f8f9fa", padding: "10px" }}>
                After Clicking "OK button, your current phone order will be
                aborted
              </p>

              <div className="button_container">
                <button
                  onClick={() => {
                    setShowAbourtAlert(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setCloseAboutModal(true);
                    dispatch({
                      type: "REMOVE_CARTITEM_FROM_ARRAY",
                    });
                    dispatch({
                      type: "MAKE_EMPTY_PHONESEARCH_CUSTOMER",
                    });

                    setShowAbourtAlert(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(ShowRestaurantList);
