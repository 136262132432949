import React, { Component } from "react";
// import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU");

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

export class GetValidLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: "",

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: this.props.preLat,
        lng: this.props.prevLngd,
      },
      mapPosition: {
        lat: null,
        lng: null,
      },
      markerPosition: {
        lat: null,
        lng: null,
      },
    };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  componentDidMount() {
    const location = this.props.location;
    location && this.setState({ address: location });
  }

  handleSelect = (address) => {
    this.props.handleAddress(address);
    this.setState({ address });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.props.handlelatLong(latLng);
        // update center state
        this.setState({ mapCenter: latLng });
      })
      .catch((error) => console.error("Error", error));
  };
  // componentDidMount () {
  //   window.initMap = this.initMap
  //   const gmapScriptEl = document.createElement(`script`)
  //   gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU&libraries=places&callback=initMap`
  //   document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
  // }

  // onMarkerDragEnd = (mapProps, map, three) => {

  //   const { latLng } = three;
  //   const draggedLat = latLng.lat();
  //   const draggedLng = latLng.lng();
  //   this.props.handlelatLong({ lat: draggedLat, lng: draggedLng })
  //       Geocode.fromLatLng(draggedLat, draggedLng).then(
  //       response => {
  //       const draggedAddress = response.results[0].formatted_address;
  //       this.props.handleAddress(draggedAddress)
  //       },
  //       error => {
  //         console.error(error);
  //       }
  //     );
  //   };

  render() {
    console.log("The defaultAddress", this.state.defaultAddress);
    return (
      <div>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder:
                    this.props.defaultAddress || "Enter delivery address",
                  className: "location-search-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

export default GetValidLocation;
