import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import Footer from "../Z_Footer/Footer";
import { connect, useDispatch } from "react-redux";
import { AiOutlineUsergroupAdd, AiOutlineUserSwitch } from "react-icons/ai";
import { FiUserCheck, FiUser } from "react-icons/fi";
import { BiUserCheck, BiUser } from "react-icons/bi";
import { RiUserUnfollowLine, RiUserFollowLine } from "react-icons/ri";
import { BsPersonPlusFill } from "react-icons/bs";
import LoadingComponent from "../LoadingComponent";
import EventSource from "eventsource";
import { getCookie } from "../../FrontendHelper";
import song from "../../Images/noti.wav";
import IndividualCard from "./IndividualCard";
import { Divider } from "antd";

// FOR ADMIN LOGIN
const Token = getCookie("token");
const mapStateToProps = (store) => {
  const { dashboardData, isLoadingOnLogin } = store.mainState;
  return { dashboardData, isLoadingOnLogin };
};

function Report({ dashboardData, isLoadingOnLogin }) {
  const dispatch = useDispatch();
  const [myTime, setMyTime] = useState(0);
  const [myTotalUser, setMyTotalUser] = useState(0);
  const [myInactiveUsers, setInactiveUsers] = useState(0);
  const [myActiveUsers, setMyActuveUsers] = useState(0);
  const [myMaleUsers, setMyMaleUsers] = useState(0);
  const [myFamaleUsers, setMyFamaleUsers] = useState(0);
  const [myVerifiedUsers, setMyVerifiedUsers] = useState(0);
  const [myTodayVerifiedUsers, setMyTodayVerifiedUsers] = useState(0);
  const [myReferredUsers, setMyReferredUsers] = useState(0);
  const [myTodayAdded, setMyTodayAdded] = useState(0);
  const [audio, setAudio] = useState(new Audio(song));

  if (myMaleUsers < dashboardData?.maleUsers) {
    setTimeout(() => {
      setMyMaleUsers(myMaleUsers + 1);
    }, 1);
  }
  if (myFamaleUsers < dashboardData?.femaleUsers) {
    setTimeout(() => {
      setMyFamaleUsers(myFamaleUsers + 1);
    }, 1);
  }

  if (myTodayVerifiedUsers < dashboardData?.totalVerifiedToday) {
    setTimeout(() => {
      setMyTodayVerifiedUsers(myTodayVerifiedUsers + 1);
    }, 1);
  }
  if (myReferredUsers < dashboardData?.totalReferredUsers) {
    setTimeout(() => {
      setMyReferredUsers(myReferredUsers + 1);
    }, 1);
  }
  if (myTodayAdded < dashboardData?.totalUsersAddedToday) {
    setTimeout(() => {
      setMyTodayAdded(myTodayAdded + 1);
    }, 1);
  }
  useEffect(() => {
    setInterval(() => {
      setMyTime(() => myTime + 1);
    }, 120000);
    dispatch({
      type: "FETCH_DASHBOARD_DATA_REQUEST",
    });
  }, [myTime]);

  return (
    <>
      <Layout>
        <p className="privacy-loading--onFetching">
          {isLoadingOnLogin && <LoadingComponent />}
        </p>
        <h3 style={{ fontSize: "24px", margin: "10px 0" }}>
          Reports
        </h3>
        <div
          className="dashboard"
          style={{ marginBottom: "20px", minHeight: "60vh" }}
        >
          <div className="dashboard--headers">
            <Link to="/manage-customer" style={{ textDecoration: "none" }}>
              <div className="dashboard--headers__item">
                <div className="dashboard--item--description">
                  <div className="item--container">
                    <p>{dashboardData?.todayOrder}</p>
                    <p>Today Orders</p>
                  </div>
                  <div className="icon--container">
                    <AiOutlineUsergroupAdd />
                  </div>
                </div>
              </div>
            </Link>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.newOrder}</p>
                  <p>Orders Placed (Action Pending) </p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.phoneOrder}</p>
                  <p>Phone Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.pendingOrder}</p>
                  <p>Pending Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.campaignOrder}</p>
                  <p>Campaign Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.preOrder}</p>
                  <p>PreOrders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.todayPreOrder}</p>
                  <p>Today Preorders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dashboard"
          style={{ marginTop: "-90px", marginBottom: "40px" }}
        >
          <div className="dashboard--headers">
            <Link to="/manage-customer" style={{ textDecoration: "none" }}>
              <div className="dashboard--headers__item">
                <div className="dashboard--item--description">
                  <div className="item--container">
                    <p>{dashboardData?.totalUsers}</p>
                    <p>Total Users</p>
                  </div>
                  <div className="icon--container">
                    <AiOutlineUsergroupAdd />
                  </div>
                </div>
              </div>
            </Link>

            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <IndividualCard
                  title="Inactive Users"
                  value={myInactiveUsers}
                />
                <div className="icon--container">
                  <RiUserUnfollowLine />
                </div>
              </div>
            </div>

            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.activeUsers}</p>
                  <p>Active Users</p>
                </div>
                <div className="icon--container">
                  <BiUserCheck />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{myMaleUsers} </p>
                  <p>Male Users</p>
                </div>
                <div className="icon--container">
                  <FiUser />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{myFamaleUsers} </p>
                  <p>Female Users</p>
                </div>
                <div className="icon--container">
                  <BiUser />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.totalVerifiedUsers} </p>
                  <p>Total Verified Users</p>
                </div>
                <div className="icon--container">
                  <RiUserFollowLine />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{myTodayVerifiedUsers} </p>
                  <p>Today Verified Users</p>
                </div>
                <div className="icon--container">
                  <FiUserCheck />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{myReferredUsers}</p>
                  <p>Referred Users</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUserSwitch />
                </div>
              </div>
            </div>

            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{myTodayAdded}</p>
                  <p>Today Added Users</p>
                </div>
                <div className="icon--container">
                  <BsPersonPlusFill />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <SoundGenerator /> */}
        <Footer />
      </Layout>
    </>
  );
}

export default connect(mapStateToProps, null)(Report);
