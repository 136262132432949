import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
import { BsX } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import UpdateReferralLoyality from './UpdateReferralLoyality'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment';

const mapStateToProps = store => {
    const { referral_loyalityData, isLoadingOnLogin, isToogleSidebar } = store.mainState;
    return { referral_loyalityData, isLoadingOnLogin, isToogleSidebar }
}

function ReferalAndLoyalty({ referral_loyalityData, isLoadingOnLogin, isToogleSidebar }) {
    const [openUpdatingModal, setOpenUpdatingModal] = useState(false);
    const [updatingConfigureKey, setUpdatingConfigureKey] = useState("")
    const [updatingConfigValue, setUpdatingConfigValue] = useState()
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "FETCH_REFERAL_LOYALTY_REQUEST"
        })
    }, [])
    const getLocalDateTime = (mydate) => {
        var stillUtc = moment.utc(mydate).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local
    }
    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Referral And Loyalty</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Referral And Loyalty</h4>
                            <button onClick={() => dispatch({ type: "ADD_REFERRAL_LOYALITY_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    {/* <th className="loyality--id">ID</th> */}
                                    <th className="loyality--schemeName">Scheme Name</th>
                                    <th className="loyality--addedBy">Added By</th>
                                    <th className="loyality--addedBy">Added Date</th>
                                    <th className="loyality--addedBy">Updated By</th>
                                    <th className="loyality--addedBy">Updated Date</th>
                                    <th className="loyality--loyalityPoint">Point</th>
                                    <th className="loyality--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {referral_loyalityData.length !== 0 && referral_loyalityData.map((item) =>
                                    <tr key={item.configKey}>
                                        <td>{item.configKey}</td>
                                        <td>{item.addedBy}</td>
                                        <td>{getLocalDateTime(item.addedDateTime)}</td>
                                        <td>{item.lastUpdatedBy}</td>
                                        <td>{getLocalDateTime(item.lastUpdatedDateTime)}</td>
                                        <td>{item.configValue}</td>
                                        <td> <Tippy content="Edit" placement="top">
                                            <span>
                                                <AiFillEdit className="edit--icon" onClick={() => {
                                                    setOpenUpdatingModal(true);
                                                    setUpdatingConfigureKey(item.configKey);
                                                    setUpdatingConfigValue(item.configValue)
                                                }} /> </span>
                                        </Tippy></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {/* add referral And loyalty modal */}
                        <div className={`${openUpdatingModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                <UpdateReferralLoyality item={updatingConfigureKey} myValue={updatingConfigValue} />
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setOpenUpdatingModal(!openUpdatingModal)
                                        dispatch({
                                            type: "FETCH_REFERAL_LOYALTY_REQUEST",
                                        })
                                    }}><BsX /></span><p>Add</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="pagination">
                            <p>Showing 0 to {referral_loyalityData.length} of {referral_loyalityData.length} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps, null)(ReferalAndLoyalty)

