import React, { useState } from "react";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { BsEyeFill } from "react-icons/bs";
import OrderDetails from "../Dashboard/OrderDetails";
import { BsX } from "react-icons/bs";
import { connect } from "react-redux";
import { Timeline } from "antd";

const mapStateToProps = (store) => {
  const { isToogleSidebar } = store.mainState;
  return {
    isToogleSidebar,
  };
};

const ViewReconcilement = ({ item, isToogleSidebar }) => {
  const [openEye, setOpenEye] = useState(false);
  const [userDetailsId, setUserDetailsId] = useState(null);
  const [opLocation, setOpLocation] = useState("");

  const getLocatDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  return (
    <div>
      {" "}
      <div className="Modal--heading">View Reconcilement</div>
      <table>
        <thead>
          <tr style={{ backgroundColor: "pink" }}>
            <th className="order--itemName">Order ID</th>
            <th className="order--customerName">Customer Name</th>
            <th className="order--sheduledTime">Sheduled Time</th>
            <th className="order--status">Date Of Order</th>
            <th className="order--status">Assigned To</th>
            <th className="order--grandTotal">Grand Total</th>
            <th className="order--status">Payment Status</th>
            <th className="order--status">Order Status</th>
            <th className="order--actions" style={{ width: "15vw" }}>
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          {item?.orderDetails?.map((item) => (
            <tr key={item.paymentId}>
              <td>{item.customerOrderId}</td>
              <td>{item.deliveryInfoDto.deliveryFullName}</td>
              <td>
                {item.scheduledTime !== "null"
                  ? getLocatDateTime(item.scheduledTime)
                  : "Not Scheduled"}
              </td>
              <td>
                {item.checkOutDateTime !== "null"
                  ? getLocatDateTime(item.checkOutDateTime)
                  : "Not Available"}
              </td>
              <td>
                {item.deliveryAssignedTo !== null
                  ? `${item.deliveryAssignedTo}`
                  : "Not Assigned"}
              </td>
              <td>$ {item.actualPaymentData.grandTotal}</td>
              <td>
                <span
                  style={{
                    backgroundColor: "purple",
                    color: "white",
                    padding: "3px 5px",
                    borderRadius: "4px",
                  }}
                >
                  {item.paymentStatus}
                </span>
              </td>
              <td>
                <span
                  style={{
                    backgroundColor: "purple",
                    color: "white",
                    padding: "3px 5px",
                    borderRadius: "4px",
                  }}
                >
                  {item.orderStatus}
                </span>
              </td>
              <td>
                <Tippy content="View" placement="bottom">
                  <span>
                    <BsEyeFill
                      className="action--edit__admin"
                      onClick={() => {
                        setUserDetailsId(item.customerOrderId);
                        setOpenEye(true);
                      }}
                    />{" "}
                  </span>
                </Tippy>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <div className="Modal--heading" style={{ marginTop: "1rem" }}>
          Reconcillation Timeline
        </div>
        <Timeline style={{ margin: "3rem" }}>
          {item?.logList?.map((log) => (
            <Timeline.Item>
              <p>Hand Over To: {log.handOverTo}</p>
              <p>Hand Over By: {log.handOverBy}</p>
              <p>Approval Comment: {log.description}</p>
              <p>Date Time: {getLocatDateTime(log.logTime)}</p>
              <p>Is Bank Deposited: {log.isBankDepositedYet ? "Yes" : "No"}</p>
              <p>Deposited Amount: {log.depositAmount}</p>
              <p>Deposited By: {log.depositedBy}</p>
              <p>Slip No: {log.bankDepositSlip}</p>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      <div className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}>
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          {userDetailsId !== null && (
            <OrderDetails id={userDetailsId} opLocation={opLocation} />
          )}
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  setOpenEye(!openEye);
                  setUserDetailsId(null);
                }}
              >
                <BsX />
              </span>
              <p>Close</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(ViewReconcilement);
