export const ADMIN_CREDENCIAL_INPUT = 'ADMIN_CREDENCIAL_INPUT'
export const ADD_CATEGORY_INPUT = 'ADD_CATEGORY_INPUT'

export const ADMIN_AUTH_REQUEST = 'ADMIN_AUTH_REQUEST'
export const ADMIN_AUTH_FAILURE = 'ADMIN_AUTH_FAILURE'

export const ADMIN_AUTH_SUCCESS = 'ADMIN_AUTH_SUCCESS'
export const TOOGLE_SIDEBAR = "TOOGLE_SIDEBAR"
export const LOADIN_ON_LOGIN = 'LOADING_ON_LOGIN'

// fetch category list
export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST" 
export const SUCCESS_CATEGORY_LIST = "SUCCESS_CATEGORY_LIST" 
export const FAILURE_CATEGORY_LIST = "FAILURE_CATEGORY_LIST" 

// Add category modal
export const OPEN_CATEGORY_MODAL = "OPEN_CATEGORY_MODAL"
export const OPEN_EDIT_CATEGORY_MODAL = "OPEN_EDIT_CATEGORY_MODAL"

// post new category
export const POST_CATEGORY_REQUEST = "POST_CATEGORY_REQUEST"
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS"
export const POST_CATEGORY_FAILURE = "POST_CATEGORY_FAILURE"
// FETCH category list by id
export const FETCH_CATEGORY_BY_ID_REQUEST = "FETCH_CATEGORY_BY_ID_REQUEST"
export const FETCH_CATEGORY_BY_ID_SUCCESS = "FETCH_CATEGORY_BY_ID_SUCCESS"
export const FETCH_CATEGORY_BY_ID_FAILURE = "FETCH_CATEGORY_BY_ID_FAILURE"
// update category by id
export const PUT_CATEGORY_REQUEST = "PUT_CATEGORY_REQUEST"
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS"
export const PUT_CATEGORY_FAILURE = "PUT_CATEGORY_FAILURE"
// fetch sub-category
export const FETCH_SUB_CATEGORY_REQUEST = "FETCH_SUB_CATEGORY_REQUEST"
export const FETCH_SUB_CATEGORY_SUCCESS = "FETCH_SUB_CATEGORY_SUCCESS"
export const FETCH_SUB_CATEGORY_FAILURE = "FETCH_SUB_CATEGORY_FAILURE"
