import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify';

const mapStateToProps = store => {
    const { updateButtonText } = store.mainState
    return { updateButtonText }
}

function EditAnnouncement({ updateButtonText,id,myBody }) {
    const dispatch = useDispatch();
    const [announcement, setAnnouncement] = useState("")
    const handleLoyalitySubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "UPDATE_USER_ANNOUNCEMENT_REQUEST",
            payload: {
                id: id,
                value: {
                    "announcement": announcement
                }
            }
        })
        setAnnouncement("")

    }
    useEffect(()=> {
        setAnnouncement(myBody)
    },[myBody])

    return (
        <div>
            <ToastContainer />
            <div className="Modal--heading">
                Edit Announcement
            </div>
            <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Announcement Body: </label><textarea style={{ minHeight: "100px", padding: "20px" }} value={announcement} onChange={(e) => setAnnouncement(e.target.value)} placeholder="Announcement Body"></textarea></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleLoyalitySubmit} >
                            {updateButtonText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(EditAnnouncement)
